import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function ReportCompareTable({
  data,
  entryValue,
  firstDate,
  secondDate,
  thirdDate,
  firstCreatedAt,
  secondCreatedAt,
  thirdCreatedAt,
}) {
  const [seeName, setSeeName] = useState(true);
  const [seeSeverity, setSeeSeverity] = useState(true);
  const [seeInFirst, setSeeInFirst] = useState(firstDate ? true : false);
  const [seeInSecond, setSeeInSecond] = useState(secondDate ? true : false);
  const [seeInThird, setSeeInThird] = useState(thirdDate ? true : false);

  const [btnprimary1, setBtnprimary1] = useState(false);
  const [rowId, setRowId] = useState([]);
  const [down, setDown] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Severity",
        accessor: "severity",
      },
      {
        Header: `${firstDate ? firstCreatedAt : "In First"}`,
        accessor: "audits[0].exist",
      },
      {
        Header: `${secondDate ? secondCreatedAt : "In Second"}`,
        accessor: "audits[1].exist",
      },
      {
        Header: `${thirdDate ? thirdCreatedAt : "In Third"}`,
        accessor: "audits[2].exist",
      },
    ],
    []
  );

  const convertJSONToCSV = (data) => {
    // CSV başlık satırını oluştur

    const csvHeader = `Name;Severity;${firstDate ? `${firstCreatedAt};` : ""}${
      secondDate ? `${secondCreatedAt};` : ""
    }${thirdDate ? `${thirdCreatedAt}` : ""}`;

    const csvRows = data.reverse().map((item) => {
      let first =
        item.audits[0]?.date === firstDate
          ? item.audits[0]?.exist
          : item.audits[1]?.date === firstDate
          ? item.audits[1]?.exist
          : item.audits[2]?.exist;
      let second =
        item.audits[0]?.date === secondDate
          ? item.audits[0]?.exist
          : item.audits[1]?.date === secondDate
          ? item.audits[1]?.exist
          : item.audits[2]?.exist;
      let third =
        item.audits[0]?.date === thirdDate
          ? item.audits[0]?.exist
          : item.audits[1]?.date === thirdDate
          ? item.audits[1]?.exist
          : item.audits[2]?.exist;
      let firstResult = first ? "YES;" : "NO;";
      let secondResult = second ? "YES;" : "NO;";
      let thirdResult = third ? "YES" : "NO";

      let severityResult =
        item.severity === "1"
          ? "Low"
          : item.severity === "2"
          ? "Medium"
          : item.severity === "3"
          ? "High"
          : item.severity === "4"
          ? "Critical"
          : item.severity === "secure"
          ? "Secure"
          : item.severity === "insecure"
          ? "Insecure"
          : "Info";

      return `${item?.name};${severityResult};${firstDate ? firstResult : ""}${
        secondDate ? secondResult : ""
      }${thirdDate ? thirdResult : ""}`;
    });

    // CSV başlığı ve satırlarını birleştir
    const csvContent = [csvHeader, ...csvRows].join("\n");

    // CSV dosyasını döndür
    return csvContent;
  };

  const downloadCSV = (data) => {
    // JSON verisini CSV'ye dönüştür
    const csvData = convertJSONToCSV(data);

    // CSV dosyasını indirme işlemi
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const fileName = "PentestBX.csv";

    if (window.navigator.msSaveBlob) {
      // IE 10+ için
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // Diğer tarayıcılar için
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
  };

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const [sortBy, setSortBy2] = useState([{ id: "company_name", desc: false }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
    // useFlexLayout
    // useResizeColumns
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }

  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const rowIdFunc = (id) => {
    let tempArray = [...rowId];
    let check = rowId.some((item) => item === id);
    if (check) {
      let filterFunc = rowId.filter((item) => item !== id);
      setRowId(filterFunc);
    } else {
      tempArray.push(id);
      setRowId(tempArray);
    }
  };

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            // style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col md={3}>
          <div className="dt-buttons  flex-wrap justify-content-center d-flex ">
            <button
              title="Copy Clipboard"
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                const csvHeader = `Name,Severity,${
                  firstDate ? `${firstCreatedAt},` : ""
                }${secondDate ? `${secondCreatedAt},` : ""}${
                  thirdDate ? `${thirdCreatedAt}` : ""
                }`;

                const csvRows = data.reverse().map((item) => {
                  let first =
                    item.audits[0]?.date === firstDate
                      ? item.audits[0]?.exist
                      : item.audits[1]?.date === firstDate
                      ? item.audits[1]?.exist
                      : item.audits[2]?.exist;
                  let second =
                    item.audits[0]?.date === secondDate
                      ? item.audits[0]?.exist
                      : item.audits[1]?.date === secondDate
                      ? item.audits[1]?.exist
                      : item.audits[2]?.exist;
                  let third =
                    item.audits[0]?.date === thirdDate
                      ? item.audits[0]?.exist
                      : item.audits[1]?.date === thirdDate
                      ? item.audits[1]?.exist
                      : item.audits[2]?.exist;
                  let firstResult = first ? "YES," : "NO,";
                  let secondResult = second ? "YES," : "NO,";
                  let thirdResult = third ? "YES" : "NO";
                  let severityResult =
                    item.severity === "1"
                      ? "Low"
                      : item.severity === "2"
                      ? "Medium"
                      : item.severity === "3"
                      ? "High"
                      : item.severity === "4"
                      ? "Critical"
                      : item.severity === "secure"
                      ? "Secure"
                      : item.severity === "insecure"
                      ? "Insecure"
                      : "Info";

                  return `${item?.name},${severityResult},${
                    firstDate ? firstResult : ""
                  }${secondDate ? secondResult : ""}${
                    thirdDate ? thirdResult : ""
                  }`;
                });

                const csvContent = [csvHeader, ...csvRows].join("\n");

                var table = document.createElement("table");
                var tableBody = document.createElement("tbody");

                var rows = csvContent.split("\n");
                for (var i = 0; i < rows.length; i++) {
                  var row = document.createElement("tr");
                  var cells = rows[i].split("\t");
                  for (var j = 0; j < cells.length; j++) {
                    var cell = document.createElement(i === 0 ? "th" : "td");
                    cell.appendChild(document.createTextNode(cells[j]));
                    row.appendChild(cell);
                  }
                  tableBody.appendChild(row);
                }

                table.appendChild(tableBody);
                document.body.appendChild(table);

                var range = document.createRange();
                range.selectNode(table);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);

                try {
                  document.execCommand("copy");
                  toastSuccess("All Rows Copied");
                } catch (err) {
                  console.error("Panoya kopyalama hatası:", err);
                }

                table.remove();
                window.getSelection().removeAllRanges();
              }}
            >
              <span className="avatar-title bg-transparent">
                <i className="mdi mdi-content-copy  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <button
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                downloadCSV(data);
              }}
              title="Download CSV"
            >
              <span className="avatar-title bg-transparent d-flex flex-column ">
                <i className="mdi mdi-microsoft-excel  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <Dropdown
              isOpen={btnprimary1}
              toggle={() => setBtnprimary1(!btnprimary1)}
            >
              <DropdownToggle
                title="Column Visibility"
                tag="button"
                className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              >
                <i className="mdi mdi-eye" />
                <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                  <span className="visually-hidden">unread messages</span>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setSeeName(!seeName);
                  }}
                  className={`${seeName ? "bg-soft-primary" : ""}`}
                >
                  Name
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setSeeSeverity(!seeSeverity);
                  }}
                  className={`${seeSeverity ? "bg-soft-primary" : ""}`}
                >
                  Severity
                </DropdownItem>
                {firstDate && (
                  <DropdownItem
                    onClick={() => {
                      setSeeInFirst(!seeInFirst);
                    }}
                    className={`${seeInFirst ? "bg-soft-primary" : ""}`}
                  >
                    {firstCreatedAt}
                  </DropdownItem>
                )}
                {secondDate && (
                  <DropdownItem
                    onClick={() => {
                      setSeeInSecond(!seeInSecond);
                    }}
                    className={`${seeInSecond ? "bg-soft-primary" : ""}`}
                  >
                    {secondCreatedAt}
                  </DropdownItem>
                )}
                {thirdDate && (
                  <DropdownItem
                    onClick={() => {
                      setSeeInThird(!seeInThird);
                    }}
                    className={`${seeInThird ? "bg-soft-primary" : ""}`}
                  >
                    {thirdCreatedAt}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {seeName && (
                <th
                  {...headerGroup.headers[0].getHeaderProps(
                    headerGroup.headers[0].getSortByToggleProps()
                  )}
                  className="col"
                  style={{ width: "400px" }}
                >
                  {headerGroup.headers[0].Header}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[0].isSorted
                      ? headerGroup.headers[0].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seeSeverity && (
                <th
                  {...headerGroup.headers[1].getHeaderProps(
                    headerGroup.headers[1].getSortByToggleProps()
                  )}
                  className="col"
                >
                  {headerGroup.headers[1].Header}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[1].isSorted
                      ? headerGroup.headers[1].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seeInFirst && (
                <th
                  className={`${
                    seeInFirst && seeInSecond && seeInThird ? "col" : "col"
                  }`}
                >
                  {headerGroup.headers[2].Header}
                </th>
              )}
              {seeInSecond && (
                <th
                  className={`${
                    seeInFirst && seeInSecond && seeInThird ? "col" : "col"
                  }`}
                >
                  {headerGroup.headers[3].Header}
                </th>
              )}
              {seeInThird && (
                <th
                  className={`${
                    seeInFirst && seeInSecond && seeInThird ? "col" : "col"
                  }`}
                >
                  {headerGroup.headers[4].Header}
                </th>
              )}
            </tr>
          ))}
        </thead>

        {data.length > 0 && (
          <tbody {...getTableBodyProps()}>
            {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={index}>
                  <tr
                    onClick={() => {
                      setDown(!down);
                      rowIdFunc(row.id);
                    }}
                    style={{ width: "100%", cursor: "pointer" }}
                    {...row.getRowProps()}
                  >
                    {seeName && (
                      <td style={{ width: "400px" }} className="col">
                        {row.cells[0].value}
                      </td>
                    )}
                    {seeSeverity && (
                      <td className="col">
                        {row.cells[1].value === "1" ? (
                          <span className="badge bg-severity-low font-size-18">
                            Low
                          </span>
                        ) : row.cells[1].value === "2" ? (
                          <span className="badge bg-severity-medium font-size-18">
                            Medium
                          </span>
                        ) : row.cells[1].value === "3" ? (
                          <span className="badge bg-severity-high font-size-18">
                            High
                          </span>
                        ) : row.cells[1].value === "4" ? (
                          <span className="badge bg-severity-critical font-size-18">
                            Critical
                          </span>
                        ) : row.cells[1].value === "secure" ? (
                          <span className="badge bg-success font-size-18">
                            Secure
                          </span>
                        ) : row.cells[1].value === "insecure" ? (
                          <span className="badge bg-danger font-size-18">
                            Insecure
                          </span>
                        ) : (
                          <span className="badge bg-severity-info font-size-18">
                            Info
                          </span>
                        )}
                      </td>
                    )}
                    {seeInFirst && (
                      <td className="col">
                        {row.original.audits[0]?.date === firstDate ? (
                          row.cells[2]?.value ? (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-danger font-size-16 me-2 "></i>
                              YES
                            </span>
                          ) : (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-secondary font-size-16 me-2 "></i>
                              No
                            </span>
                          )
                        ) : row.original?.audits[1]?.date === firstDate ? (
                          row.cells[3]?.value ? (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-danger font-size-16 me-2 "></i>
                              YES
                            </span>
                          ) : (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-secondary font-size-16 me-2 "></i>
                              No
                            </span>
                          )
                        ) : row.cells[4]?.value ? (
                          <span>
                            <i className="mdi mdi-bug-check-outline text-danger font-size-16 me-2 "></i>
                            YES
                          </span>
                        ) : (
                          <span>
                            <i className="mdi mdi-bug-check-outline text-secondary font-size-16 me-2 "></i>
                            No
                          </span>
                        )}
                      </td>
                    )}
                    {seeInSecond && (
                      <td className="col">
                        {row.original.audits[0]?.date === secondDate ? (
                          row.cells[2]?.value ? (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-danger font-size-16 me-2 "></i>
                              YES
                            </span>
                          ) : (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-secondary font-size-16 me-2 "></i>
                              No
                            </span>
                          )
                        ) : row.original.audits[1]?.date === secondDate ? (
                          row.cells[3]?.value ? (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-danger font-size-16 me-2 "></i>
                              YES
                            </span>
                          ) : (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-secondary font-size-16 me-2 "></i>
                              No
                            </span>
                          )
                        ) : row.cells[4]?.value ? (
                          <span>
                            <i className="mdi mdi-bug-check-outline text-danger font-size-16 me-2 "></i>
                            YES
                          </span>
                        ) : (
                          <span>
                            <i className="mdi mdi-bug-check-outline text-secondary font-size-16 me-2 "></i>
                            No
                          </span>
                        )}
                      </td>
                    )}
                    {seeInThird && (
                      <td className="col">
                        {row.original.audits[0]?.date === thirdDate ? (
                          row.cells[2]?.value ? (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-danger font-size-16 me-2 "></i>
                              YES
                            </span>
                          ) : (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-secondary font-size-16 me-2 "></i>
                              No
                            </span>
                          )
                        ) : row.original.audits[1]?.date === thirdDate ? (
                          row.cells[3]?.value ? (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-danger font-size-16 me-2 "></i>
                              YES
                            </span>
                          ) : (
                            <span>
                              <i className="mdi mdi-bug-check-outline text-secondary font-size-16 me-2 "></i>
                              No
                            </span>
                          )
                        ) : row.cells[4]?.value ? (
                          <span>
                            <i className="mdi mdi-bug-check-outline text-danger font-size-16 me-2 "></i>
                            YES
                          </span>
                        ) : (
                          <span>
                            <i className="mdi mdi-bug-check-outline text-secondary font-size-16 me-2 "></i>
                            No
                          </span>
                        )}
                      </td>
                    )}
                    {/* {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })} */}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        )}
      </table>
      {data.length < 1 && (
        <span
          style={{ width: "100%" }}
          className="d-flex justify-content-center "
        >
          No data available in table
        </span>
      )}
      <Row className="">
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ReportCompareTable;
