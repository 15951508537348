import React from "react";
import DonutChart from "./Graphics/DonutChart";

const Product = ({ data }) => {
  const reverseObject = (obj) => {
    const reversed = {};
    const keys = Object.keys(obj);

    for (let i = keys.length - 1; i >= 0; i--) {
      reversed[keys[i]] = obj[keys[i]];
    }

    return reversed;
  };

  const reversedData = reverseObject(data);

  const randomHexCode = () => {
    const randomColor = Math.floor(Math.random() * 16777215)
      .toString(16)
      .toUpperCase();
    return `#${randomColor.padStart(6, "0")}`;
  };

  const colors = [];

  for (let i = 0; i < 200; i++) {
    const hexKodu = randomHexCode();
    colors.push(hexKodu);
  }

  const calculateTotal = (data) => {
    let total = 0;

    // Verilen objenin her bir değerini toplamaya ekle
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        total += data[key];
      }
    }

    return total;
  };
  const total = calculateTotal(data);

  const calculatePercentage = (num1, num2) => {
    const percentage = (num1 / num2) * 100;
    return percentage.toFixed(1);
  };

  return (
    <div
      style={{ maxHeight: "350px", overflowY: "overlay", overflowX: "hidden" }}
      className="special-card d-flex flex-column px-0 h-100 "
    >
      <div className="d-flex justify-content-start align-items-center border-bottom ps-3 ">
        <h5>Products</h5>
      </div>
      <div className="row mt-3 ">
        <div className="col-7 d-flex justify-content-between ps-4 flex-column  ">
          <div className="row">
            <div className="col-9">
              <span className="font-size-14 fw-semibold my-2 ps-2 ms-1  ">
                Product
              </span>
            </div>
            <div className="col-2">
              <span className="font-size-14 fw-semibold my-2 me-3  ">
                Devices
              </span>
            </div>
            <div className="col-1">
              <span className="font-size-14 fw-semibold my-2  ">%</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column ">
                {Object.entries(reversedData)?.map(([name, count], index) => {
                  const percentage = calculatePercentage(count, total);
                  return (
                    <div key={name} className="row">
                      <div className="col-9">
                        <div className="d-flex flex-row align-items-center mb-2 ">
                          <div
                            style={{
                              width: "5px",
                              height: "5px",
                              backgroundColor: colors[index],
                            }}
                            className="rounded justify-content-center d-flex ps-1  "
                          />
                          <span className="font-size-13 fw-medium ms-2  ">
                            {name}
                          </span>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center ">
                        <span
                          key={name + "s"}
                          className="font-size-13 fw-medium  me-3 mb-2  "
                        >
                          {count}
                        </span>
                      </div>
                      <div className="col-1 d-flex align-items-center ">
                        <span
                          key={name + "a"}
                          className="font-size-13 fw-medium  me-3  mb-2 "
                        >
                          {percentage}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-5 d-flex justify-content-center align-items-center ">
          <DonutChart data={reversedData} colors={colors} total={total} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Product);
