import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import IgnoredVulnsTable from "../../../components/Customer/IgnoredVulns/IgnoredVulnsTable";
import { get } from "../../../helpers/api_helper";
import { Container, Spinner } from "reactstrap";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const IgnoredVulns = () => {
  document.title = "Ignored Vulns | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [entryValue, setEntryValue] = useState(10);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [renderGetIgnoredVulns, set_renderGetIgnoredVulns] = useState(false);
  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getIgnoredVulns = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/ignored-vulns`);
      if (result?.success) {
        const updatedData = result?.data?.map((vulnerability) => {
          if (vulnerability?.false_positive) {
            return {
              ...vulnerability,
              type: "False Positive",
            };
          } else if (vulnerability?.accepted_risk) {
            return {
              ...vulnerability,
              type: "Ignored",
            };
          } else {
            return {
              ...vulnerability,
              type: "Fixed",
            };
          }
          return vulnerability;
        });
        setData(updatedData);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getIgnoredVulns();
  }, [renderGetIgnoredVulns]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Ignored Vulns" breadcrumbItem="Ignored Vulns" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100 my-5  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}{" "}
          {!isLoading && data && (
            <div
              className="special-card"
              style={{
                overflowX: windowWidth < 800 ? "scroll" : "none",
              }}
            >
              <IgnoredVulnsTable
                data={data}
                entryValue={entryValue}
                changeValue={(e) => changeValue(e)}
                renderGetIgnoredVulns={renderGetIgnoredVulns}
                set_renderGetIgnoredVulns={(e) => set_renderGetIgnoredVulns(e)}
                getIgnoredVulns={getIgnoredVulns}
              />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IgnoredVulns;
