import React, { useState } from "react";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { del, put, patch } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const Endpoint = ({ data }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const dispatch = useDispatch();

  const [lan_netmask, set_lan_netmask] = useState(data?.data?.lan_netmask);
  const [lan_ip, set_lan_ip] = useState(data?.data?.lan_ip);
  const [lan_gw, set_lan_gw] = useState(data?.data?.lan_gw);
  const [lan_reserv_ip, set_lan_reserv_ip] = useState(
    data?.data?.lan_reserv_ip
  );
  const [wan_ip, set_wan_ip] = useState(data?.data?.wan_ip);

  const [addLoading, setAddLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [modal_standard6, setmodal_standard6] = useState(false);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const tog_standard6 = () => {
    setmodal_standard6(!modal_standard6);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "success",
      timer: 3000, // 3000 milisaniye (3 saniye)
      timerProgressBar: true, // İlerleme çubuğunu göster
      showConfirmButton: false,
    });
  };

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });

  const updateEndpoint = async () => {
    paceFunction(true);
    let updateData = {
      lan_gw,
      lan_ip,
      lan_netmask,
      lan_reserv_ip,
      wan_ip,
      id: data.data.id,
    };
    try {
      let result = await patch("/customer/settings/endpoint", updateData);
      if (result?.success) {
        togglePopover();
        toastSuccess(result?.message || "Endpoint updated.");
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  const addEndpoint = async () => {
    paceFunction(true);
    setAddLoading(true);
    try {
      let result = await put("/customer/settings/endpoint");
      if (result?.success) {
        tog_standard5();
        showSweetAlert("Endpoint setup completed.");
        dispatch({ type: "RENDER_COMPONENT2_REQ" });
        // dispatch(renderComponent2());
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  const removeEndpoint = async () => {
    paceFunction(true);
    setRemoveLoading(true);
    try {
      let result = await del("/customer/settings/endpoint");
      if (result?.success) {
        tog_standard6();
        toastSuccess(result?.message || "Endpoint was removed.");
        dispatch({ type: "RENDER_COMPONENT2_REQ" });
        // dispatch(renderComponent2());
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setRemoveLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {data?.success && (
        <Popover
          placement="top"
          isOpen={popoverOpen}
          target="confirmationEndpointTooltip"
          toggle={togglePopover}
        >
          <PopoverHeader>Are you sure ?</PopoverHeader>
          <PopoverBody>
            <Button onClick={updateEndpoint} color="success">
              Yes
            </Button>
            <Button onClick={togglePopover} color="danger">
              No
            </Button>
          </PopoverBody>{" "}
        </Popover>
      )}
      {/* Setup Endpoint */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addEndpoint}
        text="Are you sure you want to set up endpoint?"
        loadingAction={true}
        isLoading={addLoading}
      />
      {/* Remove Endpoint */}
      <ConfirmModal
        modal_standard={modal_standard6}
        tog_standard={tog_standard6}
        setmodal_standard={setmodal_standard6}
        targetFunction={removeEndpoint}
        text="Are you sure you want to remove endpoint?"
        loadingAction={true}
        isLoading={removeLoading}
      />
      <div className="d-flex justify-content-center  align-items-center ">
        <a
          className="btn-floating btn-lg btn-default"
          href="https://www.youtube.com/watch?v=VV511tWSnT4"
          target="_blank"
          rel="noreferrer"
        >
          <i
            title="Watch Video"
            className="mdi mdi-help-circle-outline label-icon text-primary  fs-2 "
          ></i>
        </a>
      </div>
      {data?.success ? (
        <button
          onClick={tog_standard6}
          type="button"
          id="claBtn"
          className="btn btn-primary waves-effect btn-label waves-light w-100 mt-2 "
          title="Create New Access"
        >
          <i className="mdi mdi-trash-can label-icon"></i> Remove
        </button>
      ) : (
        <button
          onClick={tog_standard5}
          type="button"
          id="claBtn"
          className="btn btn-success waves-effect btn-label waves-light w-100 mt-2 "
          title="Remove Access"
        >
          <i className="mdi mdi-cloud-download label-icon"></i> Set Up Endpoint
        </button>
      )}
      {data?.success && (
        <div className="special-card mt-4">
          <div className="pb-3">
            <div className="d-flex justify-content-between align-items-center  mb-3  ">
              <div className="align-items-center d-flex fw-bold fs-4 ">
                <span>Vpn Service</span>
                <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-3 " />
                <span>{data?.data?.vpn_service}</span>
              </div>{" "}
              {data?.data?.is_connect ? (
                <span className="fw-bold fs-4 ">
                  <i className="mdi mdi-lan-connect me-3 text-success" />
                  Connected
                </span>
              ) : (
                <span className="fw-bold fs-4 ">
                  <i className="mdi mdi-lan-disconnect me-3 text-danger" />
                  Disconnected
                </span>
              )}
            </div>
            <hr className="mb-3 " />
            <div className="row mb-3 ">
              <div className="col-xl-3 align-items-center justify-content-center ">
                <div className="d-flex  ">
                  <h5 className="font-size-15">Lan Ip Address :</h5>
                </div>
              </div>
              <div className="col-xl">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    style={{ width: "80%" }}
                    name="lanip"
                    defaultValue={lan_ip}
                    onChange={(e) => set_lan_ip(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3 ">
              <div className="col-xl-3 align-items-center justify-content-center ">
                <div className="d-flex  ">
                  <h5 className="font-size-15">Lan Gateway :</h5>
                </div>
              </div>
              <div className="col-xl">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    style={{ width: "80%" }}
                    name="langw"
                    defaultValue={lan_gw}
                    onChange={(e) => set_lan_gw(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3 ">
              <div className="col-xl-3 align-items-center justify-content-center ">
                <div className="d-flex  ">
                  <h5 className="font-size-15">Reserved Ip Address :</h5>
                </div>
              </div>
              <div className="col-xl">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    style={{ width: "80%" }}
                    name="reservip"
                    defaultValue={lan_reserv_ip}
                    onChange={(e) => set_lan_reserv_ip(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3 ">
              <div className="col-xl-3 align-items-center justify-content-center ">
                <div className="d-flex  ">
                  <h5 className="font-size-15">Lan Netmask :</h5>
                </div>
              </div>
              <div className="col-xl">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    style={{ width: "80%" }}
                    name="netmask"
                    defaultValue={lan_netmask}
                    onChange={(e) => set_lan_netmask(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3 ">
              <div className="col-xl-3 align-items-center justify-content-center ">
                <div className="d-flex  ">
                  <h5 className="font-size-15">Wan Ip Address :</h5>
                </div>
              </div>
              <div className="col-xl">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    style={{ width: "80%" }}
                    name="wanip"
                    defaultValue={wan_ip}
                    onChange={(e) => set_wan_ip(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <hr className="my-3" />

            <div className="d-flex justify-content-end ">
              <button
                onClick={togglePopover}
                type="button"
                id="confirmationEndpointTooltip"
                className="btn btn-primary waves-effect btn-label waves-light  "
              >
                <i className="mdi mdi-check-all me-3 label-icon"></i>Save
                Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default React.memo(Endpoint);
