import React from "react";

const CyberNewsComponent = ({ cyberNews }) => {
  return (
    <div className="row">
      {cyberNews?.map((news, index) => {
        const fullDate = new Date(news?.date);
        const tarihFormati = {
          day: "numeric",
          month: "short",
          year: "numeric",
        };
        const justDate = fullDate.toLocaleDateString("en-US", tarihFormati);
        return (
          <div key={index} className="col-xxl-3 col-xl-4 col-lg-6 mb-3 ">
            <div className="special-card h-100 d-flex flex-column justify-content-between  ">
              <div className="d-flex align-items-center justify-content-between text-black fw-semibold   ">
                <span>{news?.title}</span>
              </div>
              <hr className="my-1" />
              <div className="mb-2 ">
                <div className="d-flex align-items-center justify-content-between  flex-row ">
                  <div>{news?.content}</div>
                  <a
                    href={news?.link}
                    target="_blank"
                    rel="noreferrer"
                    className="mt-1 "
                  >
                    <i
                      title={news?.link}
                      className="mdi mdi-arrow-top-right-bold-box-outline fs-4 text-primary ms-2   "
                    />
                  </a>
                </div>
              </div>
              <div>
                {news?.categories?.map((item, i) => (
                  <div
                    key={i}
                    className="badge badge-soft-primary font-size-12 me-1  "
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className="d-flex align-items-center justify-content-end text-black fw-semibold mt-3  ">
                <span>{justDate}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CyberNewsComponent;
