import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { del, get, post, put, patch } from "../../helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import images
import logo from "../../assets/images/logo_colors_lg.svg";
import CarouselPage from "./CarouselPage";
import DateIconComponent from "../../common/DateIconComponent";
import errorFunctionComponent from "../../common/errorFunctionComponent";

const RecoverPassword = () => {
  document.title = "Reset Password | PentestBX";
  const { errorToastFunction } = errorFunctionComponent();
  const [isLoading, set_isLoading] = useState(false);
  const [seemContent, set_seemContent] = useState(false);

  const [password_new, set_password_new] = useState("");
  const [password_confirm, set_password_confirm] = useState("");

  const router = useHistory();
  const location = useLocation();
  const paramStr = location.pathname.split("/reset-password/")[1]; // URL'deki parametre stringi
  const decodedKey = decodeURIComponent(paramStr); // URL kodlamasını çöz

  const getVerifyLink = async () => {
    set_isLoading(true);
    try {
      let result = await get(`/account/reset-password/${decodedKey}`);
      if (result?.success) {
        set_seemContent(true);
        set_isLoading(false);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
      setTimeout(() => {
        set_isLoading(false);
        router.push("/login");
      }, 3000);
    }
  };

  const toastError = (msg) =>
    toast.error(msg || "Somethings went wrong", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  const toastWarning = (msg) =>
    toast.warning(msg || "Somethings went wrong", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  const toastSuccess = (msg) =>
    toast.success(msg || "Somethings went wrong", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });

  useEffect(() => {
    getVerifyLink();
  }, []);

  const sendResetPassword = async (e) => {
    e.preventDefault();
    set_isLoading(true);
    let data = {
      password_new,
      password_confirm,
    };
    if (password_new === password_confirm) {
      try {
        let result = await patch(`/account/reset-password/${decodedKey}`, data);
        if (result?.success) {
          toastSuccess(result?.message || "Password Changed Successfully");
          setTimeout(() => {
            router.push("/login");
          }, 3000);
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else {
      toastWarning("Passwords do not match !");
    }

    set_isLoading(false);
  };

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              {isLoading ? (
                <div className="auth-full-page-content d-flex justify-content-center  align-items-center h-100  ">
                  <Spinner
                    style={{ width: "3rem", height: "3rem" }}
                    className="ms-2 "
                    color="primary"
                  />
                </div>
              ) : (
                <div className="auth-full-page-content d-flex p-sm-5 p-4">
                  {seemContent && (
                    <div className="w-100">
                      <div className="d-flex flex-column h-100">
                        <div className="mb-4 mb-md-5 text-center">
                          <Link to="/dashboard" className="d-block auth-logo">
                            <img src={logo} alt="" height="28" />{" "}
                          </Link>
                        </div>
                        <div className="auth-content my-auto">
                          <div className="text-center">
                            <h5 className="mb-0">Reset Password</h5>
                          </div>
                          <form
                            onSubmit={sendResetPassword}
                            className="custom-form mt-4"
                          >
                            <div className="mb-3">
                              <label className="form-label">Password New</label>
                              <input
                                type="password"
                                className="form-control"
                                id="password_new"
                                placeholder="Enter Password"
                                defaultValue={password_new}
                                onChange={(e) =>
                                  set_password_new(e.target.value)
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">
                                Password Confirm
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="password_confirm"
                                placeholder="Enter Again Password"
                                defaultValue={password_confirm}
                                onChange={(e) =>
                                  set_password_confirm(e.target.value)
                                }
                              />
                            </div>
                            <div className="mb-3 mt-4">
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                              >
                                Send
                              </button>
                            </div>
                          </form>

                          <div className="mt-5 text-center">
                            <p className="text-muted mb-0">
                              Remember It ?{" "}
                              <Link
                                to="/login"
                                className="text-primary fw-semibold"
                              >
                                {" "}
                                Log In{" "}
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <DateIconComponent />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RecoverPassword;
