import React from "react";

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
const WeakCredentials = () => {
  //meta title
  document.title = "Weak Credentials| PentestBX";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Weak Credentials"
            breadcrumbItem="Weak Credentials"
          />

          <div>Weak Credentials</div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WeakCredentials;
