import React, { useEffect, useState } from "react";
import { get } from "../../../helpers/api_helper";
import { Spinner } from "reactstrap";
import DashboardComponent from "../../../components/Customer/Dashboard/DashboardComponent";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../common/InfoNoDataComponent";

const CustomerDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const { errorFunctionNotSwal } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [seemInfoComponent, set_seemInfoComponent] = useState(false);
  const [seemInfoComponentEndpoint, set_seemInfoComponentEndpoint] =
    useState(false);

  const getDashboardData = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/dashboard`);
      if (result?.success) {
        let weeklyOverview = result?.data?.weekly_overview;
        if (weeklyOverview[weeklyOverview.length - 1]?.score == null) {
          set_seemInfoComponent(true);
        } else {
          setData(result?.data);
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunctionNotSwal(error);
      if (!error?.response?.data?.success) {
        if (error?.response?.data?.message === "Endpoint is not connected.") {
          set_seemInfoComponentEndpoint(true);
        }
      }
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getDashboardData();
  }, []);

  return (
    <div className="">
      {isLoading && (
        <div className="d-flex justify-content-center  align-items-center h-100  ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            className="ms-2 "
            color="primary"
          />
        </div>
      )}
      {!isLoading && data && <DashboardComponent data={data} />}
      {!isLoading && seemInfoComponent && (
        <InfoNoDataComponent
          text="No scans were discovered"
          component="dashboard"
        />
      )}
      {!isLoading && seemInfoComponentEndpoint && (
        <InfoNoDataComponent
          text="Endpoint is not connected."
          component="dashboard"
        />
      )}
    </div>
  );
};

export default CustomerDashboard;
