import React, { useState, useEffect } from "react";
import TagsComponent from "../../../../../components/Customer/CyberWatch/ThreatIntelligence/Cves/TagsComponent";
import SubscriptionsComponent from "../../../../../components/Customer/CyberWatch/ThreatIntelligence/Cves/SubscriptionsComponent";
import {
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Container,
} from "reactstrap";
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../../../common/InfoNoDataComponent";
import { get } from "../../../../../helpers/api_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import classnames from "classnames";
import VulnerabilitiesWatcherTabs from "./VulnerabilitiesWatcherTabs";

const SubscriptionsTags = () => {
  document.title = "Threat Intelligence | Subscriptions & Tags";

  const [isLoading, setIsLoading] = useState(false);
  const [seemInfoComponentGrantAccess, set_seemInfoComponentGrantAccess] =
    useState(false);

  const [tags, set_tags] = useState();
  const [subscriptions, set_subscriptions] = useState();

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [activeTab1, setactiveTab1] = useState("1");

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "error",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  const getSubscriptions = async () => {
    paceFunction(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves/subscriptions`
      );
      if (result?.success) {
        set_subscriptions(result?.data);
        getTags();
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cves"
            );
          }
        }
      }
    }
    paceFunction(false);
  };

  const getTags = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/cyberwatch/ti/cves/tags`);
      if (result?.success) {
        set_tags(result?.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cves"
            );
          }
        }
      }
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getSubscriptions();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`Threat Intelligence / Subscriptions & Tags`}
            breadcrumbItem={`Threat Intelligence / Subscriptions & Tags`}
          />
          <VulnerabilitiesWatcherTabs />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && !seemInfoComponentGrantAccess && (
            <>
              <Col lg={6}>
                <Nav tabs className="nav-tabs-custom2 nav-justified">
                  {subscriptions && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "1",
                        })}
                        onClick={() => {
                          toggle1("1");
                        }}
                      >
                        Subscriptions
                      </NavLink>
                    </NavItem>
                  )}
                  {tags && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "2",
                        })}
                        onClick={() => {
                          toggle1("2");
                        }}
                      >
                        Tags
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>{" "}
              </Col>

              <TabContent activeTab={activeTab1} className="p-3 text-muted">
                {subscriptions && (
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <SubscriptionsComponent
                          subscriptions={subscriptions}
                          getSubscriptions={getSubscriptions}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {tags && (
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <TagsComponent tags={tags} getTags={getTags} />
                      </Col>
                    </Row>
                  </TabPane>
                )}
              </TabContent>
            </>
          )}
          {!isLoading && seemInfoComponentGrantAccess && (
            <InfoNoDataComponent
              text="Module permission denied. Contact with customer account."
              component="cves"
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SubscriptionsTags;
