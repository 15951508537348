import React from "react";

const Ports = ({ data }) => {
  return (
    <div className="row">
      {JSON.parse(data?.data)?.length > 0 &&
        JSON.parse(data?.data).map((port, index) => {
          let services = port?.results?.map((item) => {
            if (item?.result?.data?.service !== undefined) {
              return item?.result?.data?.service;
            }
          });

          let serviceFilter = services?.filter((item) => item !== undefined);
          return (
            <div key={port?.port} className="mb-3  col-xl-2 col-lg-3 col-md-6">
              <div className="special-card2 h-100">
                <h4 className="text-center">{port?.port}</h4>
                <div className="d-flex align-items-center justify-content-between  ">
                  <div className="flex">
                    <strong>Protocol:</strong> {port?.protocol}
                  </div>
                  <div className="flex">
                    <strong>Service:</strong>{" "}
                    {serviceFilter[0]?.name ? serviceFilter[0].name : "-"}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {JSON.parse(data?.data)?.length < 1 && (
        <h2 className="text-center">There is no port...</h2>
      )}
    </div>
  );
};

export default Ports;
