import React, { useEffect, useRef, useState } from "react";
import { put } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";

const DropdownHelp = ({
  row,
  assetName,
  updated_at,
  moduleID,
  getVulnerabilities,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isOpen, setIsOpen] = useState(false);

  const [addLoading, set_addLoading] = useState(false);

  const [modal_standard5, setmodal_standard5] = useState(false);
  const [modal_standard6, setmodal_standard6] = useState(false);
  const [modal_standard7, setmodal_standard7] = useState(false);

  const dropdownRef = useRef(null);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const tog_standard6 = () => {
    setmodal_standard6(!modal_standard6);
    removeBodyCss();
  };

  const tog_standard7 = () => {
    setmodal_standard7(!modal_standard7);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const fixedFunction = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      name: row?.original?.name,
      address: [assetName],
      fixed: true,
      false_positive: false,
      accepted_risk: false,
      module_id: moduleID,
      date: updated_at,
    };
    try {
      let result = await put("/customer/module/ignored-vulns", data);
      if (result?.success) {
        toastSuccess(result?.message || "İgnore Added");
        tog_standard5();
        getVulnerabilities();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  const falsePositiveFunction = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      name: row?.original?.name,
      address: [assetName],
      fixed: false,
      false_positive: true,
      accepted_risk: false,
      module_id: moduleID,
      date: updated_at,
    };
    try {
      let result = await put("/customer/module/ignored-vulns", data);
      if (result?.success) {
        toastSuccess(result?.message || "İgnore Added");
        tog_standard7();
        getVulnerabilities();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  const ignoredFunction = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      name: row?.original?.name,
      address: [assetName],
      fixed: false,
      false_positive: false,
      accepted_risk: true,
      module_id: moduleID,
      date: updated_at,
    };
    try {
      let result = await put("/customer/module/ignored-vulns", data);
      if (result?.success) {
        toastSuccess(result?.message || "İgnore Added");
        tog_standard6();
        getVulnerabilities();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Fixed */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={fixedFunction}
        text="Are you sure ?"
        loadingAction={true}
        isLoading={addLoading}
      />
      {/* Ingored */}
      <ConfirmModal
        modal_standard={modal_standard6}
        tog_standard={tog_standard6}
        setmodal_standard={setmodal_standard6}
        targetFunction={ignoredFunction}
        text="Are you sure ?"
        loadingAction={true}
        isLoading={addLoading}
      />
      {/* False Positive */}
      <ConfirmModal
        modal_standard={modal_standard7}
        tog_standard={tog_standard7}
        setmodal_standard={setmodal_standard7}
        targetFunction={falsePositiveFunction}
        text="Are you sure ?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <div
        className={`dropdown-host ${isOpen ? "open" : ""}`}
        ref={dropdownRef}
      >
        <div className="selected-option-host" onClick={toggleDropdown}>
          <i className="mdi mdi-check text-primary fs-4 " />
          <span className="fs-6 fw-medium mx-1 ">Mark as</span>
          <i className="mdi mdi-chevron-down text-primary fs-4 " />
        </div>
        <ul className="options-host">
          <li
            onClick={() => {
              tog_standard5();
            }}
            className="fs-6"
          >
            Fixed
          </li>
          <li
            onClick={() => {
              tog_standard6();
            }}
            className="fs-6"
          >
            Ignored
          </li>
          <li
            onClick={() => {
              tog_standard7();
            }}
            className="fs-6"
          >
            False Positive
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default React.memo(DropdownHelp);
