import React from "react";
import { Row, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import emptyImage from "../../../assets/images/empty.png";
import { formatDateTransform } from "../../../common/utils/commonFunctions";

const SeveritySection = ({ data }) => {
  let criticalValues = [];
  let highValues = [];
  let mediumValues = [];
  let lowValues = [];
  let infoValues = [];
  let weekValues = [];

  if (data?.weekly_overview?.length > 0) {
    criticalValues = data?.weekly_overview?.map((item) => item.critical);
    highValues = data?.weekly_overview?.map((item) => item.high);
    mediumValues = data?.weekly_overview?.map((item) => item.medium);
    lowValues = data?.weekly_overview?.map((item) => item.low);
    infoValues = data?.weekly_overview?.map((item) => item.info);
    weekValues = data?.weekly_overview?.map((item) => item.week);
  }

  const donutSeries = [
    data.weekly_chance["critical"],
    data.weekly_chance["high"],
    data.weekly_chance["medium"],
    data.weekly_chance["low"],
    data.weekly_chance["info"],
  ];

  const series = [
    {
      name: "Critical",
      data: criticalValues,
    },
    {
      name: "High",
      data: highValues,
    },
    {
      name: "Medium",
      data: mediumValues,
    },
    {
      name: "Low",
      data: lowValues,
    },
    {
      name: "Info",
      data: infoValues,
    },
  ];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#FD625E", "#FD8C00", "#FDC500", "#2AB57D", "#4bA6EF"],
    fill: {
      type: "solid",
      colors: ["transparent"],
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: formatDateTransform(data?.date_format),
        datetimeUTC: false,
      },
      categories: weekValues,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: `${formatDateTransform(data?.date_format)} HH:mm`,
      },
    },
  };

  const donutOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
              label: "Total",
              fontSize: "30px",
              color: "rgb(188, 193, 200)",
              formatter: () => toplam,
            },
          },
        },
      },
    },
    labels: ["Critical", "High", "Medium", "Low", "Info"],
    colors: ["#FD625E", "#FD8C00", "#FDC500", "#2AB57D", "#4bA6EF"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const CalculateTotal = (dizi) => {
    var toplam = 0;
    for (var i = 0; i < dizi.length; i++) {
      toplam += dizi[i];
    }

    return toplam;
  };

  const totalDonut = CalculateTotal(donutSeries);

  return (
    <div className=" ">
      <Row className="align-items-center">
        <Col lg={6} xxl={8}>
          <div className="special-card mb-3 ">
            <div className="d-flex  py-2  align-items-center justify-content-between  ">
              <div>
                <span className="fw-bold fs-4 me-1 ">
                  Distribution of Severity{" "}
                </span>
              </div>
            </div>
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height="330"
            />
          </div>
        </Col>
        <Col lg={6} xxl={4}>
          <div className="special-card mb-3 ">
            <div className="d-flex  py-2  align-items-center justify-content-between  ">
              <div>
                <span className="fw-bold fs-4 me-1 ">Severity Changes </span>
              </div>
            </div>
            {totalDonut > 0 ? (
              <ReactApexChart
                className="pt-5"
                options={donutOptions}
                series={donutSeries}
                type="donut"
                height="330"
              />
            ) : (
              <div className="d-flex flex-column  align-items-center justify-content-center ">
                <img style={{ height: "312px" }} src={emptyImage} alt="empty" />
                <h4
                  // style={{ height: "336px" }}
                  className="text-center "
                >
                  No change in severity values
                </h4>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SeveritySection;
