import React, { useEffect, useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion, AnimatePresence } from "framer-motion";
import { get, post, put } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ReactJson from "react-json-view";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function NetworkMonitorTable({
  data,
  entryValue,
  changeValue,
  getAuditsOverview,
}) {
  const { errorFunction, errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();
  // Others
  const [rowId, setRowId] = useState([]);
  const [vulnsDetails, setVulnsDetails] = useState([]);
  const [moduleID, set_moduleID] = useState();
  const [extraInfo, set_extraInfo] = useState([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "first_name",
      },
      {
        Header: "IP",
        accessor: "address",
      },
      {
        Header: "MAC",
        accessor: "mac",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Service Count",
        accessor: "service_count",
      },
      {
        Header: "Product",
        accessor: "name",
      },
      {
        Header: "Accuracy",
        accessor: "accuracy",
      },
      {
        Header: "First Seen",
        accessor: "first_seen",
      },
      {
        Header: "Last Seen",
        accessor: "last_seen",
      },
      {
        Header: "",
        accessor: "is_exist",
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });

  const [sortBy, setSortBy2] = useState([{ id: "company_name", desc: false }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
    // useResizeColumns
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const rowIdFunc = (id) => {
    let tempArray = [...rowId];
    let check = rowId.some((item) => item === id);
    if (check) {
      let filterFunc = rowId.filter((item) => item !== id);
      setRowId(filterFunc);
    } else {
      tempArray.push(id);
      setRowId(tempArray);
    }
  };

  const extraInfoFunction = (id, info) => {
    let tempArray = [...extraInfo];
    let check = extraInfo.some((item) => item.id === id);
    if (check) {
      let filterFunc = extraInfo.filter((item) => item.id !== id);
      set_extraInfo(filterFunc);
    } else {
      let newArray = { id, info };
      tempArray.push(newArray);
      set_extraInfo(tempArray);
    }
  };

  const getModules = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/module");
      if (result?.success) {
        let dataArray = result?.data?.modules;
        const hasWebApp = dataArray.some((item) => item.name === "HOST");
        if (hasWebApp) {
          for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i].name === "HOST") {
              set_moduleID(dataArray[i].id);
            }
          }
        } else {
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    getModules();
  }, []);

  const getVulnsDetails = async (row) => {
    paceFunction(true);
    let tempVulnsDetails = vulnsDetails;
    let vulnDetsData = {
      id: row.original.id,
    };
    let isCheck = tempVulnsDetails.some(
      (item) => item?.host?.id === row.original.id
    );
    if (!isCheck) {
      try {
        let result = await post(`/customer/module/netmon`, vulnDetsData);
        if (result?.success) {
          tempVulnsDetails = [...tempVulnsDetails, result.data];
          setVulnsDetails(tempVulnsDetails);
          rowIdFunc(row.id);
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else {
      rowIdFunc(row.id);
    }
    paceFunction(false);
  };

  const addIpAsset = async (rowData) => {
    paceFunction(true);
    let data = {
      id: moduleID,
      assets: [{ address: rowData.address, is_private: true }],
    };
    try {
      let result = await put("/customer/module/asset", data);
      if (result?.success) {
        toastSuccess(result?.message || "Asset Added");
        getAuditsOverview();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  const convertJSONToCSV = (data) => {
    const csvHeader =
      "IP;MAC;Type;Service Count;Product;Accuracy;First Seen;Last Seen;Exist";

    const csvRows = data?.map((item) => {
      let exist = item?.original?.is_exist ? "YES" : "NO";

      return `${item?.original?.address || "-"};${item?.original?.mac || "-"};${
        item?.original?.type || "-"
      };${item?.original?.service_count};${item?.original?.name || "-"};${
        item?.original?.accuracy || "-"
      };${item?.original?.first_seen || "-"};${
        item?.original?.last_seen || "-"
      };${exist || "-"}`;
    });

    const csvContent = [csvHeader, ...csvRows].join("\n");

    return csvContent;
  };
  const downloadCSV = (data) => {
    // JSON verisini CSV'ye dönüştür
    const csvData = convertJSONToCSV(data);
    // CSV dosyasını indirme işlemi
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const fileName = "Network Monitor.csv";

    if (window.navigator.msSaveBlob) {
      // IE 10+ için
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // Diğer tarayıcılar için
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
  };

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            // style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col md={3}>
          <div className="dt-buttons flex-wrap justify-content-center d-flex ">
            <button
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                downloadCSV(rows);
              }}
              title="Download CSV"
            >
              <span className="avatar-title bg-transparent d-flex flex-column ">
                <i className="mdi mdi-microsoft-excel  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
          </div>
        </Col>
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <div style={{ maxHeight: "1000px", overflowY: "overlay" }}>
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th style={{ width: "50px " }}>
                  {headerGroup.headers[0].Header}
                </th>
                <th
                  {...headerGroup.headers[1].getHeaderProps(
                    headerGroup.headers[1].getSortByToggleProps()
                  )}
                >
                  {headerGroup.headers[1].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[1].isSorted
                      ? headerGroup.headers[1].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[2].getHeaderProps(
                    headerGroup.headers[2].getSortByToggleProps()
                  )}
                >
                  {headerGroup.headers[2].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[2].isSorted
                      ? headerGroup.headers[2].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[3].getHeaderProps(
                    headerGroup.headers[3].getSortByToggleProps()
                  )}
                >
                  {headerGroup.headers[3].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[3].isSorted
                      ? headerGroup.headers[3].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[4].getHeaderProps(
                    headerGroup.headers[4].getSortByToggleProps()
                  )}
                >
                  {headerGroup.headers[4].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[4].isSorted
                      ? headerGroup.headers[4].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[5].getHeaderProps(
                    headerGroup.headers[5].getSortByToggleProps()
                  )}
                >
                  {headerGroup.headers[5].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[5].isSorted
                      ? headerGroup.headers[5].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[6].getHeaderProps(
                    headerGroup.headers[6].getSortByToggleProps()
                  )}
                >
                  {headerGroup.headers[6].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[6].isSorted
                      ? headerGroup.headers[6].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[7].getHeaderProps(
                    headerGroup.headers[7].getSortByToggleProps()
                  )}
                >
                  {headerGroup.headers[7].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[7].isSorted
                      ? headerGroup.headers[7].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[8].getHeaderProps(
                    headerGroup.headers[8].getSortByToggleProps()
                  )}
                >
                  {headerGroup.headers[8].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[8].isSorted
                      ? headerGroup.headers[8].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  style={{ width: "50px " }}
                  // {...headerGroup.headers[8].getHeaderProps(
                  //   headerGroup.headers[8].getSortByToggleProps()
                  // )}
                >
                  {headerGroup.headers[9].Header}{" "}
                </th>
              </tr>
            ))}
          </thead>

          {data.length > 0 && (
            <tbody {...getTableBodyProps()}>
              {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
                prepareRow(row);
                return (
                  <React.Fragment key={index}>
                    <tr
                      className={`${
                        rowId.some((item) => item === row.id)
                          ? "bg-soft-primary"
                          : ""
                      }`}
                      style={{ width: "100%", cursor: "pointer" }}
                      {...row.getRowProps()}
                    >
                      {row.cells[4].value > 0 ? (
                        <td
                          style={{ cursor: "pointer", width: "50px" }}
                          onClick={() => {
                            getVulnsDetails(row);
                          }}
                        >
                          {vulnsDetails?.some(
                            (item) => item?.host?.id === row?.original?.id
                          ) && rowId.some((item) => item === row.id) ? (
                            <i className="fa fa-chevron-down" />
                          ) : (
                            <i className="fa fa-chevron-right" />
                          )}
                        </td>
                      ) : (
                        <td style={{ width: "50px" }}></td>
                      )}
                      <td className="col">{row.cells[1].value || "-"}</td>
                      <td className="col">{row.cells[2].value || "-"}</td>
                      <td className="col">{row.cells[3].value || "-"}</td>
                      <td className="col">{row.cells[4].value}</td>
                      <td className="col">{row.cells[5].value || "-"}</td>
                      <td className="col">{row.cells[6].value || "-"}</td>
                      <td className="col">{row.cells[7].value || "-"}</td>
                      <td className="col">{row.cells[8].value || "-"}</td>
                      <td style={{ width: "50px" }}>
                        {row.cells[9].value ? (
                          <i className="mdi mdi-check-outline me-3 text-success" />
                        ) : (
                          <button
                            onClick={() => addIpAsset(row.original)}
                            title="Add Asset"
                            className="btn btn-success btn-sm py-0  "
                          >
                            <i className="mdi mdi-plus font-size-14" />
                          </button>
                        )}
                      </td>
                      {/* {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })} */}
                    </tr>
                    <AnimatePresence>
                      {rowId.some((item) => item === row.id) &&
                        vulnsDetails?.some(
                          (item) => item?.host?.id === row.original.id
                        ) && (
                          <motion.tr
                            // style={{ backgroundColor: "#f8f8f8" }}
                            initial={{ y: -100, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: 0, opacity: 0 }}
                            transition={{ duration: 0.4 }}
                          >
                            {vulnsDetails?.map((vuln) => {
                              if (vuln?.host?.id === row.original.id) {
                                let filteredExtraInfo = extraInfo.filter(
                                  (item) => item.id == vuln?.host?.id
                                );
                                return (
                                  <td key={vuln?.host?.id}>
                                    <div className="row">
                                      <div
                                        className="row special-card p-0  "
                                        style={{ height: "180px" }}
                                      >
                                        <div
                                          style={{ width: "240px" }}
                                          className=" d-flex flex-column justify-content-center  align-items-center border-end"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30.3494mm"
                                            height="30.3494mm"
                                            viewBox="0 0 512 512"
                                          >
                                            <path
                                              id="Selection #5"
                                              fill="black"
                                              stroke="black"
                                              strokeWidth="1"
                                              className="wht"
                                              d="M 38.00,65.09
           C 33.25,66.33 27.90,67.27 24.65,71.21
             21.16,75.43 20.07,83.65 20.00,89.00
             20.00,89.00 20.00,137.00 20.00,137.00
             20.00,137.00 20.00,363.00 20.00,363.00
             20.01,369.66 20.69,379.79 26.21,384.35
             32.89,389.88 46.72,389.00 55.00,389.00
             55.00,389.00 123.00,389.00 123.00,389.00
             123.00,389.00 465.00,389.00 465.00,389.00
             471.39,388.99 480.82,388.36 485.61,383.61
             489.90,379.36 490.93,371.76 491.00,366.00
             491.00,366.00 491.00,319.00 491.00,319.00
             491.00,319.00 491.00,90.00 491.00,90.00
             490.99,82.87 490.38,73.44 483.90,68.99
             476.63,64.00 462.61,65.00 454.00,65.09
             454.00,65.09 387.00,65.09 387.00,65.09
             387.00,65.09 154.00,65.09 154.00,65.09
             154.00,65.09 78.00,65.09 78.00,65.09
             78.00,65.09 38.00,65.09 38.00,65.09 Z"
                                            />
                                            <path
                                              id="bottomLeg"
                                              fill="black"
                                              stroke="black"
                                              strokeWidth="1"
                                              className="cls-1"
                                              d="M 183.11,432.74
           C 177.65,436.20 177.72,443.95 183.11,446.26
             186.06,447.53 203.58,447.00 208.00,447.00
             208.00,447.00 321.00,447.00 321.00,447.00
             326.08,446.99 331.75,447.47 332.76,440.98
             333.36,437.10 331.93,433.58 327.96,432.14
             327.96,432.14 299.00,432.14 299.00,432.14
             299.00,432.14 221.00,432.14 221.00,432.14
             213.27,432.00 188.97,431.26 183.11,432.74 Z"
                                            />
                                            <path
                                              id="bottomLeg"
                                              fill="black"
                                              stroke="black"
                                              strokeWidth="1"
                                              className="cls-2"
                                              d="M 196.00,391.00
           C 196.00,391.00 196.00,432.00 196.00,432.00
             196.00,432.00 316.00,432.00 316.00,432.00
             316.00,432.00 316.00,391.00 316.00,391.00
             316.00,391.00 196.00,391.00 196.00,391.00 Z"
                                            />
                                            <path
                                              id="button"
                                              fill="black"
                                              stroke="black"
                                              strokeWidth="1"
                                              className="cls-2"
                                              d="M 248.00,361.00
           C 248.00,361.00 248.00,377.00 248.00,377.00
             248.00,377.00 266.00,377.00 266.00,377.00
             266.00,377.00 266.00,361.00 266.00,361.00
             266.00,361.00 248.00,361.00 248.00,361.00 Z"
                                            />
                                            <path
                                              id="button"
                                              fill="black"
                                              stroke="black"
                                              strokeWidth="1"
                                              className="cls-2"
                                              d="M 22.00,347.00
           C 22.00,347.00 22.00,349.00 22.00,349.00
             22.00,349.00 491.00,349.00 491.00,349.00
             491.00,349.00 491.00,347.00 491.00,347.00
             491.00,347.00 22.00,347.00 22.00,347.00 Z"
                                            />
                                            <path
                                              id="button"
                                              fill="white"
                                              stroke="black"
                                              strokeWidth="1"
                                              className="cls-2"
                                              d="M 37.00,80.00
           C 37.00,80.00 37.00,331.00 37.00,331.00
             37.00,331.00 476.00,331.00 476.00,331.00
             476.00,331.00 476.00,80.00 476.00,80.00
             476.00,80.00 37.00,80.00 37.00,80.00 Z"
                                            />
                                          </svg>
                                          <span className="fw-semibold font-size-13 text-center  ">
                                            {vuln?.host?.host_name || "-"}
                                          </span>
                                        </div>
                                        <div className="col  d-flex flex-column ">
                                          <div
                                            style={{ height: "90px" }}
                                            className="row"
                                          >
                                            <div className="col-3 d-flex flex-column justify-content-between border-bottom  border-end py-2 ps-3">
                                              <span className="text-secondary fw-semibold font-size-12">
                                                IP
                                              </span>
                                              <span className="fw-semibold font-size-16">
                                                {vuln?.host?.address || "-"}
                                              </span>
                                            </div>
                                            <div className="col-3 d-flex flex-column justify-content-between border-bottom  border-end py-2 ps-3">
                                              <span className="text-secondary fw-semibold font-size-12">
                                                MAC
                                              </span>
                                              <span className="fw-semibold font-size-16">
                                                {vuln?.host?.mac || "-"}
                                              </span>
                                            </div>
                                            <div className="col-3 d-flex flex-column justify-content-between border-bottom  border-end py-2 ps-3">
                                              <span className="text-secondary fw-semibold font-size-12">
                                                MAC OUI
                                              </span>
                                              <span className="fw-semibold font-size-16">
                                                {vuln?.host?.mac_oui || "-"}
                                              </span>
                                            </div>
                                            <div className="col-3 d-flex flex-column justify-content-between border-bottom   py-2 ps-3">
                                              <span className="text-secondary fw-semibold font-size-12">
                                                TYPE
                                              </span>
                                              <span className="fw-semibold font-size-16">
                                                {vuln?.host?.data?.osclass[0]
                                                  .type || "-"}
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            style={{ height: "90px" }}
                                            className="row"
                                          >
                                            <div className="col-3 d-flex flex-column justify-content-between  border-end   py-2 ps-3">
                                              <span className="text-secondary fw-semibold font-size-12">
                                                OS NAME
                                              </span>
                                              <span className="fw-semibold font-size-12">
                                                {vuln?.host?.data?.name || "-"}
                                              </span>
                                            </div>
                                            <div className="col-3 d-flex flex-column justify-content-between  border-end   py-2 ps-3">
                                              <span className="text-secondary fw-semibold font-size-12">
                                                OS FAMILY
                                              </span>
                                              <span className="fw-semibold font-size-16">
                                                {vuln?.host?.data?.osclass[0]
                                                  ?.osfamily || "-"}
                                              </span>
                                            </div>
                                            <div className="col-3 d-flex flex-column justify-content-between  border-end   py-2 ps-3">
                                              <span className="text-secondary fw-semibold font-size-12">
                                                OS GEN
                                              </span>
                                              <span className="fw-semibold font-size-16">
                                                {vuln?.host?.data?.osclass[0]
                                                  ?.osgen || "-"}
                                              </span>
                                            </div>
                                            <div className="col-3 d-flex flex-column justify-content-between   py-2 ps-3">
                                              <span className="text-secondary fw-semibold font-size-12">
                                                SERVICE COUNT
                                              </span>
                                              <span className="fw-semibold font-size-16">
                                                {vuln?.host?.service_count}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {vuln?.host?.extra_info?.length > 0 && (
                                        <div className="row my-2 ">
                                          <button
                                            onClick={() => {
                                              extraInfoFunction(
                                                vuln?.host?.id,
                                                vuln?.host?.extra_info
                                              );
                                            }}
                                            className={`btn btn-${
                                              extraInfo.some(
                                                (item) =>
                                                  item.id === vuln?.host?.id
                                              )
                                                ? "danger"
                                                : "success"
                                            } col-12`}
                                          >
                                            {extraInfo.some(
                                              (item) =>
                                                item.id === vuln?.host?.id
                                            )
                                              ? "Close"
                                              : "Extra Info"}
                                          </button>
                                          <AnimatePresence>
                                            {extraInfo.some(
                                              (item) =>
                                                item.id === vuln?.host?.id
                                            ) && (
                                              <motion.div
                                                initial={{
                                                  y: -100,
                                                  opacity: 0,
                                                }}
                                                animate={{ y: 0, opacity: 1 }}
                                                exit={{ y: 0, opacity: 0 }}
                                                transition={{ duration: 0.4 }}
                                                className="special-card my-2 "
                                                style={{
                                                  maxHeight: "400px",
                                                  overflowY: "overlay",
                                                }}
                                              >
                                                <ReactJson
                                                  src={
                                                    filteredExtraInfo[0]?.info
                                                  }
                                                />
                                              </motion.div>
                                            )}
                                          </AnimatePresence>
                                        </div>
                                      )}
                                      {vuln?.host_service?.length > 0 && (
                                        <div className="row px-0  ">
                                          {vuln?.host_service?.map(
                                            (item, index) => {
                                              return (
                                                <div
                                                  key={index + "s"}
                                                  className="col-xxl-3 col-xl-4 col-md-6  "
                                                >
                                                  <div className="special-card my-1  ">
                                                    <div className="row mb-1">
                                                      <div className="col-md-12 text-primary font-size-24">
                                                        <i className="mdi mdi-network-outline" />
                                                        Service
                                                      </div>
                                                      <label
                                                        className="col-md-12"
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        <b>First Seen:</b>{" "}
                                                        {item?.first_seen}
                                                      </label>
                                                      <label
                                                        className="col-md-12"
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        <b>Last Seen:</b>{" "}
                                                        {item?.last_seen}
                                                      </label>
                                                      <label
                                                        className="col-md-12"
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        <b>Name:</b>{" "}
                                                        {item?.name || "-"}
                                                      </label>
                                                      <label
                                                        className="col-md-12"
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        <b>Port:</b>{" "}
                                                        {item?.port || "-"}
                                                      </label>
                                                      <label
                                                        className="col-md-12"
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        <b>Protocol:</b>{" "}
                                                        {item?.proto || "-"}
                                                      </label>
                                                      <label
                                                        className="col-md-12"
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        <b>Version:</b>{" "}
                                                        {item?.version || "-"}
                                                      </label>
                                                      <label
                                                        className="col-md-12"
                                                        style={{
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        <b>CPE:</b>{" "}
                                                        {item?.cpe || "-"}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                      {vuln?.host_service?.length < 1 && (
                                        <h5 className="text-center">
                                          There is no service...
                                        </h5>
                                      )}
                                    </div>
                                  </td>
                                );
                              }
                            })}
                          </motion.tr>
                        )}
                    </AnimatePresence>
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
        {data.length < 1 && (
          <span
            style={{ width: "100%" }}
            className="d-flex justify-content-center "
          >
            No data available in table
          </span>
        )}
      </div>
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default NetworkMonitorTable;
