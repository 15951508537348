import React, { useState } from "react";
import { Modal } from "reactstrap";
import { patch } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const MoveGroupAsset = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  groupList,
  rowId,
  getHostsAsset,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [modal_standard5, setmodal_standard5] = useState(false);

  const [addLoading, set_addLoading] = useState(false);
  const [groupID, set_groupID] = useState(groupList[0]?.id);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toastError = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  };

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });

  const moveAssetGroupFunc = async () => {
    paceFunction(true);
    let data = {
      id: groupID,
      assets: rowId,
    };
    if (rowId.length > 0) {
      try {
        let result = await patch("/customer/module/host", data);
        if (result?.success) {
          toastSuccess(result?.message || "Assets Moved");
          getHostsAsset();
          tog_standard5();
          tog_standard();
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else {
      toastError("Please select asset");
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={moveAssetGroupFunc}
        text="Are you sure you want to move assets?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Move Group
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="col-xl-6 col-md-6">
            <div className="form-group mb-3">
              <label>Target Group</label>
              <div className="input-group mb-3">
                <select
                  name="new_ip_target_group"
                  className="form-select"
                  onChange={(e) => set_groupID(e.target.value)}
                >
                  {groupList?.map((item, index) => (
                    <option
                      key={item?.id}
                      className={
                        item?.severity === "CRITICAL"
                          ? "text-danger"
                          : item?.severity === "HIGH"
                          ? "text-warning"
                          : item?.severity === "MEDIUM"
                          ? "text-info"
                          : "text-success"
                      }
                      value={item?.id}
                    >
                      {item?.name} [{item?.severity}]
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            onClick={tog_standard5}
            type="button"
            data-delete-groupbtn="deneme"
            className="btn btn-primary text-truncate"
          >
            <i className="mdi mdi-plus me-1" /> Add
          </button>
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MoveGroupAsset;
