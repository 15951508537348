import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TopSection from "../../../components/Customer/CyberWatch/AttackSurface/TopSection";
import BottomSection from "../../../components/Customer/CyberWatch/AttackSurface/BottomSection";
import { get } from "../../../helpers/api_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Container, Spinner } from "reactstrap";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../common/InfoNoDataComponent";
import { useHistory } from "react-router-dom";

const AttackSurface = () => {
  document.title = "Attack Surface | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState();
  const [empty, setEmpty] = useState(false);
  const [seemInfoComponent, set_seemInfoComponent] = useState(false);
  const [seemInfoComponentGrantAccess, set_seemInfoComponentGrantAccess] =
    useState(false);
  const router = useHistory();

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "error",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  const getAuditsOverview = async (id) => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/overview?id=${id}`);
      if (result?.success) {
        const totalLength = (obj) => {
          let toplam = 0;
          for (let key in obj) {
            if (Array.isArray(obj[key])) {
              toplam += obj[key].length;
            }
          }
          return toplam;
        };
        let total = totalLength(result?.data);
        if (total > 0) {
          setAssets(result?.data);
          setIsLoading(false);
        } else {
          set_seemInfoComponent(true);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  const getModules = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/module");
      if (result.success) {
        let dataArray = result.data.modules;
        const hasWebApp = dataArray.some((item) => item.name === "CYBER_WATCH");
        if (hasWebApp) {
          const filteredData = dataArray.filter(
            (item) => item.name === "CYBER_WATCH"
          );
          if ("permission" in filteredData[0]) {
            if (filteredData[0].permission) {
              for (let i = 0; i < dataArray.length; i++) {
                if (dataArray[i].name === "CYBER_WATCH") {
                  getAuditsOverview(dataArray[i].id);
                }
              }
            } else {
              set_seemInfoComponentGrantAccess(true);
              setIsLoading(false);
            }
          } else {
            for (let i = 0; i < dataArray.length; i++) {
              if (dataArray[i].name === "CYBER_WATCH") {
                getAuditsOverview(dataArray[i].id);
              }
            }
          }
        } else {
          setIsLoading(false);
          setEmpty(true);
          showSweetAlert("You don't have a Attack Surface Management...");
          router.push("/pages-404");
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    setEmpty(false);
    setIsLoading(true);
    getModules();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Attack Surface Management"
            breadcrumbItem="Attack Surface Management"
          />

          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && assets && <TopSection assets={assets} />}
          {!isLoading && assets && <BottomSection assets={assets} />}
          {!isLoading && seemInfoComponent && (
            <InfoNoDataComponent
              text="No attack surface info were discovered"
              component="attack-surface"
            />
          )}
          {!isLoading && seemInfoComponentGrantAccess && (
            <InfoNoDataComponent
              text="Module permission denied. Contact with customer account."
              component="attack-surface"
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AttackSurface;
