import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { del, post, patch } from "../../../helpers/api_helper";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Calnderimg from "../../../../assets/images/undraw-calendar.svg";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
//css
import "@fullcalendar/bootstrap/main.css";

const CalendarComponent = (props) => {
  document.title = "Calendar | PentestBX";

  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const calendarRef = useRef(null);

  const [expire_date, set_expire_date] = useState();

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });

  const toastWarning = (msg) => {
    toast.warning(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  };

  const clearCalendar = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().removeAllEvents();
    }
  };

  const customButtons = {
    clearButton: {
      text: "Clear",
      click: clearCalendar,
    },
  };

  const formattedEvents = props.data.tasks.map((event) => ({
    title: event.module,
    start: event.start,
    end: event.end,
    id: event?.id || "",
  }));

  const changeTime = (e) => {
    const originalDate = new Date(e[0]);
    const isoDateString = originalDate.toISOString();
    set_expire_date(isoDateString);
  };

  const [modal, setModal] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [modal_standard6, setmodal_standard6] = useState(false);

  const [selectedDay, setSelectedDay] = useState(0);

  const [addLoading, set_addLoading] = useState(false);
  const [deleteLoading, set_deleteLoading] = useState(false);

  const [eventID, set_eventID] = useState();

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const tog_standard6 = () => {
    setmodal_standard6(!modal_standard6);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handleDateClick = (arg) => {
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );
    const modifiedData = { ...arg, date: modifiedDate };
    setSelectedDay(modifiedData);
    const dateObject = new Date(modifiedData.date);
    const isoString = dateObject.toISOString();
    set_expire_date(isoString);
    if (!props.data.periodically) {
      toggle();
    } else {
      toastWarning("You must select custom schedule...");
    }
  };

  const handleEventClick = (arg) => {
    const event = arg.event;
    set_eventID(event._def.publicId);
    if (!props.data.periodically) {
      tog_standard6();
    } else {
      toastWarning("You can't delete task in auto schedule...");
    }
  };

  const addNewTask = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      module_id: props.moduleID,
      date: expire_date,
    };
    try {
      let result = await post("/customer/settings/module/task_calendar", data);
      if (result?.success) {
        toastSuccess(result?.message || "Task Added");
        toggle();
        tog_standard5();
        props.getTaskCalendar();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  const deleteTask = async () => {
    paceFunction(true);
    set_deleteLoading(true);
    try {
      let result = await del(
        `/customer/settings/module/task_calendar?id=${eventID}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Task Deleted");
        tog_standard6();
        props.getTaskCalendar();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_deleteLoading(false);
    paceFunction(false);
  };

  const customFunction = async () => {
    paceFunction(true);
    let data = {
      module_id: props.moduleID,
      type: "custom",
    };
    try {
      let result = await patch("/customer/settings/module/task_calendar", data);
      if (result?.success) {
        toastSuccess(result?.message || "Schedule is completed");
        props.getTaskCalendar();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  const autoFunction = async () => {
    paceFunction(true);
    let data = {
      module_id: props.moduleID,
      type: "auto",
    };
    try {
      let result = await patch("/customer/settings/module/task_calendar", data);
      if (result?.success) {
        toastSuccess(result?.message || "Schedule is completed");
        props.getTaskCalendar();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Add Task */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addNewTask}
        text="Are you sure you want to add task?"
        loadingAction={true}
        isLoading={addLoading}
      />
      {/* Delete Task */}
      <ConfirmModal
        modal_standard={modal_standard6}
        tog_standard={tog_standard6}
        setmodal_standard={setmodal_standard6}
        targetFunction={deleteTask}
        text="Are you sure you want to delete task?"
        loadingAction={true}
        isLoading={deleteLoading}
      />
      <Modal isOpen={modal} className={props.className}>
        <ModalHeader toggle={toggle} tag="h4">
          Schedule New Task
        </ModalHeader>
        <ModalBody>
          <div className="col-12">
            <div className="mb-3"></div>
          </div>
          <div className="col-12">
            <div className="form-floating mb-3">
              <Flatpickr
                name="expiration_date"
                className="form-control d-block"
                options={{
                  altInput: true,
                  altFormat: "F j, Y H:i",
                  dateFormat: "F j, Y H:i",
                  enableTime: true,
                  defaultDate: selectedDay.date,
                }}
                onChange={changeTime}
              />
              <label>Trigger Date</label>
            </div>
          </div>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-light me-2"
                  onClick={toggle}
                >
                  Close
                </button>
                <button
                  onClick={tog_standard5}
                  type="button"
                  className="btn btn-success save-event"
                >
                  Add
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Row>
        <Col className="col-12">
          <div className="row">
            <div className="col-lg-3">
              <Card>
                <CardBody>
                  <div className="mb-3 ">
                    <select
                      onChange={(e) => props.set_moduleID(e.target.value)}
                      className="form-control form-select"
                      id="taskModule"
                      defaultValue={props.moduleID}
                    >
                      {props.modules?.length > 0 &&
                        props?.modules?.map((mod, index) => (
                          <option key={mod.id} value={mod.id}>
                            {mod.text}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className=" d-grid gap-3">
                    <ul
                      // style={{ backgroundColor: "#e9e9ef" }}
                      className=" special-card    border list-unstyled mb-0 fw-semibold "
                    >
                      <li>
                        <ul>
                          <li>
                            Scan Period : {props.data?.module?.scan_period}
                          </li>
                          <li>
                            Expire Date : {props.data?.module?.expiration_date}
                          </li>
                          <li>
                            Periodically :
                            {props.data?.periodically ? (
                              <i className="mdi mdi-check-outline me-3 text-success" />
                            ) : (
                              <i className="mdi mdi-close-outline me-3 text-danger" />
                            )}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <div id="external-events" className="mt-2">
                    <br />
                    <p className="text-muted fw-semibold ">
                      Caution: Scans are taken automatically. The calendar shows
                      30 days of planning.
                    </p>
                  </div>
                  <Row className="justify-content-center mt-5">
                    <Col lg={12} sm={6}>
                      <img
                        src={Calnderimg}
                        alt=""
                        className="img-fluid d-block"
                      />
                    </Col>
                  </Row>
                  <div className="row justify-content-center mt-5">
                    <div className="col-lg-12 col-sm-12">
                      <div
                        className="d-flex justify-content-center flex-row "
                        role="group"
                      >
                        {props.data?.periodically ? (
                          <button
                            onClick={customFunction}
                            type="button"
                            className="btn btn-outline-primary w-xs"
                          >
                            <i className="mdi mdi-calendar-multiselect d-block" />
                            Custom Schedule
                          </button>
                        ) : (
                          <button
                            onClick={autoFunction}
                            type="button"
                            className="btn btn-outline-primary w-xs"
                          >
                            <i className="mdi mdi-axis-z-rotate-clockwise d-block" />
                            Auto Schedule
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-9">
              <Card>
                <CardBody>
                  <FullCalendar
                    ref={calendarRef}
                    plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                    slotDuration={"00:15:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "clearButton dayGridMonth,dayGridWeek,dayGridDay",
                    }}
                    events={formattedEvents}
                    editable={false}
                    droppable={false}
                    selectable={false}
                    dateClick={handleDateClick}
                    eventClick={handleEventClick}
                    customButtons={customButtons}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

CalendarComponent.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default CalendarComponent;
