import React, { useState } from "react";
import { CardHeader, CardBody, Container } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { patch } from "../../../helpers/api_helper";
import Flatpickr from "react-flatpickr";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const ManagerDetailsMain = ({ data }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const today = new Date(data?.expiration_date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);

  const [formData, setFormData] = useState({
    id: data.id,
    organization_name: data.organization_name || "",
    phone_number: data.phone_number || "",
    email: data.email || "",
    expiration_date: data.expiration_date || "",
    enable: data.enable || true,
    authentication: data.authentication || "None",
    account_limit: data.account_limit || 0,
    asset_limit_host: data.asset_limit_host || 0,
    asset_limit_webapp: data.asset_limit_webapp || 0,
    asset_limit_mxthreats: data.asset_limit_mxthreats || 0,
    asset_limit_cybersight: data.asset_limit_cybersight || 0,
  });

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const changeTime = (e) => {
    const originalDate = new Date(e[0]);
    const isoDateString = originalDate.toISOString();
    setFormData({
      ...formData,
      expiration_date: isoDateString,
    });
  };

  const handleSubmit = async (e) => {
    paceFunction(true);
    e.preventDefault();
    try {
      let result = await patch("/admin/manager", formData);
      if (result?.success) toastSuccess(result?.message || "Manager Updated");
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className=" special-card">
            <CardHeader
              // style={{ backgroundColor: "#f8f8f8" }}
              className="d-flex justify-content-between   "
            >
              <h4 className="card-title text-primary ">Update Manager</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-3 col-md-3">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="organization_name"
                        className="form-control"
                        onChange={handleChange}
                        defaultValue={formData.organization_name}
                        required
                      />
                      <label>Name</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-3">
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        onChange={handleChange}
                        defaultValue={formData.email}
                        required
                      />
                      <label>Email</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-3">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="phone_number"
                        className="form-control"
                        onChange={handleChange}
                        defaultValue={formData.phone_number}
                        required
                      />
                      <label>Phone Number</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-3">
                    <div className="form-floating mb-3">
                      <Flatpickr
                        name="expiration_date"
                        className="form-control d-block"
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "F j, Y",
                        }}
                        defaultValue={formattedDate}
                        onChange={changeTime}
                      />
                      <label>Expire Date</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        name="asset_limit_host"
                        min={0}
                        max={3000}
                        step={1}
                        defaultValue={formData.asset_limit_host}
                        onChange={handleChange}
                      />
                      <label>Max. Asset Limit (Hosts) (max 3000)</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        name="asset_limit_mxthreats"
                        min={0}
                        max={100}
                        step={1}
                        defaultValue={formData.asset_limit_mxthreats}
                        onChange={handleChange}
                      />
                      <label>Max. Asset Limit (Mailbox) (max 100)</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        name="asset_limit_webapp"
                        min={0}
                        max={100}
                        step={1}
                        defaultValue={formData.asset_limit_webapp}
                        onChange={handleChange}
                      />
                      <label>Max. Asset Limit (Web App) (max 100)</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        name="asset_limit_cybersight"
                        min={0}
                        max={100}
                        step={1}
                        defaultValue={formData.asset_limit_cybersight}
                        onChange={handleChange}
                      />
                      <label>Max. Asset Limit (Cyber) (max 100)</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        name="account_limit"
                        min={0}
                        max={50}
                        step={1}
                        defaultValue={formData.account_limit}
                        onChange={handleChange}
                      />
                      <label>Max. Account Limit (max 50)</label>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex flex-column align-items-center ">
                    <label className="form-label">Authentication</label>
                    <div className="btn-group btn-group-sm  " role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faNone1"
                        defaultValue="None"
                        autoComplete="off"
                        defaultChecked={
                          formData.authentication === "None" ? true : false
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            authentication: e.target.value,
                          })
                        }
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faNone1"
                      >
                        None
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faSms1"
                        defaultValue="SMS"
                        autoComplete="off"
                        defaultChecked={
                          formData.authentication === "SMS" ? true : false
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            authentication: e.target.value,
                          })
                        }
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faSms1"
                      >
                        SMS
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faQr1"
                        defaultValue="QR"
                        autoComplete="off"
                        defaultChecked={
                          formData.authentication === "QR" ? true : false
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            authentication: e.target.value,
                          })
                        }
                        disabled
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faQr1"
                      >
                        QR
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex flex-column align-items-center mt-md-0 mt-3 ">
                    {/* <div className="col-lg-4 d-flex flex-column align-items-center "> */}
                    <label className="form-label  ">Active</label>
                    <div className="square-switch">
                      <input
                        type="checkbox"
                        id="is_active_mng"
                        name="is_active_mng"
                        switch="primary"
                        autoComplete="off"
                        defaultChecked={formData.enable}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            enable: e.target.checked,
                          })
                        }
                      />
                      <label
                        htmlFor="is_active_mng"
                        data-on-label="Yes"
                        data-off-label="No"
                      />
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    id="save_updatebtn"
                    className="btn btn-lg btn-primary"
                  >
                    <i className="mdi mdi-book-edit-outline me-3" />
                    Update Manager
                  </button>
                </div>
              </form>
            </CardBody>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManagerDetailsMain;
