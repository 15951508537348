import React, { useRef, useState } from "react";
import TopSection from "./TopSection";
import BottomSection from "./BottomSection";
import "react-toastify/dist/ReactToastify.css";
import { post } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import HostPdfReport from "./Pdf/HostPdfReport";
import DountChart from "./Pdf/DonutChart";
import { toPng } from "html-to-image";
const AuditsComponent = ({ data, assets, groupList, moduleID }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [arrayChangeValue, setArrayChangeValue] = useState(0);
  const [updated_at, set_updated_at] = useState(
    data?.scan_history[data?.scan_history?.length - 1]?.updated_at
  );
  const [hosts, set_hosts] = useState();
  const [ports, set_ports] = useState();
  const [pdfLoading, set_pdfLoading] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(false);

  const [imageData, setImageData] = useState(null);
  const [visible, setVisible] = useState(true);
  const [pdfData, setPdfData] = useState();
  const [donutData, setDonutData] = useState();
  const [imageUrl, setImageUrl] = useState();
  const buttonRef = useRef(null);
  const chartRef = useRef(null);

  const updatedAtFunc = (date) => {
    set_updated_at(date);
  };

  const arrayChangeFunction = (value) => {
    setArrayChangeValue(value);
  };

  const getAssetPortFunc = async (hostIDS, ports) => {
    set_hosts(hostIDS);
    set_ports(ports);
  };

  const pdfExportFunc = async (type) => {
    paceFunction(true);
    set_pdfLoading(true);
    let hostsArray = hosts?.map((item) => item?.id);
    let portsArray = ports?.map((item) => item?.port);
    let portsFilter = portsArray.filter((item) => item !== 0);
    let data = {
      type,
      date: updated_at,
      id: hostsArray,
      port: portsFilter,
    };
    try {
      let result = await post("/customer/module/host/vulns-export", data);
      if (result?.success) {
        if (type === "xlsx") {
          window.open(result?.data?.report_url, "_blank");
          paceFunction(false);
          set_pdfLoading(false);
        } else {
          setPdfData(result?.data);
          // setDonutData([2, 5]);
          setTimeout(() => {
            // handleConvertHtmlToImage();
          }, 3000);
          setTimeout(() => {
            buttonRef.current.click();
            set_pdfLoading(false);
            paceFunction(false);
          }, 8000);
          setTimeout(() => {
            setPdfData();
            // setDonutData();
          }, 10000);
        }
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
      set_pdfLoading(false);
      paceFunction(false);
    }
  };

  const handleConvertHtmlToImage = () => {
    toPng(chartRef.current, { quality: 2 })
      .then((dataUrl) => {
        setImageUrl(dataUrl);
      })
      .catch((error) => {
        console.error("Error converting HTML to image:", error);
      });
  };
  return (
    <div>
      {/* {pdfData && (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          {imageUrl && 
          <HostPdfReport data={pdfData} imageUrl={imageUrl} />
          }
        </PDFViewer>
      )} */}
      <TopSection
        pdfLoading={pdfLoading}
        pdfExportFunc={pdfExportFunc}
        allData={data}
        data={data.scan_history}
        dateFormat={data.date_format}
        arrayChangeValue={arrayChangeValue}
        arrayChangeFunction={(e) => arrayChangeFunction(e)}
        updatedAtFunc={(e) => updatedAtFunc(e)}
        selectDisabled={selectDisabled}
      />
      <BottomSection
        getAssetPortFunc={(asset, port) => getAssetPortFunc(asset, port)}
        assets={assets?.assets}
        updated_at={updated_at}
        groupList={groupList}
        moduleID={moduleID}
        setSelectDisabled={(e) => setSelectDisabled(e)}
      />
      <div className="">
        <PDFDownloadLink
          document={<HostPdfReport data={pdfData} imageUrl={imageUrl} />}
          fileName={
            pdfData
              ? `${pdfData?.summary?.report_date}-Hosts_Audit_Report.pdf`
              : "Hosts_Audit_Report.pdf"
          }
        >
          {pdfData && <button ref={buttonRef}>.</button>}
        </PDFDownloadLink>
      </div>
      {/* {donutData && (
        <div ref={chartRef}>
          <DountChart data={donutData} />
        </div>
      )} */}
    </div>
  );
};

export default AuditsComponent;
