import React, { useState } from "react";
import { Modal } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { patch } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const UpdatePassword = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [old_password, set_old_password] = useState("");
  const [new_password, set_new_password] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toastError = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const updatePassword = async () => {
    paceFunction(true);
    setAddLoading(true);
    let data = {
      old_password,
      new_password,
    };
    if (new_password.length < 8) {
      toastError(
        "This password is too short. It must contain at least 8 characters."
      );
    } else {
      try {
        let result = await patch("/account/change-password", data);
        if (result.code === 200) {
          toastSuccess(result.message || "Password Updated");
          tog_standard();
          tog_standard5();
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    }
    paceFunction(false);
    setAddLoading(false);
  };

  return (
    <div>
      {/* Update Password */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={updatePassword}
        text="Are you sure you want to update password?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        size="lg"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        {" "}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Update Password
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form
            id="customer_detail_form"
            noValidate
            method="post"
            action="/update_profile/"
          >
            <div className="pb-3">
              <div className="row">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Old Password :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-lock-minus" />
                    </span>
                    <input
                      type="password"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="email"
                      onChange={(e) => set_old_password(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">New Password :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-lock-plus" />
                    </span>
                    <input
                      type="password"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="phone_number"
                      onChange={(e) => set_new_password(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-5" />
            <div className="d-flex justify-content-end ">
              <button
                onClick={tog_standard5}
                type="button"
                id="confirmationTooltip1"
                className="btn btn-primary waves-effect btn-label waves-light  "
              >
                <i className="mdi mdi-check-all me-3 label-icon"></i>Update
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default UpdatePassword;
