import React, { useEffect, useState } from "react";
import ReportCompareTable from "./ReportCompareTable";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";
import { Spinner } from "reactstrap";
import { post } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import { useSelector } from "react-redux";

const animatedComponents = makeAnimated();

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? "" : "s";
    values = `${values.length} (item${plural}) selected`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const ReportCompare = ({
  assetDatas,
  modules,
  setSelectedModule,
  selectedModule,
  dates,
}) => {
  const { layoutMode } = useSelector((state) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [firstDate, setFirstDate] = useState();
  const [secondDate, setSecondDate] = useState();
  const [thirdDate, setThirdDate] = useState();
  const [firstCreatedAt, setFirstCreatedAt] = useState();
  const [secondCreatedAt, setSecondCreatedAt] = useState();
  const [thirdCreatedAt, setThirdCreatedAt] = useState();
  const [selectedAsset, setSelectedAsset] = useState(0);
  const [asset, setAsset] = useState();
  const [moduleID, setModuleID] = useState();

  const [tableData, setTableData] = useState([]);

  const [isLoadingTable, setIsLoadingTable] = useState(false);

  const [entryValue, setEntryValue] = useState(10);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [allGroup, setAllGroup] = useState([]);

  const filteredModules = modules.filter(
    (item) =>
      item.name === "HOST" ||
      item.name === "WEB_APPLICATION" ||
      item.name === "MXTHREAT"
  );

  const handleChange = (selectedOptionss) => {
    if (selectedOptionss?.some((item) => item?.label === "All Select")) {
      let all = selectedOptionss.find((item) => item?.label === "All Select");
      setSelectedOptions(all.value);
    } else if (
      allGroup?.some(
        (group) =>
          group === selectedOptionss[selectedOptionss.length - 1]?.label
      )
    ) {
      let tempSelected = selectedOptions.concat(
        selectedOptionss[selectedOptionss.length - 1]?.value
      );
      const filterUniqueItems = (dataArray) => {
        const uniqueSet = new Set();

        const filteredArray = dataArray?.filter((item) => {
          const key = `${item.label}-${item.value}`;
          if (!uniqueSet.has(key)) {
            uniqueSet.add(key);
            return true;
          }
          return false;
        });

        return filteredArray;
      };
      const filteredArray = filterUniqueItems(tempSelected);
      setSelectedOptions(filteredArray);
    } else {
      setSelectedOptions(selectedOptionss);
    }
  };

  const changeValue = (value) => {
    setEntryValue(value);
  };

  const toastError = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      overFlowY: "overlay",
      backgroundColor: layoutMode === "dark" ? "#313533" : "white",
      color: layoutMode === "dark" ? "white" : "black",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#313533" : "white",
      color: layoutMode === "dark" ? "white" : "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: layoutMode === "dark" ? "white" : "",
    }),
    input: (provided) => ({
      ...provided,
      color: layoutMode === "dark" ? "white" : "",
    }),
  };

  let assets = [];

  useEffect(() => {
    if (selectedModule === "HOST" && assetDatas?.assets?.length > 0) {
      assets = assetDatas?.assets;
      const uniqueGroupNames = new Set(assets?.map((item) => item?.group_name));
      const groupNamesArray = Array.from(uniqueGroupNames);
      setAllGroup(groupNamesArray);
      const groupAssets = {};
      groupNamesArray?.forEach((groupName) => {
        const filteredAssets = assets?.filter(
          (item) => item?.group_name === groupName
        );
        groupAssets[groupName] = filteredAssets?.map((item) => ({
          label: item?.address,
          value: item?.id,
        }));
      });
      const convertObjectToOptions = (dataObject) => {
        const optionsArray = [];
        for (const key in dataObject) {
          if (dataObject.hasOwnProperty(key)) {
            const value = dataObject[key];
            optionsArray.push({
              label: key,
              value: value,
            });
          }
        }
        return optionsArray;
      };
      const outputObject = convertObjectToOptions(groupAssets);
      let allSelect = assets?.map((item) => ({
        label: item?.address,
        value: item?.id,
      }));
      let option = [...new Set(assets?.map((item) => item?.group_name))].map(
        (groupName) => ({
          label: groupName,
          options: assets
            ?.filter((item) => item?.group_name === groupName)
            ?.map((item) => ({
              label: item?.address,
              value: item?.id,
            })),
        })
      );
      option = [
        { label: "All Select", value: allSelect },
        ...outputObject,
        ...option,
      ];
      setOptions(option);
    }
    setFirstDate();
    setSecondDate();
    setThirdDate();
    setFirstCreatedAt();
    setSecondCreatedAt();
    setThirdCreatedAt();
  }, [selectedModule, assetDatas]);

  const handleChangeSelectAsset = (data) => {
    let parseData = JSON.parse(data);
    setAsset(parseData.id);
    setSelectedAsset(parseData.index);
  };

  const handleChangeModules = (data) => {
    let parseData = JSON.parse(data);
    setModuleID(parseData.id);
    setSelectedModule(parseData.name);
    setTableData([]);
  };

  const compareFunc = async () => {
    paceFunction(true);
    if (
      firstDate === secondDate ||
      firstDate === thirdDate ||
      thirdDate === secondDate
    ) {
      toastError(
        "The dates should not be equal to each other,you must choose at least 2 dates."
      );
    } else {
      let dateData = [];
      if (firstDate) dateData.push(firstDate);
      if (secondDate) dateData.push(secondDate);
      if (thirdDate) dateData.push(thirdDate);
      if (dateData.length > 1) {
        setIsLoadingTable(true);
        if (selectedModule === "HOST") {
          let selectedOptionsArray = selectedOptions.map((item) => item?.value);
          let data = {
            date: dateData,
            asset: selectedOptionsArray,
            id: moduleID,
          };
          try {
            let result = await post(`/customer/module/compare/vulns`, data);
            if (result?.success) {
              setTableData(result.data);
            }
          } catch (error) {
            console.log(`error ==>`, error);
            errorToastFunction(error);
          }
        } else {
          let data = {
            id: moduleID,
            asset: [asset],
            date: dateData,
          };
          try {
            let result = await post(`/customer/module/compare/vulns`, data);
            if (result?.success) {
              setTableData(result?.data);
            }
          } catch (error) {
            console.log(`error ==>`, error);
            errorToastFunction(error);
          }
        }
        setIsLoadingTable(false);
      } else {
        toastError("You must choose at least 2 dates.");
      }
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <div className="special-card mb-5 pb-5">
        <div className=" row justify-content-center   ">
          <div className="col-lg-2">
            <div className="mb-3">
              <label className="form-label font-size-13 text-muted">
                Modules
              </label>
              <div className="form-group mb-3">
                <select
                  onChange={(e) => handleChangeModules(e.target.value)}
                  className="form-select"
                  id="select_module"
                >
                  <option value="">-- select an option --</option>
                  {filteredModules?.map((mod, index) => (
                    <option key={index} value={JSON.stringify(mod)}>
                      {mod?.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {(selectedModule === "WEB_APPLICATION" ||
            selectedModule === "MXTHREAT" ||
            selectedModule === "") && (
            <div className="col-lg-2">
              <div className="mb-3">
                <label
                  // for="choices-single-default"
                  className="form-label font-size-13 text-muted"
                >
                  {selectedModule === "WEB_APPLICATION"
                    ? "Web Application Assets"
                    : selectedModule === "MXTHREAT"
                    ? "Mailbox Threat Assets"
                    : "Assets"}
                </label>
                <div className="form-group mb-3">
                  <select
                    onChange={(e) => handleChangeSelectAsset(e.target.value)}
                    className="form-select"
                    id="select_module"
                  >
                    <option value="">-- select an option --</option>
                    {dates?.map((asset, index) => {
                      let newAsset = { ...asset, index };
                      return (
                        <option key={index} value={JSON.stringify(newAsset)}>
                          {asset?.address}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          )}
          {selectedModule === "HOST" && (
            <div className="col-lg-2">
              <div className="mb-3 ">
                <label
                  htmlFor="choices-multiple-default"
                  className="form-label font-size-13 text-muted"
                >
                  Hosts Assets
                </label>
                <Select
                  className="input-dark"
                  isMulti={true}
                  options={options}
                  value={selectedOptions}
                  onChange={handleChange}
                  // classNamePrefix="select2-selection"
                  closeMenuOnSelect={false}
                  components={{ animatedComponents, ValueContainer }}
                  styles={customStyles}
                  // valueRenderer={renderValue}
                  hideSelectedOptions={false}
                />
              </div>
            </div>
          )}
          <div className="col-lg-2">
            <div className="mb-3">
              <label className="form-label font-size-13 text-muted">
                First Report (Date)
              </label>
              <div className="form-group mb-3">
                <select
                  className="form-select"
                  id="select_date_first"
                  onChange={(e) => {
                    if (selectedModule === "HOST") {
                      setFirstDate(JSON.parse(e.target.value).updated_at);
                      setFirstCreatedAt(JSON.parse(e.target.value).created_at);
                      setTableData([]);
                    } else if (selectedModule === "WEB_APPLICATION") {
                      setFirstDate(JSON.parse(e.target.value).date);
                      setFirstCreatedAt(JSON.parse(e.target.value).created_at);
                      setTableData([]);
                    } else {
                      setFirstDate(JSON.parse(e.target.value).truncated_date);
                      setFirstCreatedAt(JSON.parse(e.target.value).created_at);
                      setTableData([]);
                    }
                  }}
                >
                  <option disabled="" value="">
                    -- select an option --
                  </option>
                  {selectedModule === "HOST" &&
                    dates?.map((item, index) => {
                      return (
                        <option key={index} value={JSON.stringify(item)}>
                          {item.created_at}
                        </option>
                      );
                    })}
                  {(selectedModule === "WEB_APPLICATION" ||
                    selectedModule === "MXTHREAT") &&
                    dates[selectedAsset]?.scans?.map((item, index) => {
                      return (
                        <option key={index} value={JSON.stringify(item)}>
                          {item?.created_at}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="mb-3">
              <label className="form-label font-size-13 text-muted">
                Second Report (Date)
              </label>
              <div className="form-group mb-3">
                <select
                  className="form-select"
                  id="select_date_first"
                  onChange={(e) => {
                    if (selectedModule === "HOST") {
                      setSecondDate(JSON.parse(e.target.value).updated_at);
                      setSecondCreatedAt(JSON.parse(e.target.value).created_at);
                      setTableData([]);
                    } else if (selectedModule === "WEB_APPLICATION") {
                      setSecondDate(JSON.parse(e.target.value).date);
                      setSecondCreatedAt(JSON.parse(e.target.value).created_at);
                      setTableData([]);
                    } else {
                      setSecondDate(JSON.parse(e.target.value).truncated_date);
                      setSecondCreatedAt(JSON.parse(e.target.value).created_at);
                      setTableData([]);
                    }
                  }}
                >
                  <option disabled="" value="">
                    -- select an option --
                  </option>
                  {(selectedModule === "WEB_APPLICATION" ||
                    selectedModule === "MXTHREAT") &&
                    dates[selectedAsset]?.scans?.map((item, index) => {
                      return (
                        <option key={index} value={JSON.stringify(item)}>
                          {item?.created_at}
                        </option>
                      );
                    })}
                  {selectedModule === "HOST" &&
                    dates?.map((item, index) => {
                      return (
                        <option key={index} value={JSON.stringify(item)}>
                          {item.created_at}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="mb-3">
              <label className="form-label font-size-13 text-muted">
                Third Report (Date)
              </label>
              <div className="form-group mb-3">
                <select
                  className="form-select"
                  id="select_date_first"
                  onChange={(e) => {
                    if (selectedModule === "HOST") {
                      setThirdDate(JSON.parse(e.target.value).updated_at);
                      setThirdCreatedAt(JSON.parse(e.target.value).created_at);
                      setTableData([]);
                    } else if (selectedModule === "WEB_APPLICATION") {
                      setThirdDate(JSON.parse(e.target.value).date);
                      setThirdCreatedAt(JSON.parse(e.target.value).created_at);
                      setTableData([]);
                    } else {
                      setThirdDate(JSON.parse(e.target.value).truncated_date);
                      setThirdCreatedAt(JSON.parse(e.target.value).created_at);
                      setTableData([]);
                    }
                  }}
                >
                  <option disabled="" value="">
                    -- select an option --
                  </option>
                  {(selectedModule === "WEB_APPLICATION" ||
                    selectedModule === "MXTHREAT") &&
                    dates[selectedAsset]?.scans?.map((item, index) => {
                      return (
                        <option key={index} value={JSON.stringify(item)}>
                          {item?.created_at}
                        </option>
                      );
                    })}
                  {selectedModule === "HOST" &&
                    dates?.map((item, index) => {
                      return (
                        <option key={index} value={JSON.stringify(item)}>
                          {item.created_at}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="mb-3">
              <label className="form-label font-size-13 text-muted">
                &nbsp;
              </label>
              <div>
                <button
                  onClick={compareFunc}
                  id="comparebtn"
                  type="button"
                  className="btn btn-primary waves-effect btn-label"
                  style={{ width: "100%" }}
                >
                  <i className="mdi mdi-file-compare label-icon"></i> Compare
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoadingTable && (
        <div className="d-flex justify-content-center  align-items-center h-100  ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            className="mt-2 "
            color="primary"
          />
        </div>
      )}
      {!isLoadingTable && (
        <div className=" special-card ">
          <ReportCompareTable
            firstDate={firstDate}
            secondDate={secondDate}
            thirdDate={thirdDate}
            firstCreatedAt={firstCreatedAt}
            secondCreatedAt={secondCreatedAt}
            thirdCreatedAt={thirdCreatedAt}
            data={tableData}
            entryValue={entryValue}
            changeValue={(e) => changeValue(e)}
            selectedModule={selectedModule}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ReportCompare;
