import React, { useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import LogsTable from "./LogsTable";

const Logs = ({ logs }) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [entryValue, setEntryValue] = useState(10);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  return (
    <div className="special-card">
      <Row>
        <LogsTable
          data={logs}
          entryValue={entryValue}
          changeValue={(e) => changeValue(e)}
        />
      </Row>
    </div>
  );
};

export default Logs;
