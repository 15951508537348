import React, { useState } from "react";
import { del, get, post, put, patch } from "../../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const HostsTickets = ({
  moduleID,
  // tickets
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [updateLoading, setUpdateLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const [modal_standard3, setmodal_standard3] = useState(false);
  const [modal_standard4, setmodal_standard4] = useState(false);
  // Tickets
  const [stock, set_stock] = useState();
  const [renewal, set_renewal] = useState();
  const [handled, set_handled] = useState();

  const toastError = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  };

  const tog_standard4 = () => {
    setmodal_standard4(!modal_standard4);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateTicket = async () => {
    paceFunction(true);
    setUpdateLoading(true);
    let ticketData = {
      id: moduleID,
      stock,
      renewal,
      handled,
    };
    try {
      let result = await patch("/admin/customer/module/tickets", ticketData);
      if (result?.success) {
        toastSuccess(result?.message || "Ticket Updated");
        tog_standard3();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setUpdateLoading(false);
    paceFunction(false);
  };

  const removeTicket = async () => {
    paceFunction(true);
    setRemoveLoading(true);
    try {
      let result = await del(`/admin/customer/module/tickets?id=${moduleID}`);
      if (result?.success) {
        toastSuccess(result?.message || "Ticket Removed");
        tog_standard4();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setRemoveLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Update Ticket */}
      <ConfirmModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        targetFunction={updateTicket}
        text="Are you sure you want to update ticket?"
        loadingAction={true}
        isLoading={updateLoading}
      />
      {/* Remove Ticket */}
      <ConfirmModal
        modal_standard={modal_standard4}
        tog_standard={tog_standard4}
        setmodal_standard={setmodal_standard4}
        targetFunction={removeTicket}
        text="Are you sure you want to remove ticket?"
        loadingAction={true}
        isLoading={removeLoading}
      />
      <div className="row mt-2 ">
        <div className="col-xl-4 col-md-4">
          <div className="form-floating mb-3">
            <input
              className="form-control"
              type="number"
              name="scanperiod"
              min={1}
              step={1}
              defaultValue={stock}
              autoComplete="off"
              onChange={(e) => set_stock(e.target.value)}
            />
            <label>Number of Ticket</label>
          </div>
        </div>
        <div className="col-xl-4 col-md-4">
          <div className="form-floating mb-3">
            <input
              className="form-control"
              name="numberof_asset"
              type="number"
              min={1}
              step={1}
              defaultValue={handled}
              autoComplete="off"
              onChange={(e) => set_handled(e.target.value)}
            />
            <label>Handled</label>
          </div>
        </div>
        <div className="col-xl-4 col-md-4">
          <div className="form-floating mb-3">
            <input
              className="form-control"
              name="numberof_asset"
              type="number"
              min={1}
              step={1}
              defaultValue={renewal}
              autoComplete="off"
              onChange={(e) => set_renewal(e.target.value)}
            />
            <label>Renewal</label>
          </div>
        </div>
      </div>
      <div className="col border-end d-flex justify-content-center  align-items-end">
        <div className="d-flex flex-wrap  gap-2">
          <button
            className="btn btn-outline-primary"
            id="updateWebAppBtn"
            type="button"
            onClick={tog_standard3}
          >
            <i className="bx bx-check-double label-icon" /> Update
          </button>
          <button
            className="btn btn-outline-danger removeModule"
            data-mod="WEB_APPLICATION"
            type="button"
            onClick={() => {
              tog_standard4();
            }}
          >
            <i className="bx bx-block label-icon" /> Remove
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HostsTickets;
