import React, { useState, useEffect } from "react";
import CvesDetailComponent from "../../../../../components/Customer/CyberWatch/ThreatIntelligence/Cves/CvesDetailComponent";
import { Container, Spinner } from "reactstrap";
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../../../common/InfoNoDataComponent";
import { get } from "../../../../../helpers/api_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const CvesDetail = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [data, set_data] = useState();
  const [tags, set_tags] = useState([]);
  const [seemInfoComponentGrantAccess, set_seemInfoComponentGrantAccess] =
    useState(false);

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "error",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  const getCveDetail = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/cyberwatch/ti/cves?id=${id}`);
      if (result?.success) {
        set_data(result?.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cves"
            );
          }
        }
      }
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getCveDetail();
  }, []);

  const getTags = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/cyberwatch/ti/cves/tags`);
      if (result?.success) {
        set_tags(result?.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`Threat Intelligence / ${id} Detail`}
            breadcrumbItem={`Threat Intelligence / ${id} Detail`}
          />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && !seemInfoComponentGrantAccess && data && tags && (
            <CvesDetailComponent
              data={data}
              tags={tags}
              getCveDetail={getCveDetail}
            />
          )}
          {!isLoading && seemInfoComponentGrantAccess && (
            <InfoNoDataComponent
              text="Module permission denied. Contact with customer account."
              component="cves"
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CvesDetail;
