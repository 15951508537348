import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { patch } from "../../../../helpers/api_helper";
import { useDispatch } from "react-redux";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";

const UpdateGrantAccess = ({
  accountID,
  updateModalData,
  modal_standard,
  tog_standard,
  setmodal_standard,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const dispatch = useDispatch();

  const [addLoading, setAddLoading] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);

  const [access, set_access] = useState(updateModalData?.permissions?.access);
  const [task_start, set_task_start] = useState(
    updateModalData?.permissions?.task_start
  );
  const [task_update, set_task_update] = useState(
    updateModalData?.permissions?.task_update
  );
  const [asset_update, set_asset_update] = useState(
    updateModalData?.permissions?.asset_update
  );

  useEffect(() => {
    set_access(updateModalData?.permissions?.access);
    set_task_start(updateModalData?.permissions?.task_start);
    set_task_update(updateModalData?.permissions?.task_update);
    set_asset_update(updateModalData?.permissions?.asset_update);
  }, [updateModalData]);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toastError = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const updateGrantAccess = async () => {
    paceFunction(true);
    setAddLoading(true);
    let data = {
      id: accountID,
      module_id: updateModalData.id,
      access,
      task_start,
      task_update,
      asset_update,
    };
    if (task_start || task_update || asset_update) {
      if (access) {
        try {
          let result = await patch("/customer/settings/linked-account", data);
          if (result?.success) {
            tog_standard();
            tog_standard5();
            toastSuccess(result?.message || "Grant Access Updated");
            dispatch({ type: "RENDER_COMPONENT_REQ" });
          }
        } catch (error) {
          console.log(`error ==>`, error);
          errorToastFunction(error);
        }
      } else {
        toastError("You must enable access first");
      }
    } else {
      try {
        let result = await patch("/customer/settings/linked-account", data);
        if (result?.success) {
          tog_standard();
          tog_standard5();
          toastSuccess(result?.message || "Grant Access Updated");
          dispatch({ type: "RENDER_COMPONENT_REQ" });
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    }
    paceFunction(false);
    setAddLoading(false);
  };

  return (
    <div>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={updateGrantAccess}
        text={`Are you sure you want to update ${updateModalData?.text}?`}
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        {" "}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Update {updateModalData?.text}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form
            id="customer_detail_form"
            noValidate
            method="post"
            action="/update_profile/"
          >
            <div className="pb-3">
              <div className="row">
                <div className="col-3">
                  <div className="d-flex justify-content-center me-2  align-items-center">
                    <h5 className="font-size-15">Access</h5>
                  </div>
                  <div className="square-switch d-flex justify-content-center align-items-center">
                    <input
                      type="checkbox"
                      id="access"
                      name="access"
                      switch="primary"
                      defaultChecked={updateModalData?.permissions?.access}
                      onChange={(e) => set_access(e.target.checked)}
                    />
                    <label
                      htmlFor="access"
                      data-on-label="On"
                      data-off-label="Off"
                    />
                  </div>{" "}
                </div>
                <div className="col-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <h5 className="font-size-15">Task Start</h5>
                  </div>
                  <div className="square-switch d-flex justify-content-center align-items-center">
                    <input
                      type="checkbox"
                      id="task_start"
                      name="task_start"
                      switch="primary"
                      defaultChecked={updateModalData?.permissions?.task_start}
                      onChange={(e) => set_task_start(e.target.checked)}
                    />
                    <label
                      htmlFor="task_start"
                      data-on-label="On"
                      data-off-label="Off"
                    />
                  </div>{" "}
                </div>
                <div className="col-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <h5 className="font-size-15">Task Update</h5>
                  </div>
                  <div className="square-switch d-flex justify-content-center align-items-center">
                    <input
                      type="checkbox"
                      id="task_update"
                      name="task_update"
                      switch="primary"
                      defaultChecked={updateModalData?.permissions?.task_update}
                      onChange={(e) => set_task_update(e.target.checked)}
                    />
                    <label
                      htmlFor="task_update"
                      data-on-label="On"
                      data-off-label="Off"
                    />
                  </div>{" "}
                </div>
                <div className="col-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <h5 className="font-size-15">Asset Update</h5>
                  </div>
                  <div className="square-switch d-flex justify-content-center align-items-center">
                    <input
                      type="checkbox"
                      id="asset_update"
                      name="asset_update"
                      switch="primary"
                      defaultChecked={
                        updateModalData?.permissions?.asset_update
                      }
                      onChange={(e) => set_asset_update(e.target.checked)}
                    />
                    <label
                      htmlFor="asset_update"
                      data-on-label="On"
                      data-off-label="Off"
                    />
                  </div>{" "}
                </div>
              </div>
            </div>
            <hr className="" />
            <div className="d-flex justify-content-end ">
              <button
                onClick={tog_standard5}
                type="button"
                id="confirmationTooltip1"
                className="btn btn-primary waves-effect btn-label waves-light  "
              >
                <i className="mdi mdi-check-all me-3 label-icon"></i>Save
                Changes
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateGrantAccess;
