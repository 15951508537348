import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { patch } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "reactstrap";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

function EndpointInfo({
  modal_standard,
  tog_standard,
  setmodal_standard,
  data,
}) {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const { id } = useParams();

  const [formData, setFormData] = useState({
    lan_netmask: data?.lan_netmask,
    id: id,
    endpoint_id: data?.id,
    lan_ip: data?.lan_ip,
    lan_gw: data?.lan_gw,
    lan_reserv_ip: data?.lan_reserv_ip,
    ep_username: data?.ep_username,
    ep_password: data?.ep_password,
    uuid: data?.uuid || "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  useEffect(() => {
    setFormData({
      lan_netmask: data?.lan_netmask,
      id: id,
      endpoint_id: data?.id,
      lan_ip: data?.lan_ip,
      lan_gw: data?.lan_gw,
      lan_reserv_ip: data?.lan_reserv_ip,
      ep_username: data?.ep_username,
      ep_password: data?.ep_password,
      uuid: data?.uuid || "",
    });
  }, [data]);

  const handleSubmit = async () => {
    paceFunction(true);
    try {
      let result = await patch("/manager/customer/endpoint", formData);
      if (result?.success) toastSuccess("Endpoint Updated");
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  return (
    <div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0 fs-4 " id="myModalLabel">
            Endpoint Setup
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="d-flex justify-content-center ">
          <div className="row my-4 w-75 ">
            <span className="badge badge-soft-primary py-2 ">
              Port - Proto : {data?.vpn_service}
            </span>
            <span className="badge badge-soft-primary py-2 ">
              VPN Iface : {data?.vpn_iface}
            </span>
            <span className="badge badge-soft-primary py-2 ">
              VPN Name : {data?.vpn_server_name}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center flex-column  align-items-center ">
          <div className=" mb-4 w-75 ">
            <div className="form-floating m-0 ">
              <input
                type="text"
                name="lan_ip"
                className="form-control"
                defaultValue={formData?.lan_ip}
                onChange={handleInputChange}
              />
              <label>Lan Ip Address</label>
            </div>
          </div>
          <div className=" mb-4 w-75 ">
            <div className="form-floating m-0 ">
              <input
                type="text"
                name="uuid"
                className="form-control"
                defaultValue={formData?.uuid}
                onChange={handleInputChange}
              />
              <label>Uuid</label>
            </div>
          </div>
          <div className=" mb-4 w-75 ">
            <div className="form-floating m-0 ">
              <input
                type="text"
                name="lan_gw"
                className="form-control"
                defaultValue={formData?.lan_gw}
                onChange={handleInputChange}
              />
              <label>Lan Gateway</label>
            </div>
          </div>
          <div className=" mb-4 w-75 ">
            <div className="form-floating m-0 ">
              <input
                type="text"
                name="lan_reserv_ip"
                className="form-control"
                defaultValue={formData?.lan_reserv_ip}
                onChange={handleInputChange}
              />
              <label>Reserved Ip Address</label>
            </div>
          </div>
          <div className=" mb-4 w-75 ">
            <div className="form-floating m-0 ">
              <input
                type="text"
                name="lan_netmask"
                className="form-control"
                defaultValue={formData?.lan_netmask}
                onChange={handleInputChange}
              />
              <label>Lan Netmask</label>
            </div>
          </div>
          <div className=" mb-4 w-75 ">
            <div className="form-floating m-0 ">
              <input
                type="text"
                name="ep_username"
                className="form-control"
                defaultValue={formData?.ep_username}
                onChange={handleInputChange}
              />
              <label>Username</label>
            </div>
          </div>
          <div className=" mb-4 w-75 ">
            <div className="form-floating m-0 ">
              <input
                type="text"
                name="ep_password"
                className="form-control"
                defaultValue={formData?.ep_password}
                onChange={handleInputChange}
              />
              <label>Password</label>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center my-4 ">
          <button
            type="button"
            className="btn btn-outline-primary  w-50"
            onClick={handleSubmit}
          >
            <i className="bx bx-check-double label-icon me-1 "></i>
            Update
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default React.memo(EndpointInfo);
