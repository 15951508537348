import React from "react";
const AccountAssetLimits = ({ customerLimits }) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-6 ">
          <div className="special-card mt-5 h-100  ">
            <h2 className="text-center">Limits</h2>
            <div className="row">
              <div className="mb-2 font-size-15 col-6">
                <div className="mb-1 d-flex flex-row align-items-center ">
                  <strong className="me-1  ">Total Account Limit:</strong>
                  <span className="badge bg-info ">
                    {customerLimits?.account_limit}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <strong className="me-1">Remaining Account Limit:</strong>
                  <span
                    className={`badge ${
                      customerLimits?.account_limit -
                        customerLimits?.customers?.length >
                      0
                        ? "bg-success"
                        : "bg-danger"
                    }`}
                  >
                    {customerLimits?.account_limit -
                      customerLimits?.customers?.length}
                  </span>
                </div>
              </div>
              <div className="mb-2 font-size-15 col-6">
                <div className="mb-1 d-flex flex-row align-items-center">
                  <strong className="me-1">Total Cyber Watch Limit:</strong>
                  <span className="badge bg-info ">
                    {customerLimits?.asset_limit_cybersight}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <strong className="me-1">Remaining Cyber Watch Limit:</strong>
                  <span
                    className={`badge ${
                      customerLimits?.asset_limit_cybersight -
                        customerLimits?.using_assets?.CYBER_WATCH >
                      0
                        ? "bg-success"
                        : "bg-danger"
                    }`}
                  >
                    {customerLimits?.asset_limit_cybersight -
                      customerLimits?.using_assets?.CYBER_WATCH}
                  </span>
                </div>
              </div>
              <div className="mb-2 font-size-15 col-6">
                <div className="mb-1 d-flex flex-row align-items-center">
                  <strong className="me-1">Total Host Limit:</strong>
                  <span className="badge bg-info ">
                    {customerLimits?.asset_limit_host}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <strong className="me-1">Remaining Host Limit:</strong>
                  <span
                    className={`badge ${
                      customerLimits?.asset_limit_host -
                        customerLimits?.using_assets?.HOST >
                      0
                        ? "bg-success"
                        : "bg-danger"
                    }`}
                  >
                    {customerLimits?.asset_limit_host -
                      customerLimits?.using_assets?.HOST}
                  </span>
                </div>
              </div>
              <div className="mb-2 font-size-15 col-6">
                <div className="mb-1 d-flex flex-row align-items-center">
                  <strong className="me-1">Total Mailbox Threats Limit:</strong>
                  <span className="badge bg-info">
                    {customerLimits?.asset_limit_mxthreats}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <strong className="me-1">
                    Remaining Mailbox Threats Limit:
                  </strong>
                  <span
                    className={`badge ${
                      customerLimits?.asset_limit_mxthreats -
                        customerLimits?.using_assets?.MXTHREAT >
                      0
                        ? "bg-success"
                        : "bg-danger"
                    }`}
                  >
                    {customerLimits?.asset_limit_mxthreats -
                      customerLimits?.using_assets?.MXTHREAT}
                  </span>
                </div>
              </div>
              <div className="mb-2 font-size-15 col-6">
                <div className="mb-1 d-flex flex-row align-items-center">
                  <strong className="me-1">Total Web Application Limit:</strong>
                  <span className="badge bg-info ">
                    {customerLimits?.asset_limit_webapp}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <strong className="me-1">
                    Remaining Web Application Limit:
                  </strong>
                  <span
                    className={`badge ${
                      customerLimits?.asset_limit_webapp -
                        customerLimits?.using_assets?.WEB_APPLICATION >
                      0
                        ? "bg-success"
                        : "bg-danger"
                    }`}
                  >
                    {customerLimits?.asset_limit_webapp -
                      customerLimits?.using_assets?.WEB_APPLICATION}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 ">
          <div className="special-card mt-5 h-100  ">
            <h2 className="text-center">Customers</h2>
            {customerLimits?.customers?.length > 0 &&
              customerLimits?.customers?.map((item) => (
                <div key={item?.id} className="special-card2 mb-3 ">
                  <div className="d-flex justify-content-between ">
                    <h5 className="text-primary">{item?.email}</h5>
                    <h5 className="text-primary">{item?.company_name}</h5>
                    <h5 className="text-primary">{item?.phone_number}</h5>
                  </div>
                  {item?.modules?.length > 0 &&
                    item?.modules?.map((mod) => (
                      <div
                        key={mod?.id}
                        className="d-flex flex-row align-items-center font-size-14 fw-semibold  "
                      >
                        <span className="me-2">- </span>
                        <strong>
                          {mod?.name === "HOST"
                            ? "Host"
                            : mod?.name === "CYBER_WATCH"
                            ? "Cyber Watch"
                            : mod?.name === "MXTHREAT"
                            ? "Mailbox Threat"
                            : mod?.name === "WEB_APPLICATION"
                            ? "Web Application"
                            : "Network Monitor"}
                          :
                        </strong>
                        <span
                          className={`badge mx-1  ${
                            mod?.asset_count > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          {mod?.asset_count}
                        </span>
                        Asset
                        {mod?.asset_count > 1 ? "s" : ""}
                      </div>
                    ))}
                </div>
              ))}
            {customerLimits?.customers?.length < 1 && (
              <h4 className="text-center mt-5 ">There is no customer</h4>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccountAssetLimits;
