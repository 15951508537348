import React, { useState } from "react";
import { Modal } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { put } from "../../../helpers/api_helper";
import { useDispatch } from "react-redux";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";

const ModalAddModule = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
  missingModules,
  userId,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const dispatch = useDispatch();

  const [name, setModule] = useState();
  const [addLoading, setAddLoading] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const addModule = async () => {
    paceFunction(true);
    setAddLoading(true);
    let data = {
      name,
      id: userId,
    };
    try {
      let result = await put("/manager/customer/module", data);
      if (result?.success) toastSuccess("Module Added");
      dispatch({ type: "RENDER_COMPONENT_REQ" });
      tog_standard();
      tog_standard5();
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  return (
    <div>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addModule}
        text="Are you sure you want to add module?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Module
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-is_credentials"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="type">Module:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <select
                className="form-control"
                name="type"
                style={{ border: "1px solid #3fa0da" }}
                id="type"
                onChange={(e) => {
                  setModule(e.target.value);
                }}
              >
                <option value=""></option>
                {missingModules?.map((item, i) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <label id="type-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-primary "
          >
            Add Record
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddModule;
