import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const errorFunctionComponent = () => {
  const router = useHistory();
  const MySwal = withReactContent(Swal);

  const toastError = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "error",
      timer: 24 * 60 * 3000, // 3000 milisaniye (3 saniye)
      timerProgressBar: false, // İlerleme çubuğunu göster
      showConfirmButton: false,
    });
  };

  const errorToastFunction = (error) => {
    let errorResponse;
    if (error?.response?.data?.data) {
      if (Array.isArray(error?.response?.data?.data)) {
        if (error?.response?.data?.data?.length > 0) {
          errorResponse = `${error?.response?.data?.message || ""} => ${
            error?.response?.data?.data[0]
          }`;
        } else {
          errorResponse = `${
            error?.response?.data?.message || "Something went wrong"
          }`;
        }
      } else {
        if (Object.keys(error?.response?.data?.data).length > 0) {
          const keys = Object.keys(error?.response?.data?.data);
          const firstKey = keys[0];
          if (Array.isArray(error?.response?.data?.data[firstKey])) {
            if (keys.length > 0) {
              errorResponse = `${
                error?.response?.data?.message || ""
              } => ${firstKey} => ${error?.response?.data?.data[firstKey][0]}`;
            }
          } else {
            if (keys.length > 0) {
              const keysTwo = Object.keys(
                error?.response?.data?.data[firstKey]
              );
              const firstKeyTwo = keysTwo[0];
              if (
                Array.isArray(
                  error?.response?.data?.data[firstKey][firstKeyTwo]
                )
              ) {
                errorResponse = `${
                  error?.response?.data?.message || ""
                } => ${firstKey} => ${
                  error?.response?.data?.data[firstKey][firstKeyTwo][0]
                }`;
              } else {
                errorResponse = `${
                  error?.response?.data?.message || ""
                } => ${firstKey} => ${
                  error?.response?.data?.data[firstKey][firstKeyTwo]
                }`;
              }
            }
          }
        } else {
          errorResponse = error?.response?.data?.message;
        }
      }
    } else if (error?.response?.data?.message) {
      errorResponse = error?.response?.data?.message;
    }
    toastError(errorResponse || "Something went wrong");

    if (error?.response?.status === 404) {
      router.push("/pages-404");
    } else if (error?.response?.status === 500) {
      router.push("/pages-500");
    } else if (error?.response?.status === 504) {
      router.push("/pages-504");
    } else if (error?.response?.status === 0) {
      router.push("/maintenance");
    }
  };

  const errorFunction = (error) => {
    if (error?.response?.status === 404) {
      router.push("/pages-404");
    } else if (error?.response?.status === 500) {
      router.push("/pages-500");
    } else if (error?.response?.status === 504) {
      router.push("/pages-504");
    } else if (error?.response?.status === 0) {
      router.push("/maintenance");
    } else {
      if (error?.response?.status !== 401) {
        showSweetAlert(
          error?.response?.data?.message || "Something went wrong"
        );
      }
    }
  };

  const errorFunctionNotSwal = (error) => {
    if (error?.response?.status === 404) {
      router.push("/pages-404");
    } else if (error?.response?.status === 500) {
      router.push("/pages-500");
    } else if (error?.response?.status === 504) {
      router.push("/pages-504");
    } else if (error?.response?.status === 0) {
      router.push("/maintenance");
    } else {
    }
  };
  return { errorFunction, errorFunctionNotSwal, errorToastFunction };
};

export default errorFunctionComponent;
