import React, { useState } from "react";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { patch } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";

const Notification = ({ notifications }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [critical_vulns_sms, set_critical_vulns_sms] = useState(
    notifications?.critical_vulns_sms
  );
  // const [critical_vulns_mobile, set_critical_vulns_mobile] = useState(
  //   notifications?.critical_vulns_mobile
  // );
  const [critical_vulns_email, set_critical_vulns_email] = useState(
    notifications?.critical_vulns_email
  );
  const [weak_credentials_sms, set_weak_credentials_sms] = useState(
    notifications?.weak_credentials_sms
  );
  // const [weak_credentials_mobile, set_weak_credentials_mobile] = useState(
  //   notifications?.weak_credentials_mobile
  // );
  const [weak_credentials_email, set_weak_credentials_email] = useState(
    notifications?.weak_credentials_email
  );
  const [breached_accounts_sms, set_breached_accounts_sms] = useState(
    notifications?.breached_accounts_sms
  );
  // const [breached_accounts_mobile, set_breached_accounts_mobile] = useState(
  //   notifications?.breached_accounts_mobile
  // );
  const [breached_accounts_email, set_breached_accounts_email] = useState(
    notifications?.breached_accounts_email
  );
  const [scan_completed_sms, set_scan_completed_sms] = useState(
    notifications?.scan_completed_sms
  );
  // const [scan_completed_mobile, set_scan_completed_mobile] = useState(
  //   notifications?.scan_completed_mobile
  // );
  const [scan_completed_email, set_scan_completed_email] = useState(
    notifications?.scan_completed_email
  );

  const [popoverOpen, setPopoverOpen] = useState(false);
  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const updateNotifications = async () => {
    paceFunction(true);
    let data = {
      critical_vulns_email,
      // critical_vulns_mobile,
      critical_vulns_sms,
      scan_completed_email,
      // scan_completed_mobile,
      scan_completed_sms,
      weak_credentials_email,
      // weak_credentials_mobile,
      weak_credentials_sms,
      breached_accounts_email,
      // breached_accounts_mobile,
      breached_accounts_sms,
    };
    try {
      let result = await patch(
        "/customer/settings/account/notification-config",
        data
      );
      if (result?.success) {
        togglePopover();
        toastSuccess(result?.message || "Notifications Updated");
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <Popover
        placement="top"
        isOpen={popoverOpen}
        target="confirmationTooltipNotification"
        id="confirmationNotification"
        toggle={togglePopover}
      >
        <PopoverHeader>Are you sure ?</PopoverHeader>
        <PopoverBody>
          <Button onClick={updateNotifications} color="success">
            Yes
          </Button>
          <Button onClick={togglePopover} color="danger">
            No
          </Button>
        </PopoverBody>{" "}
      </Popover>
      <div className="special-card mt-5 ">
        <div className="py-5 px-3">
          <div className="row">
            <div className="col-xl-3">
              <div>
                <h5 className="font-size-15">Notification Settings :</h5>
              </div>
            </div>
            <div className="col-xl">
              <div className="table-responsive">
                <table className="table table-bordered border-primary mb-0">
                  <thead>
                    <tr>
                      <th />
                      <th>E-Mail</th>
                      <th>SMS</th>
                      {/* <th>Mobile Application</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Critical Vulnerabilities</th>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="critical_vulns_email"
                            name="critical_vulns_email"
                            switch="primary"
                            defaultChecked={critical_vulns_email}
                            onChange={(e) =>
                              set_critical_vulns_email(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="critical_vulns_email"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="critical_vulns_sms"
                            name="critical_vulns_sms"
                            switch="primary"
                            defaultChecked={critical_vulns_sms}
                            onChange={(e) =>
                              set_critical_vulns_sms(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="critical_vulns_sms"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      {/* <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="critical_vulns_mobile"
                            name="critical_vulns_mobile"
                            switch="primary"
                            defaultChecked={critical_vulns_mobile}
                            onChange={(e) =>
                              set_critical_vulns_mobile(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="critical_vulns_mobile"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td> */}
                    </tr>
                    <tr>
                      <th scope="row">Completed Scans</th>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="scan_completed_email"
                            name="scan_completed_email"
                            switch="primary"
                            defaultChecked={scan_completed_email}
                            onChange={(e) =>
                              set_scan_completed_email(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="scan_completed_email"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="scan_completed_sms"
                            name="scan_completed_sms"
                            switch="primary"
                            defaultChecked={scan_completed_sms}
                            onChange={(e) =>
                              set_scan_completed_sms(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="scan_completed_sms"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      {/* <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="scan_completed_mobile"
                            name="scan_completed_mobile"
                            switch="primary"
                            defaultChecked={scan_completed_mobile}
                            onChange={(e) =>
                              set_scan_completed_mobile(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="scan_completed_mobile"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td> */}
                    </tr>
                    <tr>
                      <th scope="row">Found Weak Credentials</th>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="weak_credentials_email"
                            name="weak_credentials_email"
                            switch="primary"
                            defaultChecked={weak_credentials_email}
                            onChange={(e) =>
                              set_weak_credentials_email(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="weak_credentials_email"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="weak_credentials_sms"
                            name="weak_credentials_sms"
                            switch="primary"
                            defaultChecked={weak_credentials_sms}
                            onChange={(e) =>
                              set_weak_credentials_sms(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="weak_credentials_sms"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      {/* <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="weak_credentials_mobile"
                            name="weak_credentials_mobile"
                            switch="primary"
                            defaultChecked={weak_credentials_mobile}
                            onChange={(e) =>
                              set_weak_credentials_mobile(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="weak_credentials_mobile"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td> */}
                    </tr>
                    <tr>
                      <th scope="row">Found Breached Accounts</th>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="breached_accounts_email"
                            name="breached_accounts_email"
                            switch="primary"
                            defaultChecked={breached_accounts_email}
                            onChange={(e) =>
                              set_breached_accounts_email(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="breached_accounts_email"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="breached_accounts_sms"
                            name="breached_accounts_sms"
                            switch="primary"
                            defaultChecked={breached_accounts_sms}
                            onChange={(e) =>
                              set_breached_accounts_sms(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="breached_accounts_sms"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      {/* <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="breached_accounts_mobile"
                            name="breached_accounts_mobile"
                            switch="primary"
                            defaultChecked={breached_accounts_mobile}
                            onChange={(e) =>
                              set_breached_accounts_mobile(e.target.checked)
                            }
                          />
                          <label
                            htmlFor="breached_accounts_mobile"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td> */}
                    </tr>
                    {/* <tr>
                      <th scope="row">Found Discovered Shares</th>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="discovered_shares_email"
                            name="discovered_shares_email"
                            switch="primary"
                            // defaultChecked={breached_accounts_email}
                            // onChange={(e) =>
                            //   set_breached_accounts_email(e.target.checked)
                            // }
                          />
                          <label
                            htmlFor="discovered_shares_email"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="discovered_shares_sms"
                            name="discovered_shares_sms"
                            switch="primary"
                            // defaultChecked={breached_accounts_sms}
                            // onChange={(e) =>
                            //   set_breached_accounts_sms(e.target.checked)
                            // }
                          />
                          <label
                            htmlFor="discovered_shares_sms"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="discovered_shares_mobile"
                            name="discovered_shares_mobile"
                            switch="primary"
                            // defaultChecked={breached_accounts_mobile}
                            // onChange={(e) =>
                            //   set_breached_accounts_mobile(e.target.checked)
                            // }
                          />
                          <label
                            htmlFor="discovered_shares_mobile"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Found Discovered Services</th>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="discovered_services_email"
                            name="discovered_services_email"
                            switch="primary"
                            // defaultChecked={breached_accounts_email}
                            // onChange={(e) =>
                            //   set_breached_accounts_email(e.target.checked)
                            // }
                          />
                          <label
                            htmlFor="discovered_services_email"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="discovered_services_sms"
                            name="discovered_services_sms"
                            switch="primary"
                            // defaultChecked={breached_accounts_sms}
                            // onChange={(e) =>
                            //   set_breached_accounts_sms(e.target.checked)
                            // }
                          />
                          <label
                            htmlFor="discovered_services_sms"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="discovered_services_mobile"
                            name="discovered_services_mobile"
                            switch="primary"
                            // defaultChecked={breached_accounts_mobile}
                            // onChange={(e) =>
                            //   set_breached_accounts_mobile(e.target.checked)
                            // }
                          />
                          <label
                            htmlFor="discovered_services_mobile"
                            data-on-label="On"
                            data-off-label="Off"
                          />
                        </div>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <hr className="" />

        <div className="d-flex justify-content-end ">
          <button
            onClick={togglePopover}
            type="button"
            id="confirmationTooltipNotification"
            className="btn btn-primary waves-effect btn-label waves-light  "
          >
            <i className="mdi mdi-check-all me-3 label-icon"></i>Save Changes
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Notification;
