import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownHelp from "./DropdownHelp";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <form autoComplete="off">
        <input
          id="search"
          name="search"
          type="text"
          className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
          defaultValue={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
            setCurrentPage(1);
          }}
          placeholder="Search"
        />
      </form>
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function Table({ data, entryValue }) {
  const [rowId, setRowId] = useState();
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const columns = React.useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "company_name",
      },
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone_number",
      },
      {
        Header: "Activation",
        accessor: "is_active",
      },
      {
        Header: "Demo",
        accessor: "is_demo",
      },
      {
        Header: "Actions",
        accessor: "dropdownColumn",
      },
    ],
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }

  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };
  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };
  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const convertJSONToCSV = (data) => {
    const csvHeader = "Username;Company Name;Email;Phone;Activation;Demo";

    const csvRows = data.map((item) => {
      let activation;
      let demo;

      activation = item?.is_active ? "YES" : "NO";
      demo = item?.is_demo ? "YES" : "NO";
      return `${item?.username || "-"};${item?.company_name || "-"};${
        item?.email || "-"
      };${item?.phone_number || "-"};${activation || "-"};${demo || "-"}`;
    });

    const csvContent = [csvHeader, ...csvRows].join("\n");

    return csvContent;
  };

  const downloadCSV = (data) => {
    // JSON verisini CSV'ye dönüştür
    const csvData = convertJSONToCSV(data);
    // CSV dosyasını indirme işlemi
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const fileName = "Customers.csv";

    if (window.navigator.msSaveBlob) {
      // IE 10+ için
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // Diğer tarayıcılar için
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
  };

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-column "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col md={3}>
          <div className="dt-buttons flex-wrap justify-content-md-center  d-flex ">
            <button
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                downloadCSV(data);
              }}
              title="Download CSV"
            >
              <span className="avatar-title bg-transparent d-flex flex-column ">
                <i className="mdi mdi-microsoft-excel  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
          </div>
        </Col>
        <Col xxl={1} xl={2} lg={3} md={4} sm={5} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column.id}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
            prepareRow(row);
            return (
              <tr key={index} {...row.getRowProps()}>
                <td
                  onClick={() => {
                    var textField = document.createElement("textarea");
                    textField.innerText = row.original.username;
                    document.body.appendChild(textField);
                    textField.select();
                    document.execCommand("copy");
                    textField.remove();
                    toastSuccess(`${row.original.username} copied`);
                  }}
                >
                  <span
                    href="#"
                    className="badge rounded-pill badge-soft-primary cursor-pointer me-1 "
                    title={row.original.username}
                  >
                    i
                  </span>
                  {row.cells[0].value}
                </td>
                <td>{row.cells[1].value}</td>
                <td>{row.cells[2].value}</td>
                <td>
                  {row.cells[3].value ? (
                    <i className="mdi mdi-check-outline me-3 text-success" />
                  ) : (
                    <i className="mdi mdi-close-outline me-3 text-danger" />
                  )}
                </td>
                <td>
                  {row.cells[4].value ? (
                    <i className="mdi mdi-check-outline me-3 text-success" />
                  ) : (
                    <i className="mdi mdi-close-outline me-3 text-danger" />
                  )}
                </td>
                <td onClick={() => setRowId(row.original.id)}>
                  <DropdownHelp
                    rowId={rowId}
                    row={rows.slice(firstPostIndex, lastPostIndex)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Table;
