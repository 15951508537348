import React, { useEffect, useState } from "react";
import { Modal, CardHeader, Card, CardBody } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MailboxThreatsModuleTable from "./MailboxThreatsModuleTable";
import { del, get, post, put, patch } from "../../../../helpers/api_helper";
import { useDispatch } from "react-redux";
import Flatpickr from "react-flatpickr";
import MailboxThreatsModuleTickets from "./MailboxThreatsModuleTickets";
import { motion, AnimatePresence } from "framer-motion";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const MailboxThreatsModule = ({ datas }) => {
  const dispatch = useDispatch();

  const { errorFunction, errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [updateLoading, setUpdateLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [addTaskLoading, setAddTaskLoading] = useState(false);
  const [addTicketLoading, setAddTicketLoading] = useState(false);

  const [data, setData] = useState();
  const [renderValue, setRenderValue] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [entryValue, setEntryValue] = React.useState(10);
  const [viewTickets, setViewTickets] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);
  const [modal_standard4, setmodal_standard4] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [modal_standard7, setmodal_standard7] = useState(false);
  const [modal_standard8, setmodal_standard8] = useState(false);
  const [modal_standard9, setmodal_standard9] = useState(false);
  // Assets
  const [asset_id, set_asset_id] = useState();
  const [address, set_address] = useState("");
  const [port, set_port] = useState("143");
  const [username, set_username] = useState("");
  const [password, set_password] = useState("");
  const [protocol, set_protocol] = useState("IMAP");
  const [ssl, set_ssl] = useState(true);
  // Update
  const [is_active, set_is_active] = useState(datas?.is_enable);
  const [scan_period, set_scan_period] = useState(datas?.scan_period);
  const [asset_count, set_asset_count] = useState(datas?.asset_count);
  const [expire_date, set_expire_date] = useState(datas?.expiration_date);
  // Add Tasks
  const [task_type, set_task_type] = useState("all");

  const today = new Date(datas?.expiration_date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);

  const changeTime = (e) => {
    const originalDate = new Date(e[0]);
    const isoDateString = originalDate.toISOString();
    set_expire_date(isoDateString);
  };

  const toastError = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const getAssets = async () => {
    paceFunction(true);
    try {
      let result = await get(
        `/admin/customer/module/asset?id=${datas.user_id}&module_id=${datas.id}`
      );
      if (result?.success) {
        if (result?.data[0]?.assets?.length > 0) setButtonDisable(false);

        setData(result?.data[0]?.assets);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    getAssets();
  }, [renderValue]);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  };

  const tog_standard4 = () => {
    setmodal_standard4(!modal_standard4);
    removeBodyCss();
  };
  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const tog_standard7 = () => {
    setmodal_standard7(!modal_standard7);
    removeBodyCss();
  };
  const tog_standard8 = () => {
    setmodal_standard8(!modal_standard8);
    removeBodyCss();
  };
  const tog_standard9 = () => {
    setmodal_standard9(!modal_standard9);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const removeModule = async () => {
    paceFunction(true);
    setRemoveLoading(true);
    try {
      let result = await del(
        `/admin/customer/module?id=${datas.user_id}&module_id=${datas.id}`
      );
      if (result.success) {
        toastSuccess("Module Removed");
        dispatch({ type: "RENDER_COMPONENT_REQ" });
        tog_standard4();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setRemoveLoading(false);
    paceFunction(false);
  };

  const addRecord = async () => {
    paceFunction(true);
    setAddLoading(true);
    let data = {
      address,
      port,
      username,
      password,
      protocol,
      id: datas.id,
      is_ssl: ssl,
    };
    if (password.length > 3) {
      try {
        let result = await put("/admin/customer/module/asset", data);
        if (result.success) {
          toastSuccess("Asset Added");
          setRenderValue(!renderValue);
          tog_standard();
          tog_standard5();
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else toastError("Ensure password has at least 4 characters.");
    setAddLoading(false);
    paceFunction(false);
  };

  const updateWebApp = async () => {
    paceFunction(true);
    setUpdateLoading(true);
    const date = new Date(expire_date);
    const timestamp = date.getTime();
    let data = {
      scan_period,
      asset_count,
      expiration_date: expire_date,
      is_enable: is_active,
      user_id: datas.user_id,
      id: datas.id,
    };
    if (scan_period > 1) {
      try {
        let response = await patch("/admin/customer/module", data);
        if (response?.success) {
          toastSuccess(response?.message || "Module Updated");
          tog_standard3();
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else {
      toastError("Scan Period must be greater than or equal to 2");
    }
    setUpdateLoading(false);
    paceFunction(false);
  };

  const addTask = async () => {
    paceFunction(true);
    setAddTaskLoading(true);
    let taskData;
    if (task_type === "all") {
      taskData = {
        id: datas.user_id,
        module_id: datas.id,
        type: task_type,
      };
      try {
        let result = await put("/admin/customer/task", taskData);
        if (result.success) toastSuccess(result.message || "Task Added");
        tog_standard7();
        tog_standard8();
        set_asset_id();
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else {
      if (asset_id !== undefined) {
        taskData = {
          id: datas.user_id,
          module_id: datas.id,
          type: task_type,
          asset: [Number(asset_id)],
        };
        try {
          let result = await put("/admin/customer/task", taskData);
          if (result.success) toastSuccess(result.message || "Task Added");
          tog_standard7();
          tog_standard8();
          set_asset_id();
        } catch (error) {
          console.log(`error ==>`, error);
          errorToastFunction(error);
        }
      } else {
        toastError("You must enter ASSET ID");
      }
    }
    setAddTaskLoading(false);
    paceFunction(false);
  };

  const addTicket = async () => {
    paceFunction(true);
    setAddTicketLoading(true);
    let ticketData = {
      id: datas.user_id,
      name: datas.name,
    };
    try {
      let result = await put("/admin/customer/module", ticketData);
      if (result.success) {
        toastSuccess(result.message || "Ticket Added");
        tog_standard9();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddTicketLoading(false);
    paceFunction(false);
  };

  return (
    <div className="col-xxl-12 h-100 ">
      {/* Add Record */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addRecord}
        text="Are you sure you want to add record?"
        loadingAction={true}
        isLoading={addLoading}
      />
      {/* Add Ticket */}
      <ConfirmModal
        modal_standard={modal_standard9}
        tog_standard={tog_standard9}
        setmodal_standard={setmodal_standard9}
        targetFunction={addTicket}
        text="Are you sure you want to add ticket?"
        loadingAction={true}
        isLoading={addTicketLoading}
      />
      {/* Update Module */}
      <ConfirmModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        targetFunction={updateWebApp}
        text="Are you sure you want to update module?"
        loadingAction={true}
        isLoading={updateLoading}
      />
      {/* Remove Module */}
      <ConfirmModal
        modal_standard={modal_standard4}
        tog_standard={tog_standard4}
        setmodal_standard={setmodal_standard4}
        targetFunction={removeModule}
        text="Are you sure you want to remove module?"
        loadingAction={true}
        isLoading={removeLoading}
      />
      {/* Add Task */}
      <ConfirmModal
        modal_standard={modal_standard8}
        tog_standard={tog_standard8}
        setmodal_standard={setmodal_standard8}
        targetFunction={addTask}
        text="Are you sure you want to add task?"
        loadingAction={true}
        isLoading={addTaskLoading}
      />
      {/* Add Record */}
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Record
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-is_credentials"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="protocol">Protocol:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <select
                className="form-control"
                name="protocol"
                style={{ border: "1px solid #3fa0da" }}
                id="protocol"
                defaultValue="IMAP"
                onChange={(e) => set_protocol(e.target.value)}
              >
                <option value="IMAP">IMAP</option>
                <option value="POP3">POP3</option>
              </select>
              <label id="is_credentials-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="address">Mailbox Url:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control font-size-13"
                type="text"
                pattern=".*"
                name="address"
                id="address"
                placeholder="Mailbox Url imap.google.com (without schema)"
                style={{ overflow: "hidden" }}
                onChange={(e) => set_address(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="port">Port:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="number"
                pattern=".*"
                name="port"
                id="port"
                min={1}
                max={65535}
                defaultValue={143}
                style={{ overflow: "hidden" }}
                onChange={(e) => set_port(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="username">Username/E-mail:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="text"
                pattern=".*"
                name="username"
                id="username"
                placeholder="Username/E-mail"
                style={{ overflow: "hidden" }}
                onChange={(e) => set_username(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="password">Password:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="text"
                pattern=".*"
                name="password"
                id="password"
                placeholder="Password"
                style={{ overflow: "hidden" }}
                onChange={(e) => set_password(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="ssl">SSL:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <div className="square-switch">
                <input
                  type="checkbox"
                  id="ssl"
                  name="ssl"
                  switch="primary"
                  defaultChecked={ssl}
                  autoComplete="off"
                  onChange={(e) => set_ssl(e.target.checked)}
                />
                <label htmlFor="ssl" data-on-label="Yes" data-off-label="No" />
              </div>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-primary "
          >
            Add Record
          </button>
        </div>
      </Modal>
      <Card className="border-primary special-card">
        <CardHeader className="d-flex justify-content-between   ">
          <h5 className="my-0 text-primary">
            <i className="mdi mdi-email-alert-outline me-3"></i>
            {datas.text}
          </h5>
          <div className="d-flex ">
            <label className="form-label me-1 ">Active</label>
            <div className="square-switch">
              <input
                type="checkbox"
                id="is_active_mbx"
                name="is_active_mbx"
                switch="primary"
                defaultChecked={is_active}
                autoComplete="off"
                onChange={(e) => set_is_active(e.target.checked)}
              />
              <label
                htmlFor="is_active_mbx"
                data-on-label="Yes"
                data-off-label="No"
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="dt-buttons btn-group flex-wrap">
            <button
              className="btn btn-primary"
              aria-controls="webapps_table"
              type="button"
              onClick={() => {
                tog_standard();
              }}
            >
              <span>Add</span>
            </button>
          </div>
          {data && (
            <div
              style={{
                overflowX: "scroll",
              }}
            >
              <MailboxThreatsModuleTable
                data={data}
                entryValue={entryValue}
                changeValue={(e) => changeValue(e)}
                renderValue={renderValue}
                setRenderValue={(e) => setRenderValue(e)}
                userID={datas.user_id}
                moduleID={datas.id}
              />
            </div>
          )}
          <div className="row mt-2 ">
            <div className="col-xl-4 col-md-4">
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="number"
                  name="scanperiod"
                  min={1}
                  step={1}
                  defaultValue={scan_period}
                  autoComplete="off"
                  onChange={(e) => set_scan_period(e.target.value)}
                />
                <label>Scan Period</label>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  name="numberof_asset"
                  type="number"
                  min={1}
                  step={1}
                  defaultValue={asset_count}
                  autoComplete="off"
                  onChange={(e) => set_asset_count(e.target.value)}
                />
                <label>Number Of Url Address</label>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="form-floating mb-3">
                <Flatpickr
                  name="expiration_date"
                  className="form-control d-block"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "F j, Y",
                  }}
                  defaultValue={formattedDate}
                  onChange={changeTime}
                />
                <label>Expire Date</label>
              </div>
            </div>
          </div>
          <div className="col border-end d-flex justify-content-between align-items-end">
            {" "}
            <button
              onClick={() => {
                setViewTickets(!viewTickets);
              }}
              className="btn btn-outline-info "
            >
              <i className="mdi mdi-view-grid label-icon  me-1 " />
              {viewTickets ? "Close Tickets" : "View Tickets"}
            </button>
            <div className="d-flex flex-wrap justify-content-center  gap-2">
              <button
                className="btn btn-outline-primary"
                id="updateWebAppBtn"
                type="button"
                onClick={tog_standard3}
              >
                <i className="bx bx-check-double label-icon" /> Update
              </button>
              <button
                className="btn btn-outline-danger removeModule"
                data-mod="WEB_APPLICATION"
                type="button"
                onClick={() => {
                  tog_standard4();
                }}
              >
                <i className="bx bx-block label-icon" /> Remove
              </button>
            </div>
            <button
              onClick={() => {
                tog_standard7();
              }}
              className="btn btn-outline-success "
            >
              <i className="mdi mdi-view-grid label-icon  me-1 " />
              Add Task
            </button>
            <Modal
              isOpen={modal_standard7}
              toggle={() => {
                tog_standard7();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Add Task
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard7(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {" "}
                <div
                  style={{ marginLeft: "initial", marginRight: "initial" }}
                  className="form-group row"
                  id="alteditor-row-is_credentials"
                >
                  <div
                    className="col-sm-3 col-md-3 col-lg-3 text-right"
                    style={{ paddingTop: "4px" }}
                  >
                    <label htmlFor="web_task_type">Type:</label>
                  </div>
                  <div className="col-sm-8 col-md-8 col-lg-8">
                    <select
                      className="form-control"
                      name="web_task_type"
                      style={{ border: "1px solid #3fa0da" }}
                      id="web_task_type"
                      onChange={(e) => set_task_type(e.target.value)}
                    >
                      <option value="all">ALL</option>
                      <option value="custom">CUSTOM</option>
                    </select>
                    <label id="web_task_type" className="errorLabel" />
                  </div>
                  <div style={{ clear: "both" }} />
                </div>
                <div
                  style={{ marginLeft: "initial", marginRight: "initial" }}
                  className="form-group row"
                  id="alteditor-row-url"
                >
                  <div
                    className="col-sm-3 col-md-3 col-lg-3 text-right"
                    style={{ paddingTop: "4px" }}
                  >
                    <label htmlFor="web_task_asset_id">Asset ID:</label>
                  </div>
                  <div className="col-sm-8 col-md-8 col-lg-8">
                    <input
                      disabled={task_type === "all" ? true : false}
                      className="form-control"
                      type="text"
                      pattern=".*"
                      name="web_task_asset_id"
                      id="web_task_asset_id"
                      placeholder="ASSET ID"
                      style={{ overflow: "hidden" }}
                      onChange={(e) => set_asset_id(e.target.value)}
                    />
                    <label id="web_task_asset_id" className="errorLabel" />
                  </div>
                  <div style={{ clear: "both" }} />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_standard7();
                  }}
                  className="btn btn-secondary "
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    tog_standard8();
                  }}
                  type="button"
                  className="btn btn-primary "
                >
                  Add Task
                </button>
              </div>
            </Modal>
          </div>
          <AnimatePresence>
            {viewTickets && (
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 0, opacity: 0 }}
                transition={{ duration: 0.4 }}
                className="mt-4"
              >
                <MailboxThreatsModuleTickets
                  moduleID={datas.id}
                  // tickets={datas.ticket}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </CardBody>
      </Card>
    </div>
  );
};

export default MailboxThreatsModule;
