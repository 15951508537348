import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { del, post } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";

const DropdownHelp = ({ rowId }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState();

  const [removeLoading, setRemoveLoading] = useState(false);

  const [modal_center, setmodal_center] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const router = useHistory();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && buttonRef.current) {
        buttonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  };

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      // text: "This is a SweetAlert2 dialog in a React app.",
      icon: "success",
      timer: 2000, // 3000 milisaniye (3 saniye)
      timerProgressBar: true, // İlerleme çubuğunu göster
      showConfirmButton: false,
    });
  };

  const deleteFunc = async () => {
    paceFunction(true);
    setRemoveLoading(true);
    try {
      let result = await del(`/manager/customer?id=${rowId}`);
      if (result?.success) {
        toastSuccess(result?.message || "Customer removed");
        tog_standard3();
        toggleDropdown();
        dispatch({ type: "RENDER_COMPONENT_REQ" });
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setRemoveLoading(false);
    setIsOpen(false);
    paceFunction(false);
  };

  const sendImpersonate = async () => {
    paceFunction(true);
    let data = {
      id: rowId,
      password: password,
    };
    try {
      let result = await post(`/manager/customer/impersonate`, data);
      if (result?.success) {
        const expireTimeInMilliseconds = 23 * 60 * 60 * 1000;
        const value = result;
        const expireTime = new Date().getTime() + expireTimeInMilliseconds;
        const dataToStore = {
          value,
          expireTime,
        };
        localStorage.setItem("authUser", JSON.stringify(dataToStore));
        tog_center();
        showSweetAlert(result?.message || "Impersonate Success");
        setTimeout(() => {
          router.push("/dashboard");
        }, 2000);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Delete Customer */}
      <ConfirmModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        targetFunction={deleteFunc}
        text="Are you sure you want to delete customer?"
        loadingAction={true}
        isLoading={removeLoading}
      />
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <div className="border border-bottom border-red px-1 py-2 ">
          <h5 className="modal-title mt-0 d-flex  justify-content-center fs-4 ">
            Please verify the authorized password
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <input
            className="customer-input py-2 "
            name="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="border border-top border-red px-1 py-4 d-flex  justify-content-center ">
          <button
            ref={buttonRef}
            onClick={sendImpersonate}
            className="btn btn-primary me-2 px-4 py-2  "
          >
            Verify
          </button>
          <button
            type="button"
            onClick={() => {
              tog_center();
            }}
            className="btn btn-secondary px-4 py-2"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <div className={`dropdownn ${isOpen ? "open" : ""}`} ref={dropdownRef}>
        <div className="selected-optionn py-0 " onClick={toggleDropdown}>
          <i className="bx bx-dots-horizontal-rounded text-primary fs-5 " />
          <i className="mdi mdi-chevron-down text-primary fs-6 " />
        </div>
        <ul className="optionss">
          <li
            className="fs-6"
            onClick={() => {
              setIsOpen(false);
              router.push(`/manager/customer-details/${rowId}`);
            }}
          >
            <i className="mdi mdi-account-details-outline label-icon text-info me-1 " />
            Details
          </li>
          <li
            onClick={() => {
              tog_center();
            }}
            className="fs-6"
          >
            <i className="mdi mdi-account-switch-outline label-icon text-primary me-1 " />
            Impersonate
            <div></div>
          </li>
          <li className="fs-6" onClick={tog_standard3}>
            <i className="mdi mdi-account-remove-outline label-icon text-danger me-1 " />
            Delete
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default DropdownHelp;
