import React from "react";
import { Modal } from "reactstrap";

const MailboxThreatsSuggestions = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Here are some precautions you can take to keep your email box safe
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-2">
            <h5>Antivirus and Antimalware Filters:</h5>
            <ul>
              <li className="fw-medium ">
                Use a strong antivirus and antimalware solution on your email
                server. This can scan attachments in incoming emails to detect
                malicious content.
              </li>
            </ul>
          </div>
          <div className="mb-2">
            <h5>SPF, DKIM and DMARC Settings:</h5>
            <ul>
              <li className="fw-medium ">
                Strengthen authentication of incoming emails by using email
                authentication mechanisms such as SPF (Sender Policy Framework),
                DKIM (DomainKeys Identified Mail) and DMARC (Domain-based
                Message Authentication, Reporting, and Conformance). This can
                protect against fraudulent email and malicious campaigns.
              </li>
            </ul>
          </div>
          <div className="mb-2">
            <h5>File Type Filters:</h5>
            <ul>
              <li className="fw-medium ">
                Enable filters on your email server that block certain file
                types. Blocking file types that are particularly risky or
                commonly contain malware can improve security.
              </li>
            </ul>
          </div>
          <div className="mb-2">
            <h5>Trainable Filtering Systems:</h5>
            <ul>
              <li className="fw-medium ">
                You can opt for filters that have the ability to analyze email
                traffic and learn using machine learning or artificial
                intelligence-based filtering systems. These systems can better
                adapt to changing threats over time.
              </li>
            </ul>
          </div>
          <div className="mb-2">
            <h5>Up-to-date Signatures and Definitions:</h5>
            <ul>
              <li className="fw-medium ">
                Regularly update the signature databases of your antivirus and
                antimalware solutions. This is important to protect against the
                latest threats.
              </li>
            </ul>
          </div>
          <div className="mb-2">
            <h5>Harmful Link and URL Filters:</h5>
            <ul>
              <li className="fw-medium ">
                Use filters to check links and URLs in emails. Add a filtering
                mechanism that tries to detect potentially harmful or malicious
                links.
              </li>
            </ul>
          </div>
          <div className="mb-2">
            <h5>Out-of-Content Analysis:</h5>
            <ul>
              <li className="fw-medium ">
                Use solutions that can analyze email content as well as
                attachments.
              </li>
            </ul>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MailboxThreatsSuggestions;
