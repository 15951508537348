import React, { useState } from "react";
import SplineArea from "./Graphics/SplineArea";
import LineGraphic from "./Graphics/LineGraphic";
import DonutChart from "./Graphics/DonutChart";
import ColumnWithDataCharts1 from "./Graphics/ColumnWithDataCharts1";
import ColumnWithDataCharts2 from "./Graphics/ColumnWithDataCharts2";
import ColumnWithDataCharts3 from "./Graphics/ColumnWithDataCharts3";
import {
  CardBody,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useHistory } from "react-router-dom";

const TopSection = ({
  allData,
  data,
  arrayChangeValue,
  arrayChangeFunction,
  updatedAtFunc,
  pdfExportFunc,
  pdfLoading,
  selectDisabled,
  dateFormat,
}) => {
  const router = useHistory();

  const [btnDanger1, setBtnDanger1] = useState(false);

  const selectFunc = (data) => {
    let itemData = JSON.parse(data);
    arrayChangeFunction(itemData.index);
    updatedAtFunc(itemData.updated_at);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 mb-xl-0 mb-3  ">
          <div className="special-card">
            <CardHeader>
              <h4 className="card-title mb-0">Vulnerability Trends </h4>
            </CardHeader>
            <CardBody>
              <SplineArea
                data={data
                  .slice()
                  .sort(
                    (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
                  )}
                dateFormat={dateFormat}
              />
            </CardBody>
          </div>
        </div>
        <div className="col-xl-6 ">
          <div className="special-card">
            <CardHeader>
              <h4 className="card-title mb-0">Summary of Vulnerability </h4>
            </CardHeader>
            <CardBody>
              <div className="row align-items-center ">
                <div className="col-xl-5">
                  <DonutChart
                    arrayChangeValue={arrayChangeValue}
                    data={data?.sort(
                      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                    )}
                  />
                </div>
                <div className="col-sm align-self-center">
                  <div className="mt-4 mt-sm-0">
                    <div>
                      <div className="form-group ">
                        <div
                          className=" mb-3 d-flex  justify-content-between "
                          role="group"
                          aria-label="Button group with nested dropdown"
                        >
                          <button
                            type="button"
                            onClick={() => router.push("/report-compare")}
                            className="btn btn-primary w-sm font-size-12"
                          >
                            <i className="mdi mdi-file-compare d-block font-size-20" />
                            Compare Report
                          </button>
                          <Dropdown
                            isOpen={btnDanger1}
                            toggle={() => setBtnDanger1(!btnDanger1)}
                          >
                            <button
                              onClick={() => setBtnDanger1(!btnDanger1)}
                              type="button"
                              className="btn btn-danger w-sm font-size-12"
                              id="generate_pdf_btn"
                              disabled={selectDisabled}
                            >
                              {pdfLoading ? (
                                <i
                                  className="bx bx-loader bx-spin d-block font-size-20"
                                  id="gpbicon"
                                />
                              ) : (
                                <i className="mdi mdi-chevron-down d-block font-size-20" />
                              )}
                              Export
                            </button>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => pdfExportFunc("pdf")}
                              >
                                PDF Export
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => pdfExportFunc("xlsx")}
                              >
                                XLSX Export
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label>History</label>
                        <select
                          disabled={selectDisabled}
                          onChange={(e) => selectFunc(e.target.value)}
                          className="form-select"
                          id="scan_date"
                        >
                          {data
                            ?.sort(
                              (a, b) =>
                                new Date(b.updated_at) - new Date(a.updated_at)
                            )
                            .map((item, index) => {
                              let newItem = { ...item, index };
                              const date = new Date(item.updated_at);
                              const formattedDate = `${date.getDate()}/${
                                date.getMonth() + 1
                              }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                              return (
                                <option
                                  key={index}
                                  value={JSON.stringify(newItem)}
                                >
                                  {item.created_at}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <label>Score</label>
                      <div
                        style={{ height: "125px" }}
                        className={`bg-hexagon-${
                          data[arrayChangeValue]?.score <= 0.8
                            ? "a"
                            : data[arrayChangeValue]?.score <= 1.5
                            ? "b"
                            : data[arrayChangeValue]?.score <= 2.4
                            ? "c"
                            : data[arrayChangeValue]?.score <= 3.5
                            ? "d"
                            : data[arrayChangeValue]?.score <= 4
                            ? "e"
                            : "g"
                        }   pt-3 align-items-center `}
                      >
                        <div className="row align-items-center justify-content-center mt-1   ">
                          <div className="hexagon-small align-items-center   justify-content-center d-flex ">
                            <span>
                              {data[arrayChangeValue]?.score <= 0.8
                                ? "A"
                                : data[arrayChangeValue]?.score <= 1.5
                                ? "B"
                                : data[arrayChangeValue]?.score <= 2.4
                                ? "C"
                                : data[arrayChangeValue]?.score <= 3.5
                                ? "D"
                                : data[arrayChangeValue]?.score <= 4
                                ? "E"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <LineGraphic
                        data={data
                          .slice()
                          .sort(
                            (a, b) =>
                              new Date(a.updated_at) - new Date(b.updated_at)
                          )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </div>
        </div>
      </div>
      <div className="row my-5 ">
        <div className="col-lg-4 mb-lg-0 mb-4 ">
          <ColumnWithDataCharts1 data={allData?.common_os} />
        </div>
        <div className="col-lg-4 mb-lg-0 mb-4">
          <ColumnWithDataCharts2 data={allData?.common_services} />
        </div>
        <div className="col-lg-4 mb-lg-0 ">
          <ColumnWithDataCharts3 data={allData?.common_vulnbyservices} />
        </div>
      </div>
    </>
  );
};

export default TopSection;
