import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  // OnePage Styles
  onePage: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // padding: 10, // pdfLogo
    padding: 30,
    paddingRight: 60,
    paddingLeft: 60,
  },
  sectionOnePage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  onePageTitle: {
    fontSize: 28,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
  },
  tableOnePage: {
    display: "flex",
    width: "100%",
    // borderStyle: "solid",
    // borderColor: "#000",
    // borderWidth: 1,
    marginBottom: 25,
  },
  row: {
    flexDirection: "row",
  },
  cellOnePage: {
    padding: 5,
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    flexGrow: 1,
    fontSize: 12,
  },
  labelCellOnePage: {
    padding: 5,
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    backgroundColor: "#eee",
    width: 100,
    fontSize: 12,
  },

  // Others Pages

  page: {
    paddingTop: 40,
    paddingBottom: 30,
    paddingRight: 30,
    paddingLeft: 30,
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  pageNumber: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 15,
  },
  pdfLogo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "20px",
    marginBottom: 10,
  },
  background: {
    position: "relative",
    width: "120px",
    height: "30px",
    top: "-5px",
    left: "-25px",
  },
  h1: {
    fontSize: 18,
    fontFamily: "Helvetica-Bold",
    color: "#8f1860",
    borderBottomWidth: 4,
    borderBottomColor: "gray",
    borderBottomStyle: "solid",
    paddingBottom: 10,
    marginBottom: 10,
  },
  h2: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    color: "#1334ed",
  },
  h3: {
    fontSize: 14,
    fontFamily: "Helvetica-Bold",
    color: "#000",
    marginBottom: 10,
    marginTop: 10,
  },
  bgCritical: {
    backgroundColor: "#FD625E",
  },
  bgHigh: {
    backgroundColor: "#FD8C00",
  },
  bgMedium: {
    backgroundColor: "#FDC500",
  },
  bgLow: {
    backgroundColor: "#2AB57D",
  },
  bgInfo: {
    backgroundColor: "#4bA6EF",
  },
  bgTotal: {
    backgroundColor: "#000",
  },
  textCritical: {
    color: "#FD625E",
    fontFamily: "Helvetica-Bold",
  },
  textHigh: {
    color: "#FD8C00",
    fontFamily: "Helvetica-Bold",
  },
  textMedium: {
    color: "#FDC500",
    fontFamily: "Helvetica-Bold",
  },
  textLow: {
    color: "#2AB57D",
    fontFamily: "Helvetica-Bold",
  },
  textInfo: {
    color: "#4bA6EF",
    fontFamily: "Helvetica-Bold",
  },
  textBold: {
    color: "black",
    fontFamily: "Helvetica-Bold",
  },
  textTotal: {
    color: "black",
    fontFamily: "Helvetica-Bold",
  },
  p: {
    marginBottom: 10,
  },
  strong: {
    color: "black",
    fontFamily: "Helvetica-Bold",
  },
  ul: {
    marginLeft: 20,
  },
  li: {
    marginBottom: 5,
  },
  pre: {
    // fontFamily: 'Courier',
    border: 1,
    borderColor: "black",
    padding: 5,
    marginBottom: 10,
  },
  bullet: {
    width: 5,
    height: 5,
    borderRadius: 50,
    backgroundColor: "black",
    marginRight: 5,
  },
  table: {
    width: "100%",
    marginTop: 10,
    border: "1px solid gray",
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  tableTitleCellOne: {
    flexGrow: 1,
    flexBasis: "30%",
    borderRightWidth: 1,
    borderRightColor: "gray",
    borderRightStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: "gray",
    borderTopStyle: "solid",
    padding: "5px 2px",
    height: "100%",
  },
  tableTitleCellTwo: {
    flexGrow: 1,
    flexBasis: "50%",
    height: "100%",
    borderRightWidth: 1,
    borderRightColor: "gray",
    borderRightStyle: "solid",
    padding: "5px 2px",
    borderTopWidth: 1,
    borderTopColor: "gray",
    borderTopStyle: "solid",
  },
  tableTitleCellThree: {
    height: "100%",
    flexGrow: 1,
    flexBasis: "20%",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: "gray",
    borderTopStyle: "solid",
  },
  tableCellLeft: {
    flexGrow: 1,
    flexBasis: "20%",
    backgroundColor: "#edeef0",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    fontFamily: "Helvetica-Bold",
    padding: "5px 2px",
    borderTopWidth: 1,
    borderTopColor: "gray",
    borderTopStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: "gray",
    borderRightStyle: "solid",
    height: "100%",
  },
  tableCellRight: {
    flexGrow: 1,
    height: "100%",
    flexBasis: "80%",
    padding: "5px 2px",
    borderTopWidth: 1,
    borderTopColor: "gray",
    borderTopStyle: "solid",
    flexWrap: "wrap",
  },
  badgeView: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "red",
  },
  badge: {
    backgroundColor: "#8f1860",
  },
  chartImage: {
    marginTop: 10,
  },
  boxesContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 20,
  },
  boxes: {
    position: "relative",
    width: 60,
    height: 60,
    margin: 10,
  },
  backBox: {
    position: "absolute",
    width: 60,
    height: 60,
    left: 5,
    top: 5,
    borderRadius: 12,
  },
  frontBox: {
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #e6ebf2",
    width: 60,
    height: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
  },
  title: {
    color: "#000",
    fontSize: 10,
    fontWeight: "600",
  },
  score: {
    color: "#facd15",
    fontSize: 32,
    fontWeight: "900",
    marginTop: 1,
  },
  bgEmpty: { backgroundColor: "#afafaf" },
  textEmpty: { color: "#afafaf" },
});
