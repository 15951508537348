import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion, AnimatePresence } from "framer-motion";
import DropdownHelp from "./DropdownHelp";
import DOMPurify from "dompurify";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function VulnerabilitiesTable({
  data,
  entryValue,
  assetName,
  updated_at,
  moduleID,
  getVulnerabilities,
}) {
  // Seem
  const [seeFirst, setSeeFirst] = useState(true);
  const [seeSecond, setSeeSecond] = useState(true);
  const [seeThird, setSeeThird] = useState(true);
  const [seeFourth, setSeeFourth] = useState(true);
  // Others
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [rowId, setRowId] = useState([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "first_name",
      },
      {
        Header: "Title",
        accessor: "name",
      },
      {
        Header: "Severity",
        accessor: "severity",
      },
      {
        Header: "CVSS Score",
        accessor: "cvss_score",
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const [sortBy, setSortBy2] = useState([{ id: "company_name", desc: false }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data: data,
      defaultColumn,
      initialState: {
        sortBy,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
    // useResizeColumns
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const rowIdFunc = (id) => {
    let tempArray = [...rowId];
    let check = rowId.some((item) => item === id);
    if (check) {
      let filterFunc = rowId.filter((item) => item !== id);
      setRowId(filterFunc);
    } else {
      tempArray.push(id);
      setRowId(tempArray);
    }
  };

  let rowData = [];

  const convertJSONToCSV = (data) => {
    const csvHeader = "Title;Severity;CVSS Score";

    const csvRows = data.map((item) => {
      let formattedSeverity = "CRITICAL";
      if (item?.severity === 1) {
        formattedSeverity = "LOW";
      } else if (item?.severity === 2) {
        formattedSeverity = "MEDIUM";
      } else if (item?.severity === 3) {
        formattedSeverity = "HIGH";
      } else if (item?.severity === 0) {
        formattedSeverity = "INFO";
      } else if (item?.severity === 4) {
        formattedSeverity = "CRITICAL";
      }
      return `${item?.name};${formattedSeverity};${item?.cvss_score
        .toString()
        .replace(/\./, ",")}`;
    });

    const csvContent = [csvHeader, ...csvRows].join("\n");

    return csvContent;
  };

  const downloadCSV = (data) => {
    // JSON verisini CSV'ye dönüştür
    const csvData = convertJSONToCSV(data);
    // CSV dosyasını indirme işlemi
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const fileName = "Web Application.csv";

    if (window.navigator.msSaveBlob) {
      // IE 10+ için
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // Diğer tarayıcılar için
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
  };

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            // style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col md={3}>
          <div className="dt-buttons flex-wrap justify-content-center d-flex ">
            <button
              title="Copy Clipboard"
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                const csvHeader = "Title,Severity,CVSS Score";

                const csvRows = data.map((item) => {
                  let formattedSeverity = "CRITICAL";
                  if (item?.severity === 1) {
                    formattedSeverity = "LOW";
                  } else if (item?.severity === 2) {
                    formattedSeverity = "MEDIUM";
                  } else if (item?.severity === 3) {
                    formattedSeverity = "HIGH";
                  } else if (item?.severity === 0) {
                    formattedSeverity = "INFO";
                  } else if (item?.severity === 4) {
                    formattedSeverity = "CRITICAL";
                  }
                  return `${item?.name},${formattedSeverity},${item?.cvss_score}`;
                });

                const csvContent = [csvHeader, ...csvRows].join("\n");
                // const formattedData = formatData(csvContent);

                var table = document.createElement("table");
                var tableBody = document.createElement("tbody");

                var rows = csvContent.split("\n");
                for (var i = 0; i < rows.length; i++) {
                  var row = document.createElement("tr");
                  var cells = rows[i].split("\t");
                  for (var j = 0; j < cells.length; j++) {
                    var cell = document.createElement(i === 0 ? "th" : "td");
                    cell.appendChild(document.createTextNode(cells[j]));
                    row.appendChild(cell);
                  }
                  tableBody.appendChild(row);
                }

                table.appendChild(tableBody);
                document.body.appendChild(table);

                var range = document.createRange();
                range.selectNode(table);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);

                try {
                  document.execCommand("copy");
                  toastSuccess("All Rows Copied");
                } catch (err) {
                  console.error("Panoya kopyalama hatası:", err);
                }

                table.remove();
                window.getSelection().removeAllRanges();
              }}
            >
              <span className="avatar-title bg-transparent">
                <i className="mdi mdi-content-copy  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <button
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                downloadCSV(data);
              }}
              title="Download CSV"
            >
              <span className="avatar-title bg-transparent d-flex flex-column ">
                <i className="mdi mdi-microsoft-excel  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <Dropdown
              isOpen={btnprimary1}
              toggle={() => setBtnprimary1(!btnprimary1)}
            >
              <DropdownToggle
                title="Column Visibility"
                tag="button"
                className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              >
                <i className="mdi mdi-eye" />
                <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                  <span className="visually-hidden">unread messages</span>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setSeeFirst(!seeFirst);
                  }}
                  className={`${seeFirst ? "bg-soft-primary" : ""}`}
                >
                  #
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setSeeSecond(!seeSecond);
                  }}
                  className={`${seeSecond ? "bg-soft-primary" : ""}`}
                >
                  Title
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setSeeThird(!seeThird);
                  }}
                  className={`${seeThird ? "bg-soft-primary" : ""}`}
                >
                  Severity
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setSeeFourth(!seeFourth);
                  }}
                  className={`${seeFourth ? "bg-soft-primary" : ""}`}
                >
                  Cvss
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <div style={{ maxHeight: "1000px", overflowY: "scroll" }}>
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th className={`${seeFirst ? "col-1" : "d-none "}`}>
                  {headerGroup.headers[0].Header}
                </th>
                <th
                  {...headerGroup.headers[1].getHeaderProps(
                    headerGroup.headers[1].getSortByToggleProps()
                  )}
                  className={`${seeSecond ? "col-7" : "d-none "}`}
                >
                  {headerGroup.headers[1].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[1].isSorted
                      ? headerGroup.headers[1].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[2].getHeaderProps(
                    headerGroup.headers[2].getSortByToggleProps()
                  )}
                  className={`${seeThird ? "col-2" : "d-none "}`}
                >
                  {headerGroup.headers[2].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[2].isSorted
                      ? headerGroup.headers[2].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[3].getHeaderProps(
                    headerGroup.headers[3].getSortByToggleProps()
                  )}
                  className={`${seeFourth ? "col-2" : "d-none "}`}
                >
                  {headerGroup.headers[3].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[3].isSorted
                      ? headerGroup.headers[3].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                {/* {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {" "}
                  {column.render("Header")}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              ))} */}
              </tr>
            ))}
          </thead>

          {data.length > 0 && (
            <tbody {...getTableBodyProps()}>
              {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
                prepareRow(row);
                rowData.push(row.original);
                return (
                  <React.Fragment key={index}>
                    <tr
                      className={`${
                        rowId.some((item) => item === row.id)
                          ? "bg-soft-primary"
                          : ""
                      }`}
                      style={{ width: "100%" }}
                      {...row.getRowProps()}
                    >
                      {seeFirst && (
                        <td
                          onClick={() => {
                            rowIdFunc(row.id);
                          }}
                          className="col-1"
                        >
                          {rowId.some((item) => item === row.id) ? (
                            <i className="fa fa-chevron-down" />
                          ) : (
                            <i className="fa fa-chevron-right" />
                          )}
                        </td>
                      )}
                      {seeSecond && (
                        <td className="col">{row.cells[1].value}</td>
                      )}
                      {seeThird && (
                        <td className="col">
                          {row.cells[2].value === 1 ? (
                            <span className="badge bg-severity-low font-size-18">
                              Low
                            </span>
                          ) : row.cells[2].value === 2 ? (
                            <span className="badge bg-severity-medium font-size-18">
                              Medium
                            </span>
                          ) : row.cells[2].value === 3 ? (
                            <span className="badge bg-severity-high font-size-18">
                              High
                            </span>
                          ) : row.cells[2].value === 4 ? (
                            <span className="badge bg-severity-critical font-size-18">
                              Critical
                            </span>
                          ) : (
                            <span className="badge bg-severity-info font-size-18">
                              Info
                            </span>
                          )}
                        </td>
                      )}
                      {seeFourth && (
                        <td className="col">{row.cells[3].value}</td>
                      )}
                      {/* {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })} */}
                    </tr>
                    <AnimatePresence>
                      {rowId.some((item) => item === row?.id) && (
                        <motion.tr
                          // style={{ backgroundColor: "#f8f8f8" }}
                          initial={{ y: -100, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: 0, opacity: 0 }}
                          transition={{ duration: 0.4 }}
                        >
                          <td>
                            <div style={{ padding: "40px" }} className="row">
                              <div className="col-md-9">
                                <div>
                                  <Table className="table mb-0">
                                    <tbody border={1}>
                                      {row?.original?.description && (
                                        <tr>
                                          <td className="fw-semibold">
                                            Description
                                          </td>
                                          <td>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                  row?.original?.description
                                                ),
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      )}
                                      {row?.original?.impact && (
                                        <tr>
                                          <td className="fw-semibold">
                                            Impact
                                          </td>
                                          <td>
                                            {row?.original?.impact || "-"}
                                          </td>
                                        </tr>
                                      )}
                                      {row?.original?.recommendation && (
                                        <tr>
                                          <td className="fw-semibold">
                                            Recommendation
                                          </td>
                                          <td>
                                            {row?.original?.recommendation ||
                                              "-"}
                                          </td>
                                        </tr>
                                      )}
                                      {row?.original?.url && (
                                        <tr>
                                          <td className="fw-semibold">URL</td>
                                          <td>{row?.original?.url || "-"}</td>
                                        </tr>
                                      )}
                                      {row?.original?.details && (
                                        <tr>
                                          <td className="fw-semibold">
                                            Attack Details
                                          </td>
                                          <td>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                  row?.original?.details
                                                ),
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                  <div />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="row mb-3">
                                  <div className="col-xl-8 mb-3  ">
                                    <DropdownHelp
                                      row={row}
                                      assetName={assetName}
                                      updated_at={updated_at}
                                      moduleID={moduleID}
                                      getVulnerabilities={getVulnerabilities}
                                    />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <h5 className="mb-3">CVSS Vector 2</h5>
                                  <div className="row mb-3 font-size-13">
                                    {row?.original?.cvss2 || "-"}
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <h5 className="mb-3">CVSS Vector 3</h5>
                                  <div className="row mb-3 font-size-13">
                                    {row?.original?.cvss3 || "-"}
                                  </div>
                                </div>
                                {row?.original?.refs?.length > 0 && (
                                  <div className="row ">
                                    <h5 className="mb-3">References</h5>
                                    <div className="row mb-3">
                                      <ul>
                                        {row?.original?.refs?.map((item) => {
                                          const url = new URL(item?.url);
                                          const hostname = url?.hostname;
                                          const parts = hostname.split(".");
                                          const domain = parts
                                            .slice(-2)
                                            .join(".");
                                          return (
                                            <li
                                              key={item?.name}
                                              className="mb-2"
                                            >
                                              <a
                                                href={item?.url}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <span className="badge badge-soft-primary font-size-14">
                                                  {domain || "-"}
                                                </span>
                                              </a>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </motion.tr>
                      )}
                    </AnimatePresence>
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
        {data.length < 1 && (
          <span
            style={{ width: "100%" }}
            className="d-flex justify-content-center "
          >
            No data available in table
          </span>
        )}
      </div>
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default VulnerabilitiesTable;
