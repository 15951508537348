import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Container, Spinner } from "reactstrap";
import { get } from "../../../helpers/api_helper";
import LogsComponent from "../../../components/Admin/Logs/LogsComponent";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const Logs = () => {
  document.title = "Logs | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState();
  const [logs, setLogs] = useState();

  const getLogs = async () => {
    paceFunction(true);
    setIsLoading(true);
    try {
      let result = await get(`/admin/logs`);
      if (result?.success) {
        setLogs(result?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    getLogs();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Logs" breadcrumbItem="PentestBX Logs" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && logs && <LogsComponent logs={logs} />}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Logs;
