import React from "react";
import { CardBody, CardTitle } from "reactstrap";

const ProfileCards = ({ modules }) => {
  const allModules = [
    { name: "NET_MON", text: "Network Monitor" },
    { name: "CYBER_WATCH", text: "Cyber Watch" },
    { name: "WEB_APPLICATION", text: "Web Applications" },
    { name: "MXTHREAT", text: "Mailbox Threats" },
    { name: "HOST", text: "Hosts" },
  ];

  let missingModules = [];
  if (modules?.length > 0) {
    allModules.forEach((mod) => {
      if (!modules.some((allModule) => allModule.name === mod.name)) {
        missingModules.push(mod);
      }
    });
  } else {
    missingModules = allModules;
  }
  return (
    <>
      <div className="special-card mt-lg-0 mt-5">
        <CardBody>
          <CardTitle className="mb-3">Modules</CardTitle>

          <div className="d-flex flex-wrap gap-2 font-size-16">
            {modules?.length > 0 &&
              modules?.map((item, index) => (
                <div
                  key={index}
                  className="badge badge-soft-primary font-size-14"
                >
                  <i className="mdi mdi-checkbox-marked-circle-outline" />{" "}
                  {item?.text}
                </div>
              ))}
            {missingModules?.length > 0 &&
              missingModules?.map((item, index) => (
                <div
                  key={index}
                  className="badge badge-soft-secondary font-size-14"
                >
                  <i className="mdi mdi-checkbox-blank-circle-outline" />{" "}
                  {item?.text}
                </div>
              ))}
          </div>
        </CardBody>
      </div>
    </>
  );
};

export default ProfileCards;
