import React, { useRef, useState } from "react";
import Vulnerabilities from "./Vulnerabilities";
import SplineArea from "./Graphics/SplineArea";
import LineGraphic from "./Graphics/LineGraphic";
import DonutChart from "./Graphics/DonutChart";
import { CardBody, CardHeader } from "reactstrap";
import { post } from "../../../helpers/api_helper";
import "react-toastify/dist/ReactToastify.css";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import { useHistory } from "react-router-dom";
import WebApplicationPdfReport from "./Pdf/WebApplicationPdfReport";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";

const WebApplication = ({ assets, moduleID }) => {
  const router = useHistory();

  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [arrayChangeValue, setArrayChangeValue] = useState(0);
  const [dataChangeValue, setDataChangeValue] = useState(0);
  const [updated_at, set_updated_at] = useState(assets[0]?.scans[0]?.date);
  const [assetID, setAssetID] = useState(assets[0]?.id);
  const [pdfLoading, set_pdfLoading] = useState(false);
  const [assetName, set_assetName] = useState(assets[0]?.address);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectDisabled, setSelectDisabled] = useState(false);

  const [pdfData, setPdfData] = useState();
  const buttonRef = useRef(null);

  const onChangeApplications = (data) => {
    let itemData = JSON.parse(data);
    setDataChangeValue(itemData?.index);
    setAssetID(itemData?.id);
    set_assetName(itemData?.address);
    set_updated_at(assets[itemData.index]?.scans[0]?.date);
    setArrayChangeValue(0);
    setSelectedValue("");
  };

  const onChangeDate = (data) => {
    let itemData = JSON.parse(data);
    set_updated_at(itemData?.date);
    setArrayChangeValue(itemData?.index);
  };

  const pdfExportFunc = async () => {
    paceFunction(true);
    set_pdfLoading(true);
    let data = {
      id: assetID,
      date: updated_at,
    };
    try {
      let result = await post("/customer/module/web-app/vulns-export", data);
      if (result?.success) {
        setPdfData(result?.data);
        // setDonutData([2, 5]);
        setTimeout(() => {
          // handleConvertHtmlToImage();
        }, 3000);
        setTimeout(() => {
          buttonRef.current.click();
          set_pdfLoading(false);
          paceFunction(false);
        }, 8000);
        setTimeout(() => {
          setPdfData();
          // setDonutData();
        }, 10000);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
      set_pdfLoading(false);
      paceFunction(false);
    }
  };

  return (
    <React.Fragment>
      {/* {pdfData && (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <WebApplicationPdfReport data={pdfData} />
        </PDFViewer>
      )} */}
      <div className="row">
        <div className="col-xl-6 mb-xl-0 mb-3 ">
          <div className="special-card">
            <CardHeader>
              <h4 className="card-title mb-0">
                Application Vulnerability Trends
              </h4>
            </CardHeader>
            <CardBody>
              <SplineArea
                data={assets[dataChangeValue]?.scans
                  ?.slice()
                  ?.sort((a, b) => new Date(a.date) - new Date(b.date))}
                dateFormat={assets[dataChangeValue]?.date_format}
              />
            </CardBody>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="special-card">
            <CardHeader>
              <h4 className="card-title mb-0">Summary of Vulnerability </h4>
            </CardHeader>
            <CardBody>
              <div className="row align-items-center ">
                <div className="col-xl-5">
                  <DonutChart
                    data={assets[dataChangeValue]?.scans}
                    arrayChangeValue={arrayChangeValue}
                  />
                </div>
                <div className="col-sm align-self-center">
                  <div className="mt-4 mt-sm-0">
                    <div>
                      <div className="form-group">
                        <div
                          className=" mb-3 d-flex justify-content-between   align-items-center "
                          role="group"
                        >
                          <button
                            type="button"
                            onClick={() => router.push("/report-compare")}
                            className="btn btn-primary w-sm font-size-12"
                          >
                            <i className="mdi mdi-file-compare d-block font-size-20" />
                            Compare Report
                          </button>
                          {pdfLoading ? (
                            <button
                              disabled
                              type="button"
                              className="btn btn-danger w-sm font-size-12"
                              id="generate_pdf_btn"
                            >
                              <i
                                className="bx bx-loader bx-spin d-block font-size-20"
                                id="gpbicon"
                              />
                              PDF Export
                            </button>
                          ) : (
                            <button
                              onClick={pdfExportFunc}
                              type="button"
                              className="btn btn-danger w-sm font-size-12"
                              id="generate_pdf_btn"
                              disabled={selectDisabled}
                            >
                              <i
                                className="mdi mdi-file-document-multiple-outline d-block font-size-20"
                                id="gpbicon"
                              />
                              PDF Export
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label>Applications</label>
                        <select
                          disabled={selectDisabled}
                          onChange={(e) => {
                            onChangeApplications(e.target.value);
                          }}
                          className="form-select"
                          id="scan_date"
                        >
                          {assets?.map((item, index) => {
                            let newItem = { ...item, index };
                            return (
                              <option
                                key={index}
                                value={JSON.stringify(newItem)}
                              >
                                {item?.address}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label>History</label>
                        <select
                          disabled={selectDisabled}
                          onChange={(e) => {
                            onChangeDate(e.target.value);
                            setSelectedValue(e.target.value);
                          }}
                          className="form-select"
                          id="scan_date"
                          value={selectedValue}
                        >
                          {assets[dataChangeValue]?.scans
                            ?.sort(
                              (a, b) => new Date(b.date) - new Date(a.date)
                            )
                            ?.map((item, index) => {
                              let newItem = { ...item, index };
                              return (
                                <option
                                  key={index}
                                  value={JSON.stringify(newItem)}
                                >
                                  {item?.created_at}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <label>Score</label>
                      <div
                        style={{ height: "125px" }}
                        className={`bg-hexagon-${
                          assets[dataChangeValue]?.scans[arrayChangeValue]
                            ?.score <= 0.8
                            ? "a"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 1.5
                            ? "b"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 2.4
                            ? "c"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 3.5
                            ? "d"
                            : assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 4
                            ? "e"
                            : "g"
                        }   pt-3  `}
                      >
                        <div className="row align-items-center justify-content-center mt-1  ">
                          <div className="hexagon-small align-items-center   justify-content-center d-flex ">
                            <span>
                              {assets[dataChangeValue]?.scans[arrayChangeValue]
                                ?.score <= 0.8
                                ? "A"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 1.5
                                ? "B"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 2.4
                                ? "C"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 3.5
                                ? "D"
                                : assets[dataChangeValue]?.scans[
                                    arrayChangeValue
                                  ]?.score <= 4
                                ? "E"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <LineGraphic
                        data={assets[dataChangeValue]?.scans
                          ?.slice()
                          ?.sort((a, b) => new Date(a.date) - new Date(b.date))}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </div>
        </div>
        <div className="col-md-12 mt-5 ">
          <Vulnerabilities
            updated_at={updated_at}
            assetID={assetID}
            assetName={assetName}
            moduleID={moduleID}
            setSelectDisabled={(e) => setSelectDisabled(e)}
          />
        </div>
      </div>
      <div className="">
        <PDFDownloadLink
          document={<WebApplicationPdfReport data={pdfData} />}
          fileName={
            pdfData
              ? `${pdfData?.report_date}-Web_Application_Report.pdf`
              : "Web_Application_Report.pdf"
          }
        >
          {pdfData && <button ref={buttonRef}></button>}
        </PDFDownloadLink>
      </div>
    </React.Fragment>
  );
};

export default WebApplication;
