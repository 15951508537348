import React, { useState } from "react";
import {
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import ProfileTabOverview from "./ProfileTabOverview";
import AccountAssetLimits from "./AccountAssetLimits";
import UpdatePassword from "./UpdatePassword";

const ProfileInfo = ({
  profileInfos,
  renderGetProfile,
  set_renderGetProfile,
  customerLimits,
}) => {
  const [activeTab, toggleTab] = useState("1");
  const [modal_standard, setmodal_standard] = useState(false);

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  return (
    <>
      <UpdatePassword
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
      />
      <div className="special-card">
        <CardBody>
          <Row>
            <div className="col-sm order-2 order-sm-1">
              <div className="d-flex align-items-start mt-3 mt-sm-0">
                <div className="flex-grow-1">
                  <div>
                    <h5 className="font-size-16 mb-1">
                      {profileInfos.organization_name}
                    </h5>
                    <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                      <div>
                        <i className="mdi mdi-circle-medium me-1 text-primary align-middle"></i>
                        {profileInfos.phone_number || "-"}
                      </div>
                      <div>
                        <i className="mdi mdi-circle-medium me-1 text-primary align-middle"></i>
                        {profileInfos.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-auto order-1 order-sm-2">
              <div className="d-flex align-items-start justify-content-end gap-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-soft-primary"
                    onClick={tog_standard}
                  >
                    <i className="mdi mdi-account-key-outline me-1"></i> Update
                    Password
                  </button>
                </div>
              </div>
            </div>
          </Row>

          <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
            <NavItem>
              <NavLink
                href="#"
                className={classnames(
                  {
                    active: activeTab === "1",
                  },
                  "px-3"
                )}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                Overview
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames(
                  {
                    active: activeTab === "2",
                  },
                  "px-3"
                )}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                Account & Asset Limits
              </NavLink>
            </NavItem>
          </Nav>
        </CardBody>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ProfileTabOverview
            profileInfos={profileInfos}
            renderGetProfile={renderGetProfile}
            set_renderGetProfile={(e) => set_renderGetProfile(e)}
          />
        </TabPane>
        <TabPane tabId="2">
          <AccountAssetLimits customerLimits={customerLimits} />
        </TabPane>
      </TabContent>
    </>
  );
};

export default ProfileInfo;
