import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";
import { get } from "../../../../../helpers/api_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import CvesComponent from "../../../../../components/Customer/CyberWatch/ThreatIntelligence/Cves/CvesComponent";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../../../common/InfoNoDataComponent";
import { useHistory, useLocation } from "react-router-dom";
import VulnerabilitiesWatcherTabs from "./VulnerabilitiesWatcherTabs";

const Cves = () => {
  document.title = "Threat Intelligence | Cves";

  const router = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const vendor = queryParams.get("vendor");
  const product = queryParams.get("product");
  const cwe = queryParams.get("cwe");
  const cvss2Url = queryParams.get("cvss2");
  const cvss3Url = queryParams.get("cvss3");
  const searchUrl = queryParams.get("search");
  const tagUrl = queryParams.get("tag");
  const subscriptionListUrl = queryParams.get("subscription_list");

  const [cvePage, set_cvePage] = useState(page);
  const [cves, set_cves] = useState();
  const [cvss2, set_cvss2] = useState(cvss2Url ? cvss2Url : "");
  const [cvss3, set_cvss3] = useState(cvss3Url ? cvss3Url : "");
  const [search, set_search] = useState(searchUrl ? searchUrl : "");
  const [tag, set_tag] = useState(tagUrl ? tagUrl : "");
  const [searchFilter, set_searchFilter] = useState();
  const [tags, set_tags] = useState([]);
  const [subscriptions, set_subscriptions] = useState([]);
  const [subscription_list, set_subscription_list] = useState(
    subscriptionListUrl === "true" ? true : false
  );
  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);

  const [buttonDisabled, set_buttonDisabled] = useState(false);

  const [seemInfoComponent, set_seemInfoComponent] = useState(false);
  const [seemInfoComponentGrantAccess, set_seemInfoComponentGrantAccess] =
    useState(false);

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "error",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  const getThreatIntelligenceCves = async (data) => {
    set_buttonDisabled(true);
    paceFunction(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves?page=${
          data ? data : cvePage
        }&vendor=${vendor !== null ? vendor : ""}&product=${
          product !== null ? product : ""
        }&cvss2=${cvss2}&cvss3=${cvss3}&search=${search}${
          cwe ? `&cwe=${cwe}` : ""
        }${tag ? `&tag=${tag}` : ""}&subscription_list=${subscription_list}`
      );
      if (result?.success) {
        if (result?.data?.length > 0) {
          if (!firstLoading) {
            setFirstLoading(true);
          }
          set_cves(result?.data);
          set_seemInfoComponent(false);
          set_seemInfoComponentGrantAccess(false);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=${
                data ? data : cvePage
              }${search ? `&search=${search}` : ""}${
                cvss2 ? `&cvss2=${cvss2}` : ""
              }${cvss3 ? `&cvss3=${cvss3}` : ""}${
                vendor ? `&vendor=${vendor}` : ""
              }${product ? `&product=${product}` : ""}${
                cwe ? `&cwe=${cwe}` : ""
              }${
                tag ? `&tag=${tag}` : ""
              }&subscription_list=${subscription_list}`
            );
          }
        } else {
          set_seemInfoComponent(true);
          set_cves();
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=${
                data ? data : cvePage
              }${search ? `&search=${search}` : ""}${
                cvss2 ? `&cvss2=${cvss2}` : ""
              }${cvss3 ? `&cvss3=${cvss3}` : ""}${
                vendor ? `&vendor=${vendor}` : ""
              }${product ? `&product=${product}` : ""}${
                cwe ? `&cwe=${cwe}` : ""
              }${
                tag ? `&tag=${tag}` : ""
              }&subscription_list=${subscription_list}`
            );
          }
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=${
                data ? data : cvePage
              }${search ? `&search=${search}` : ""}${
                cvss2 ? `&cvss2=${cvss2}` : ""
              }${cvss3 ? `&cvss3=${cvss3}` : ""}${
                vendor ? `&vendor=${vendor}` : ""
              }${product ? `&product=${product}` : ""}${
                cwe ? `&cwe=${cwe}` : ""
              }${
                tag ? `&tag=${tag}` : ""
              }&subscription_list=${subscription_list}`
            );
          }
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cves"
            );
          }
        }
      }
      set_cves();
    }
    setIsLoading(false);
    paceFunction(false);
    set_cvePage(data ? data : cvePage);
    set_buttonDisabled(false);
    if (search) {
      set_searchFilter(search);
    } else {
      set_searchFilter();
    }
  };

  const displayChangeCves = async (data) => {
    paceFunction(true);
    set_buttonDisabled(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves?page=1&subscription_list=${data}`
      );
      if (result?.success) {
        if (result?.data?.length > 0) {
          if (!firstLoading) {
            setFirstLoading(true);
          }
          set_cvePage(1);
          set_subscription_list(data);
          set_cves(result?.data);
          set_seemInfoComponent(false);
          set_seemInfoComponentGrantAccess(false);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&subscription_list=${data}`
            );
          }
        } else {
          set_seemInfoComponent(true);
          set_cves();
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&subscription_list=${data}`
            );
          }
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&subscription_list=${data}`
            );
          }
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cves"
            );
          }
        }
      }
      set_cves();
    }
    paceFunction(false);
    set_buttonDisabled(false);
    set_search("");
    set_cvss2("");
    set_cvss3("");
    set_tag("");
    set_searchFilter();
  };

  useEffect(() => {
    if (!firstLoading) {
      setIsLoading(true);
    }
    getThreatIntelligenceCves();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    getThreatIntelligenceCves(1);
  };

  const cleanVendorSearch = async () => {
    paceFunction(true);
    set_buttonDisabled(true);
    try {
      let result = await get(`/customer/module/cyberwatch/ti/cves?page=1`);
      if (result?.success) {
        if (result?.data?.length > 0) {
          set_cvePage(1);
          set_cves(result?.data);
          set_seemInfoComponent(false);
          set_seemInfoComponentGrantAccess(false);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(`/cyber-watch/threat-intelligence/cves?page=1`);
          }
        } else {
          set_seemInfoComponent(true);
          set_cves();
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(`/cyber-watch/threat-intelligence/cves?page=1`);
          }
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(`/cyber-watch/threat-intelligence/cves?page=1`);
          }
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cves"
            );
          }
        }
      }
      set_cves();
    }
    paceFunction(false);
    set_buttonDisabled(false);
    set_searchFilter();
    set_search("");
    set_cvss2("");
    set_cvss3("");
    set_tag("");
    set_subscription_list(false);
  };

  const cleanProduct = async () => {
    paceFunction(true);
    set_buttonDisabled(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves?page=1&vendor=${
          vendor !== null ? vendor : ""
        }&cvss2=${cvss2}&cvss3=${cvss3}&search=${search}${
          tag ? `&tag=${tag}` : ""
        }`
      );
      if (result?.success) {
        if (result?.data?.length > 0) {
          set_cvePage(1);
          set_cves(result?.data);
          set_seemInfoComponent(false);
          set_seemInfoComponentGrantAccess(false);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1${
                search ? `&search=${search}` : ""
              }${cvss2 ? `&cvss2=${cvss2}` : ""}${
                cvss3 ? `&cvss3=${cvss3}` : ""
              }${vendor ? `&vendor=${vendor}` : ""}${tag ? `&tag=${tag}` : ""}`
            );
          }
        } else {
          set_seemInfoComponent(true);
          set_cves();
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1${
                search ? `&search=${search}` : ""
              }${cvss2 ? `&cvss2=${cvss2}` : ""}${
                cvss3 ? `&cvss3=${cvss3}` : ""
              }${vendor ? `&vendor=${vendor}` : ""}${tag ? `&tag=${tag}` : ""}`
            );
          }
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1${
                search ? `&search=${search}` : ""
              }${cvss2 ? `&cvss2=${cvss2}` : ""}${
                cvss3 ? `&cvss3=${cvss3}` : ""
              }${vendor ? `&vendor=${vendor}` : ""}${tag ? `&tag=${tag}` : ""}`
            );
          }
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cves"
            );
          }
        }
      }
      set_cves();
    }
    paceFunction(false);
    set_buttonDisabled(false);
    if (search) {
      set_searchFilter(search);
    } else {
      set_searchFilter();
    }
    set_subscription_list(false);
  };

  const filterMyVendor = async (dataVendor) => {
    paceFunction(true);
    set_buttonDisabled(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves?page=1&vendor=${dataVendor}`
      );
      if (result?.success) {
        if (result?.data?.length > 0) {
          set_cvePage(1);
          set_cves(result?.data);
          set_seemInfoComponent(false);
          set_seemInfoComponentGrantAccess(false);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&vendor=${dataVendor}`
            );
          }
        } else {
          set_seemInfoComponent(true);
          set_cves();
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&vendor=${dataVendor}`
            );
          }
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&vendor=${dataVendor}`
            );
          }
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cves"
            );
          }
        }
      }
      set_cves();
    }
    paceFunction(false);
    set_buttonDisabled(false);
    set_search("");
    set_searchFilter();
    set_cvss2("");
    set_cvss3("");
    set_tag("");
    set_subscription_list(false);
  };

  const filterMyProduct = async (dataVendor, dataProduct) => {
    paceFunction(true);
    set_buttonDisabled(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves?page=1&vendor=${dataVendor}&product=${dataProduct}`
      );
      if (result?.success) {
        if (result?.data?.length > 0) {
          set_cvePage(1);
          set_cves(result?.data);
          set_seemInfoComponent(false);
          set_seemInfoComponentGrantAccess(false);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&vendor=${dataVendor}&product=${dataProduct}`
            );
          }
        } else {
          set_seemInfoComponent(true);
          set_cves();
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&vendor=${dataVendor}&product=${dataProduct}`
            );
          }
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&vendor=${dataVendor}&product=${dataProduct}`
            );
          }
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cves"
            );
          }
        }
      }
      set_cves();
    }
    paceFunction(false);
    set_buttonDisabled(false);
    set_search("");
    set_searchFilter();
    set_cvss2("");
    set_cvss3("");
    set_tag("");
    set_subscription_list(false);
  };

  const filterMyTag = async (dataTag) => {
    paceFunction(true);
    set_buttonDisabled(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves?page=1&tag=${dataTag}`
      );
      if (result?.success) {
        if (result?.data?.length > 0) {
          set_cvePage(1);
          set_cves(result?.data);
          set_seemInfoComponent(false);
          set_seemInfoComponentGrantAccess(false);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&tag=${dataTag}`
            );
          }
        } else {
          set_seemInfoComponent(true);
          set_cves();
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&tag=${dataTag}`
            );
          }
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
          if (
            window.location.pathname === "/cyber-watch/threat-intelligence/cves"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/cves?page=1&tag=${dataTag}`
            );
          }
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cves"
            );
          }
        }
      }
      set_cves();
    }
    paceFunction(false);
    set_buttonDisabled(false);
    set_search("");
    set_searchFilter();
    set_cvss2("");
    set_cvss3("");
    set_tag(dataTag);
    set_subscription_list(false);
  };

  const getSubscriptions = async () => {
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves/subscriptions`
      );
      if (result?.success) {
        set_subscriptions(result?.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
  };

  const getTags = async () => {
    try {
      let result = await get(`/customer/module/cyberwatch/ti/cves/tags`);
      if (result?.success) {
        set_tags(result?.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
  };

  useEffect(() => {
    getTags();
    getSubscriptions();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Threat Intelligence / Vulnerabilities"
            breadcrumbItem="Threat Intelligence / Vulnerabilities"
          />
          <VulnerabilitiesWatcherTabs />
          {(vendor || searchFilter || product || cwe) && (
            <div className="row justify-content-between mb-2 ">
              {(vendor || searchFilter || cwe) && (
                <div className="col-md-6">
                  <div className="special-card card-info d-flex justify-content-between align-items-center  ">
                    <div className="font-size-16">
                      <i className="fa fa-filter" /> Filtered by{" "}
                      {cwe ? "cwe" : searchFilter ? "search" : "vendor"}{" "}
                      <strong>
                        {vendor
                          ? vendor
                          : cwe
                          ? cwe
                          : searchFilter
                          ? searchFilter
                          : null}
                      </strong>{" "}
                      selected
                    </div>
                    <div className="">
                      <span
                        onClick={cleanVendorSearch}
                        className="font-size-20"
                        style={{ cursor: "pointer" }}
                        aria-hidden="true"
                      >
                        &times;
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {product && (
                <div className="col-md-6">
                  <div className="special-card card-info d-flex justify-content-between align-items-center  ">
                    <div className="font-size-16">
                      <i className="fa fa-filter" /> Filtered by product{" "}
                      <strong>{product}</strong> selected
                    </div>
                    <div className="">
                      <span
                        onClick={cleanProduct}
                        className="font-size-20"
                        style={{ cursor: "pointer" }}
                        aria-hidden="true"
                      >
                        &times;
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="row justify-content-center mb-2 ">
            <div className="col-12">
              <div className="special-card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row align-items-end ">
                      {vendor === null && product === null && cwe === null && (
                        <div className="col-lg-2">
                          <div className="form-group">
                            <label htmlFor="tag">Tags</label>
                            <select
                              onChange={(e) => set_tag(e.target.value)}
                              className="form-control"
                              name="tag"
                              value={tag}
                            >
                              <option />
                              {tags?.map((tagItem, tagIndex) => (
                                <option
                                  key={`tag-${tagIndex}`}
                                  value={tagItem?.name}
                                >
                                  {tagItem?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                      <div className="col-lg-2">
                        <div className="form-group">
                          <label htmlFor="cvss2">Severity V2</label>
                          <select
                            onChange={(e) => set_cvss2(e.target.value)}
                            className="form-control"
                            name="cvss2"
                            value={cvss2}
                          >
                            <option />
                            <option value="low">Low (0.0 - 3.9)</option>
                            <option value="medium">Medium (4.0 - 6.9)</option>
                            <option value="high">High (7.0 - 10.0)</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="form-group">
                          <label htmlFor="cvss3">Severity V3</label>
                          <select
                            onChange={(e) => set_cvss3(e.target.value)}
                            className="form-control"
                            name="cvss3"
                            value={cvss3}
                          >
                            <option />
                            <option value="none">None (0.0)</option>
                            <option value="low">Low (0.1 - 3.9)</option>
                            <option value="medium">Medium (4.0 - 6.9)</option>
                            <option value="high">High (7.0 - 8.9)</option>
                            <option value="critical">
                              Critical (9.0 - 10.0)
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 my-lg-0 my-2 ">
                        <label htmlFor="search">Search</label>
                        <input
                          type="text"
                          placeholder="Search in CVEs"
                          className="form-control"
                          name="search"
                          onChange={(e) => set_search(e.target.value)}
                          value={search}
                        />
                      </div>
                      <div className="col-lg-3 d-flex  align-items-center justify-content-between ">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={buttonDisabled}
                        >
                          Search
                        </button>
                        <button
                          onClick={cleanVendorSearch}
                          type="button"
                          className="btn btn-danger"
                          disabled={buttonDisabled}
                        >
                          Clear All
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && !seemInfoComponent && !seemInfoComponentGrantAccess && (
            <div>
              {cves && tags && subscriptions && (
                <React.Fragment>
                  <CvesComponent
                    cves={cves}
                    tags={tags}
                    subscriptions={subscriptions}
                    subscription_list={subscription_list}
                    getThreatIntelligenceCves={getThreatIntelligenceCves}
                    cvePage={cvePage}
                    filterMyVendor={filterMyVendor}
                    filterMyProduct={filterMyProduct}
                    filterMyTag={filterMyTag}
                    displayChangeCves={displayChangeCves}
                    buttonDisabled={buttonDisabled}
                  />
                </React.Fragment>
              )}
            </div>
          )}
          {!isLoading && seemInfoComponent && (
            <InfoNoDataComponent
              text="No cves data were found"
              component="cves"
            />
          )}
          {!isLoading && seemInfoComponentGrantAccess && (
            <InfoNoDataComponent
              text="Module permission denied. Contact with customer account."
              component="cves"
            />
          )}
          {!isLoading && (
            <div className={`mt-2 col-lg-${cves ? "8" : "12"}`}>
              <Pagination
                className="d-flex align-items-center justify-content-center "
                aria-label="Page navigation "
              >
                {cvePage > 1 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() =>
                        getThreatIntelligenceCves(Number(cvePage) - 1)
                      }
                      tabIndex={-1}
                    >
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink>{Number(cvePage)}</PaginationLink>
                </PaginationItem>
                <PaginationItem></PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    onClick={() =>
                      getThreatIntelligenceCves(Number(cvePage) + 1)
                    }
                  >
                    Next
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Cves;
