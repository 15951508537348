import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { put } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const AddGroupModal = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  getGroupList,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [name, set_name] = useState("");
  const [severity, set_severity] = useState("CRITICAL");

  const [modal_standard5, setmodal_standard5] = useState(false);

  const [addLoading, set_addLoading] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const addGroup = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      name,
      severity,
    };
    try {
      let result = await put("/customer/module/host/group", data);
      if (result?.success) {
        toastSuccess(result?.message || "Group Added");
        tog_standard5();
        getGroupList();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    if (modal_standard) {
      set_name("");
      set_severity("CRITICAL");
    }
  }, [modal_standard]);

  return (
    <React.Fragment>
      {/* Add Group */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addGroup}
        text="Are you sure you want to add group?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add New Ip Group
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            className="swal2-html-container"
            id="swal2-html-container"
            style={{ display: "block" }}
          >
            <div className="input-group">
              <span className="input-group-text">Group Name</span>
              <input
                type="text"
                className="form-control"
                name="new_group_name"
                style={{ width: "50%" }}
                onChange={(e) => set_name(e.target.value)}
              />
            </div>
            <br />
            <div className="input-group">
              <span className="input-group-text">Group Severity</span>
              <select
                onChange={(e) => set_severity(e.target.value)}
                name="new_group_severity"
                className="form-select"
              >
                <option className="text-danger" value="CRITICAL">
                  Critical
                </option>
                <option className="text-warning" value="HIGH">
                  High
                </option>
                <option className="text-info" value="MEDIUM">
                  Medium
                </option>
                <option className="text-success" value="LOW">
                  Low
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            onClick={tog_standard5}
            type="button"
            data-delete-groupbtn="deneme"
            className="btn btn-primary text-truncate"
          >
            <i className="mdi mdi-plus me-1" /> Add
          </button>
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddGroupModal;
