import React, { useState } from "react";
import { Modal, Collapse } from "reactstrap";
import { put } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import classnames from "classnames";

const AddWebApplication = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
  moduleID,
  getWebApplicationsAsset,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [address, set_address] = useState();
  const [severity, set_severity] = useState("CRITICAL");
  const [token, set_token] = useState();

  const [addLoading, set_addLoading] = useState(false);

  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
  };

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const addWebApplicationAsset = async (verify_method) => {
    paceFunction(true);
    set_addLoading(true);
    let data;
    token
      ? (data = {
          id: moduleID,
          address,
          severity,
          verify_method,
          token,
        })
      : (data = {
          id: moduleID,
          address,
          severity,
        });
    try {
      let result = await put("/customer/module/asset", data);
      if (result?.success) {
        if (result?.is_subdomain) {
          toastSuccess(result?.message || "Asset Added");
          tog_standard();
          getWebApplicationsAsset();
          set_severity("CRITICAL");
          set_token();
          set_address();
        } else {
          if (result?.data?.token) {
            set_token(result?.data?.token);
          } else {
            toastSuccess(result?.message || "Asset Added");
            tog_standard();
            getWebApplicationsAsset();
            set_token();
            set_severity("CRITICAL");
            set_address();
          }
        }
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  const downloadHtml = () => {
    paceFunction(true);
    let content = `pentestbx-site-verification=${token}`;
    const htmlContent = content;

    const blob = new Blob([htmlContent], { type: "text/html" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "pentestbx-site-verification.html";
    link.click();
    paceFunction(false);
  };

  return (
    <div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            New Domain Asset
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
              set_severity("CRITICAL");
              set_address();
              set_token();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {token ? (
            <div className="card">
              <div className="card-body">
                <div className="accordion" id="accordion">
                  <p>Recommended Verification Method</p>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classnames("accordion-button", "fw-medium", {
                          collapsed: !col1,
                        })}
                        type="button"
                        onClick={t_col1}
                        style={{ cursor: "pointer" }}
                      >
                        HTML File
                      </button>
                    </h2>

                    <Collapse isOpen={col1} className="accordion-collapse">
                      <div className="accordion-body">
                        <div
                          id="panelsStayOpen-collapseOne"
                          className="accordion-collapse collapse show"
                        >
                          <div className="accordion-body">
                            <ol>
                              <li>
                                Download the file:{" "}
                                <a
                                  type="button"
                                  onClick={downloadHtml}
                                  className="btn btn-light"
                                >
                                  <i className="mdi mdi-download" /> Download
                                  File{" "}
                                </a>
                              </li>
                              <li>
                                Upload to:
                                <code id="verify-domain-name">{address}</code>
                              </li>
                              <li>
                                Click <strong>VERIFY</strong> below.
                              </li>
                            </ol>
                            <button
                              type="button"
                              className="btn btn-primary text-truncate"
                              onClick={() =>
                                addWebApplicationAsset("HTML_FILE")
                              }
                            >
                              <i className="uil uil-user me-1" /> VERIFY
                            </button>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion" id="accordion">
                    <p className="mt-2 ">Other Verification Methods </p>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !col2,
                            }
                          )}
                          type="button"
                          onClick={t_col2}
                          style={{ cursor: "pointer" }}
                        >
                          HTML TAG
                        </button>
                      </h2>

                      <Collapse isOpen={col2} className="accordion-collapse">
                        <div className="accordion-body">
                          <ol>
                            <li>
                              Copy the meta tag below and paste it on your
                              site's homepage.The tag must be added to the
                              <strong>&lt;head&gt;</strong> section before the
                              first <strong>&lt;body&gt;</strong> section.{" "}
                              <br />
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="verify_tag_input"
                                  disabled
                                  defaultValue={`<meta name="pentestbx-site-verification" content="${token}" />`}
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={() => {
                                      var textField =
                                        document.createElement("textarea");
                                      textField.innerText = `<meta name="pentestbx-site-verification" content="${token}" />`;
                                      document.body.appendChild(textField);
                                      textField.select();
                                      document.execCommand("copy");
                                      textField.remove();
                                      toastSuccess(
                                        `<meta name="pentestbx-site-verification" content="${token}" />  copied`
                                      );
                                    }}
                                  >
                                    Copy
                                  </button>
                                </div>
                              </div>
                            </li>
                            <li>
                              Click <strong>VERIFY</strong> below.
                            </li>
                          </ol>
                          <button
                            type="button"
                            className="btn btn-primary text-truncate"
                            onClick={() => addWebApplicationAsset("HTML_TAG")}
                          >
                            <i className="uil uil-user me-1" /> VERIFY
                          </button>
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !col3,
                            }
                          )}
                          type="button"
                          onClick={t_col3}
                          style={{ cursor: "pointer" }}
                        >
                          DNS TXT
                        </button>
                      </h2>
                      <Collapse isOpen={col3} className="accordion-collapse">
                        <div className="accordion-body">
                          <ol>
                            <li>
                              Sign in to your domain provider (e.g. godaddy.com
                              or namecheap.com)
                            </li>
                            <li>
                              Copy the TXT record to the DNS configuration below
                              for <code>{address}</code> <br />
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="verify_dnstxt_input"
                                  disabled
                                  defaultValue={`pentestbx-site-verification=${token}`}
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={() => {
                                      var textField =
                                        document.createElement("textarea");
                                      textField.innerText = `pentestbx-site-verification=${token}`;
                                      document.body.appendChild(textField);
                                      textField.select();
                                      document.execCommand("copy");
                                      textField.remove();
                                      toastSuccess(
                                        `pentestbx-site-verification=${token} copied`
                                      );
                                    }}
                                  >
                                    Copy
                                  </button>
                                </div>
                              </div>
                            </li>
                            <li>
                              Click <strong>VERIFY</strong> below.
                            </li>
                          </ol>
                          <p>
                            Note: It may take some time for DNS changes to take
                            effect.
                          </p>
                          <button
                            type="button"
                            className="btn btn-primary text-truncate"
                            onClick={() => addWebApplicationAsset("DNS_TXT")}
                          >
                            <i className="uil uil-user me-1" /> VERIFY
                          </button>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body text-center" id="add-webapp-modal-body">
                <div className="mx-auto mb-4">
                  <div className="icon-demo-content i">
                    <i
                      className="mdi mdi-file-tree-outline"
                      style={{ fontSize: "40px" }}
                    />
                  </div>
                </div>
                <h4 className="card-title">
                  Domain <span className="badge bg-primary">New</span>
                </h4>
                <ul className="mb-3">
                  <li>All URLs across all subdomains (m.www. ...)</li>
                  <li>Any URLs across https or http</li>
                  <li>Make sure it's not defined before</li>
                  <li>Make sure there is no url prefix</li>
                </ul>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="new_domain_url"
                    placeholder="http(s)://pentestbx.com"
                    defaultValue={address}
                    style={{ border: "1px solid #3fa0da" }}
                    onChange={(e) => set_address(e.target.value)}
                  />
                </div>
                <div className="">
                  <select
                    className="form-control"
                    name="is_credentials"
                    style={{ border: "1px solid #3fa0da" }}
                    id="is_credentials"
                    placeholder="Authorized Scan "
                    onChange={(e) => set_severity(e.target.value)}
                  >
                    <option value="CRITICAL">CRITICAL</option>
                    <option value="HIGH">HIGH</option>
                    <option value="MEDIUM">MEDIUM</option>
                    <option value="LOW">LOW</option>
                  </select>
                  <label id="is_credentials-label" className="errorLabel" />
                </div>
                <button
                  onClick={addWebApplicationAsset}
                  className="btn btn-primary waves-effect waves-light w-100"
                >
                  CONTINUE
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AddWebApplication;
