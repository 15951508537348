import React, { useEffect, useRef, useState } from "react";

import { BsEraser } from "react-icons/bs";
import { GoReport } from "react-icons/go";
import { RiArrowGoBackFill } from "react-icons/ri";
import { motion } from "framer-motion";

import { useScreenshot } from "use-react-screenshot";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CanvasDraw from "react-canvas-draw";

import "./screenshotButton.css";
import { toast } from "react-toastify";
import paceFunctionComponent from "./common/paceFunctionComponent";
import errorFunctionComponent from "./common/errorFunctionComponent";
import ConfirmModal from "./common/utils/ConfirmModal";
import { post, put } from "./components/helpers/api_helper";

const Screenshot = () => {
  const authUser = localStorage.getItem("profileInfo");

  const { paceFunction } = paceFunctionComponent();
  const { errorToastFunction } = errorFunctionComponent();

  const [image, takeScreenshot] = useScreenshot();
  const [level, setLevel] = useState(1);
  const [isFits, setisFits] = useState(false);

  const [type, setType] = useState("QUESTION");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const [addLoading, set_addLoading] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);

  const cropperRef = useRef(null);
  const saveableCanvasRef = useRef(null);

  const [croppedImg, setCroppedImg] = useState("");
  const [color, setColor] = useState("#000");
  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(800);
  const [brushRadius, setBrushRadius] = useState(1);
  const [lazyRadius, setLazyRadius] = useState(1);
  const [canvasDataURL, setCanvasDataURL] = useState("");

  const [isDivOpen, setIsDivOpen] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    // const handleWheelDisable = (e) => {
    //   if (isDivOpen) {
    //     e.preventDefault();
    //   }
    // };

    // if (isDivOpen) {
    //   window.addEventListener("wheel", handleWheelDisable, { passive: false });
    // } else {
    //   window.removeEventListener("wheel", handleWheelDisable);
    // }

    // return () => {
    //   window.removeEventListener("wheel", handleWheelDisable);
    // };
    if (!isDivOpen) {
      setLevel(1);
      setCanvasDataURL();
      setCroppedImg();
    }
  }, [isDivOpen]);

  const handleDivOpen = () => {
    setIsDivOpen(true);
  };

  const handleDivClose = () => {
    setIsDivOpen(false);
  };
  const getImage = () => {
    const element = document.getElementsByClassName("main-content")[0];

    takeScreenshot(element);
    setTimeout(() => {
      handleDivOpen();
    }, 1);
    setTimeout(() => {
      goToTop();
    }, 110);
  };

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setWidth(cropper.cropBoxData.width);
    setHeight(cropper.cropBoxData.height);
    setCroppedImg(cropper.getCroppedCanvas().toDataURL());
  };

  const getCanvasData = () => {
    const canvas = saveableCanvasRef.current.canvasContainer.children[1];
    const ctx = canvas.getContext("2d");

    const backgroundImg = new Image();
    backgroundImg.src = croppedImg;
    backgroundImg.onload = () => {
      ctx.drawImage(backgroundImg, 0, 0, canvas.width, canvas.height);

      saveableCanvasRef.current.lines.forEach((line) => {
        ctx.strokeStyle = line.color;
        ctx.lineWidth = line.size;
        ctx.lineJoin = "round";
        ctx.lineCap = "round";
        ctx.beginPath();
        ctx.moveTo(line.points[0].x, line.points[0].y);
        line.points.forEach((point) => {
          ctx.lineTo(point.x, point.y);
        });
        ctx.stroke();
      });

      const dataURL = canvas.toDataURL();
      setCanvasDataURL(dataURL);
    };
  };

  const handleInitialized = (cropper) => {
    const imageData = cropper.getImageData();
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const sendIssueReport = async () => {
    paceFunction(true);
    set_addLoading(true);
    const base64WithoutPrefix = canvasDataURL.split(";base64,").pop();
    let data = {
      subject,
      description,
      type,
      screenshot: base64WithoutPrefix,
      status: "OPENED",
    };
    try {
      let result = await post("/account/issue", data);
      if (result?.success) {
        toastSuccess(result?.message || "Issue was sent");
        setCanvasDataURL();
        setCroppedImg();
        handleDivClose();
        tog_standard5();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={sendIssueReport}
        text="Are you sure you want to send issue?"
        loadingAction={true}
        isLoading={addLoading}
      />
      {isFits ? (
        <Lightbox
          mainSrc={canvasDataURL}
          enableZoom={true}
          onCloseRequest={() => {
            setisFits(!isFits);
          }}
        />
      ) : null}
      {authUser && (
        <div title="Report An Issue" className="screenshot-btn">
          <GoReport
            className="screenshot-icon-position screenshot-icon-style"
            onClick={getImage}
          />
        </div>
      )}
      {isDivOpen && image && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{ backgroundColor: "#000000b8" }}
          className="w-full h-screen  absolute top-0 left-0 z-10"
        >
          <div className="w-full flex justify-end">
            <button
              style={{ marginTop: "100px" }}
              className="btn btn-secondary  mt-5 me-5"
              onClick={handleDivClose}
            >
              X
            </button>
          </div>
          <motion.div
            initial={{ opacity: 0, translateY: -200 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 1 }}
            className="container mx-auto  z-20 d-flex flex-column  justify-content-between align-items-center   "
            style={{ height: 600, width: "100vw" }}
          >
            <h3 className="text-center text-white ">
              {level === 1
                ? "Crop Image"
                : level === 2
                ? "Draw Image"
                : "Report an Issue"}
            </h3>
            {level === 1 && (
              <Cropper
                src={image}
                style={{ maxHeight: 500, width: "100vw" }}
                initialAspectRatio={16 / 9}
                guides={true}
                crop={onCrop}
                ref={cropperRef}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                // aspectRatio={4 / 3}
                checkOrientation={false}
                onInitialized={handleInitialized}
              />
            )}
            {level === 2 && (
              <div className="">
                <div
                  style={{
                    position: "relative",
                    zIndex: 100,
                    overflow: "overlay",
                    maxHeight: 500,
                    maxWidth: "100vw",
                  }}
                  className=" "
                >
                  <CanvasDraw
                    // hideInterface
                    // enablePanAndZoom
                    // clampLinesToDocument
                    ref={saveableCanvasRef}
                    brushColor={color}
                    brushRadius={brushRadius}
                    lazyRadius={lazyRadius}
                    canvasWidth={width * 2}
                    canvasHeight={height * 2}
                    imgSrc={croppedImg}
                    // hideGrid
                  />
                </div>
                <div className="mt-2 d-flex justify-content-center align-items-center gap-2 ">
                  <BsEraser
                    className="screenshot-canvas-icon-style "
                    onClick={() => {
                      saveableCanvasRef.current.eraseAll();
                    }}
                  />
                  <RiArrowGoBackFill
                    className="screenshot-canvas-icon-style "
                    onClick={() => {
                      saveableCanvasRef.current.undo();
                    }}
                  />
                </div>
              </div>
            )}
            {level === 3 && (
              <div className="row justify-content-center">
                <div className="col-12">
                  <div
                    // style={{ width: width, height: height }}
                    className="screenshot-image"
                  >
                    <img
                      src={canvasDataURL}
                      alt={"Screenshot"}
                      onClick={() => setisFits(true)}
                      className="img-fluid"
                      // width={width}
                      // height={height}
                    />
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <select
                        name="ss-select"
                        id="ss-select"
                        className="ss-select"
                        style={{ padding: "11px" }}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option value="QUESTION">QUESTION</option>
                        <option value="ENHANCEMENT">ENHANCEMENT</option>
                        <option value="INVALID">INVALID</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        placeholder="Subject"
                        className="input-subject mb-2"
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                  </div>

                  <textarea
                    className="rounded"
                    placeholder="Description"
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
            )}
            <div className="d-flex align-items-center justify-content-end mt-2 gap-5 ">
              {level > 1 && (
                <button
                  type="button"
                  className="btn btn-primary "
                  data-dismiss="modal"
                  onClick={() => setLevel(Number(level) - 1)}
                >
                  Back
                </button>
              )}
              {level < 3 && (
                <button
                  type="button"
                  className="btn btn-primary "
                  data-dismiss="modal"
                  onClick={() => {
                    if (level === 2) {
                      getCanvasData();
                      setTimeout(() => {
                        setLevel(Number(level) + 1);
                      }, 100);
                    } else {
                      setLevel(Number(level) + 1);
                    }
                  }}
                >
                  Next
                </button>
              )}
              {level === 3 && (
                <button
                  type="button"
                  className="btn btn-success "
                  data-dismiss="modal"
                  onClick={tog_standard5}
                >
                  Send
                </button>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </React.Fragment>
  );
};

export default Screenshot;
