import React, { useState } from "react";
import { del } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddGrantAccess from "./GrantAccess/AddGrantAccess";
import UpdateGrantAccess from "./GrantAccess/UpdateGrantAccess";
import { useDispatch } from "react-redux";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const GrantAccess = ({ grantAccess }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const dispatch = useDispatch();

  const [accountID, set_accountID] = useState();
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateModalData, set_updateModalData] = useState();

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const deleteGrantAccess = async () => {
    paceFunction(true);
    setAddLoading(true);
    try {
      let result = await del(
        `/customer/settings/linked-account?id=${accountID}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Linked Account Deleted");
        tog_standard5();
        dispatch({ type: "RENDER_COMPONENT_REQ" });
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  const filterDataFunc = (accountData, id) => {
    let filteredData = accountData.filter((item) => item.id === id);
    set_updateModalData(filteredData[0]);
    tog_standard2();
  };

  return (
    <React.Fragment>
      <AddGrantAccess
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
      />
      {updateModalData && (
        <UpdateGrantAccess
          updateModalData={updateModalData}
          accountID={accountID}
          modal_standard={modal_standard2}
          tog_standard={tog_standard2}
          setmodal_standard={setmodal_standard2}
        />
      )}
      {/* Delete Linked Account */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={deleteGrantAccess}
        text="Are you sure you want to delete linked account?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <div className=" mt-3  ">
        <div className="  d-flex align-items-start justify-content-center">
          <button
            type="button"
            className="btn btn-soft-primary special-card-button"
            onClick={tog_standard}
          >
            <i className="mdi mdi-view-grid label-icon  me-1 " />
            Add Grant Access
          </button>
        </div>
      </div>
      <hr className="my-2" />
      <div className="row mt-3 ">
        {grantAccess?.map((item, index) => (
          <div key={index} className="col-md-12 mb-4 ">
            <div className="special-card">
              <div className="d-flex justify-content-between align-items-center flex-sm-row flex-column    fs-6 fw-bold text-primary  ">
                <div className="d-flex align-items-center ">
                  <strong className="me-1">E-mail</strong>
                  <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-3 " />
                  <span>{item.email}</span>
                </div>
                <button
                  onClick={() => {
                    set_accountID(item.id);
                    tog_standard5();
                  }}
                  id="confirmationTooltipGrant"
                  type="button"
                  className="btn btn-soft-primary   special-card-button"
                >
                  <i className="mdi mdi-delete label-icon  me-1 " />
                  Delete
                </button>
                <div className="d-flex align-items-center ">
                  <strong className="me-1">Phone</strong>
                  <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-3 " />
                  <span>{item?.phone_number}</span>
                </div>
              </div>
              <div className="row mt-4">
                {item?.modules?.map((mod, i) => (
                  <div key={mod?.text} className="col-sm-6 mb-4">
                    <div className="special-card2">
                      <div className="d-flex justify-content-center ">
                        <span className="fs-5 fw-bold text-center ">
                          {mod?.text}
                        </span>
                      </div>
                      <hr className="my-2" />
                      <div>
                        <div className="row">
                          <div className="col-xxl-3 col-6 fw-bold  flex-column d-flex text-center">
                            <span className=" ">Access</span>
                            {mod?.permissions?.access ? (
                              <i className="mdi mdi-check-outline  text-success fs-4 text-center " />
                            ) : (
                              <i className="mdi mdi-close-outline  text-danger fs-4 text-center" />
                            )}
                          </div>
                          <div className="col-xxl-3 col-6 fw-bold flex-column d-flex   text-center">
                            <span className=" ">Task Start</span>
                            {mod?.permissions?.task_start ? (
                              <i className="mdi mdi-check-outline  text-success fs-4 text-center " />
                            ) : (
                              <i className="mdi mdi-close-outline  text-danger fs-4 text-center" />
                            )}
                          </div>
                          <div className="col-xxl-3 col-6 fw-bold flex-column d-flex text-center ">
                            <span className=" ">Task Update</span>
                            {mod?.permissions?.task_update ? (
                              <i className="mdi mdi-check-outline  text-success fs-4 text-center" />
                            ) : (
                              <i className="mdi mdi-close-outline  text-danger fs-4 text-center " />
                            )}
                          </div>
                          <div className="col-xxl-3 col-6 fw-bold flex-column d-flex text-center">
                            <span className=" ">Asset Update</span>
                            {mod?.permissions?.asset_update ? (
                              <i className="mdi mdi-check-outline  text-success fs-4 text-center" />
                            ) : (
                              <i className="mdi mdi-close-outline   text-danger fs-4 text-center" />
                            )}
                          </div>
                          <div className="d-flex justify-content-center  fs-6 fw-bold text-primary mt-2  ">
                            <button
                              type="button"
                              className="btn btn-soft-primary "
                              onClick={() => {
                                set_accountID(item?.id);
                                filterDataFunc(item?.modules, mod?.id);
                              }}
                            >
                              <i className="mdi mdi-update label-icon  me-1 " />
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>{" "}
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default GrantAccess;
