import React, { useEffect, useState } from "react";
import {
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import ProfileTabOverview from "./ProfileTabOverview";
import GrantAccess from "./GrantAccess";
import Notification from "./Notification";
import Endpoint from "./Endpoint";
import UpdatePassword from "./UpdatePassword";
import Issues from "./Issues";

import { get } from "../../../../helpers/api_helper";
import { useSelector } from "react-redux";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";

const CustomerInfo = ({ profileInfos, renderState, setRender }) => {
  const { paceFunction } = paceFunctionComponent();

  const { render, render2 } = useSelector((state) => ({
    render: state.render.render,
    render2: state.render.render2,
  }));

  const [activeTab, toggleTab] = useState("1");
  const [modal_standard, setmodal_standard] = useState(false);
  const [notifications, set_notifications] = useState();
  const [grantAccess, set_grantAccess] = useState();
  const [endpoint, set_endpoint] = useState();
  const [issues, set_issues] = useState();

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const getNotifications = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/settings/account/notification-config");
      if (result?.success) set_notifications(result?.data);
    } catch (error) {
      console.log(`error ==>`, error);
    }
    paceFunction(false);
  };

  const getGrantAccess = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/settings/linked-account");
      if (result?.success) set_grantAccess(result?.data);
    } catch (error) {
      console.log(`error ==>`, error);
    }
    paceFunction(false);
  };

  const getEndpoint = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/settings/endpoint");
      if (result?.success) {
        set_endpoint(result);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      set_endpoint(error.response.data);
    }
    paceFunction(false);
  };

  const getIssues = async () => {
    paceFunction(true);
    try {
      let result = await get("/account/issue");
      if (result?.success) {
        set_issues(result?.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    getNotifications();
    getIssues();
  }, []);

  useEffect(() => {
    getGrantAccess();
  }, [render]);

  useEffect(() => {
    getEndpoint();
  }, [render2]);

  return (
    <>
      <UpdatePassword
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
      />
      <div className="special-card">
        <CardBody>
          <Row>
            <div className="col-sm order-2 order-sm-1">
              <div className="d-flex align-items-start mt-3 mt-sm-0">
                <div className="flex-grow-1">
                  <div>
                    <h5 className="font-size-16 mb-1">
                      {profileInfos?.company_name}
                    </h5>

                    <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                      <div>
                        <i className="mdi mdi-circle-medium me-1 text-primary align-middle"></i>
                        {profileInfos?.phone_number}
                      </div>
                      <div>
                        <i className="mdi mdi-circle-medium me-1 text-primary align-middle"></i>
                        {profileInfos?.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-auto order-1 order-sm-2">
              <div className="d-flex align-items-start justify-content-center justify-content-sm-end  gap-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-soft-primary"
                    onClick={tog_standard}
                  >
                    <i className="mdi mdi-account-key-outline me-1"></i> Update
                    Password
                  </button>
                </div>
              </div>
            </div>
          </Row>

          <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
            <NavItem>
              <NavLink
                href="#"
                className={classnames(
                  {
                    active: activeTab === "1",
                  },
                  "px-3"
                )}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                Overview
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames(
                  {
                    active: activeTab === "2",
                  },
                  "px-3"
                )}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                Notification Config
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames(
                  {
                    active: activeTab === "3",
                  },
                  "px-3"
                )}
                onClick={() => {
                  toggleTab("3");
                }}
              >
                Grant Access
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames(
                  {
                    active: activeTab === "4",
                  },
                  "px-3"
                )}
                onClick={() => {
                  toggleTab("4");
                }}
              >
                Endpoint
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames(
                  {
                    active: activeTab === "5",
                  },
                  "px-3"
                )}
                onClick={() => {
                  toggleTab("5");
                }}
              >
                Issues
              </NavLink>
            </NavItem>
          </Nav>
        </CardBody>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ProfileTabOverview
            renderState={renderState}
            setRender={(e) => setRender(e)}
            profileInfos={profileInfos}
          />
        </TabPane>
        <TabPane tabId="2">
          {notifications && <Notification notifications={notifications} />}
        </TabPane>
        <TabPane tabId="3">
          {grantAccess && <GrantAccess grantAccess={grantAccess} />}
        </TabPane>
        <TabPane tabId="4">{endpoint && <Endpoint data={endpoint} />}</TabPane>
        <TabPane tabId="5">
          {issues && <Issues issues={issues} getIssues={getIssues} />}
        </TabPane>
      </TabContent>
    </>
  );
};

export default CustomerInfo;
