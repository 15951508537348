import PropTypes from "prop-types";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { del, get, post, put } from "../../helpers/api_helper";
//Import Icons
import FeatherIcon from "feather-icons-react";
import { Spinner } from "reactstrap";
//Import images
import giftBox from "../../assets/images/giftbox.png";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";

//SideBars
import AdminSidebar from "./AdminSidebar";
import CustomerSidebar from "./CustomerSidebar";
import ManagerSidebar from "./ManagerSidebar";
import ElementsSidebar from "./ElementsSidebar";
//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const propsValues = props;
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef();
  const storage = JSON.parse(localStorage.getItem("authUser"));
  const role = JSON.parse(localStorage.getItem("authUser")).role;

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // const getUser = async () => {
  //   setIsLoading(true);
  //   try {
  //     let result = await get("/account/settings");
  //     if (result.success) setUser(result.data);
  //   } catch (error) {
  //     console.log(`error ==>`, error);
  //   }
  //   setIsLoading(false);
  // };
  // useEffect(() => {
  //   getUser();
  // }, []);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            {/* <ElementsSidebar propsValues={propsValues} /> */}
            {storage.value.role === "ADMIN" && (
              <AdminSidebar propsValues={propsValues} />
            )}
            {storage.value.role === "CUSTOMER" && (
              <CustomerSidebar propsValues={propsValues} />
            )}
            {storage.value.role === "MANAGER" && (
              <ManagerSidebar propsValues={propsValues} />
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
