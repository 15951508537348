import React, { useState } from "react";
import { Collapse, Progress } from "reactstrap";
import DOMPurify from "dompurify";
import { useHistory } from "react-router-dom";
import CvesComponentExploitDetails from "./CvesComponentExploitDetails";

const CvesComponent = ({
  cves,
  subscriptions,
  tags,
  filterMyVendor,
  filterMyProduct,
  filterMyTag,
  subscription_list,
  displayChangeCves,
  buttonDisabled,
}) => {
  const [rowId, setRowId] = useState([]);
  const router = useHistory();
  const vendorKeys = new Set();
  cves?.forEach((item) => {
    Object.keys(item?.vendors).forEach((vendor) => {
      vendorKeys.add(vendor);
    });
  });

  function gatherAllValues(data) {
    const allValues = new Set();

    data.forEach((cve) => {
      const vendors = Object.values(cve.vendors);

      vendors.forEach((vendorArray) => {
        vendorArray.forEach((value) => {
          allValues.add(value);
        });
      });
    });

    return [...allValues];
  }

  const uniqueVendorKeys = Array.from(vendorKeys);
  const allProducts = gatherAllValues(cves);

  const getBrightness = (color) => {
    var match = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (!match) {
      var hex = color.replace("#", "");
      var r = parseInt(hex.substring(0, 2), 16);
      var g = parseInt(hex.substring(2, 4), 16);
      var b = parseInt(hex.substring(4, 6), 16);
      return (r * 299 + g * 587 + b * 114) / 1000;
    }
    return (
      (parseInt(match[1]) * 299 +
        parseInt(match[2]) * 587 +
        parseInt(match[3]) * 114) /
      1000
    );
  };

  const rowIdFunc = (id) => {
    let tempArray = [...rowId];
    let check = rowId.some((item) => item === id);
    if (check) {
      let filterFunc = rowId.filter((item) => item !== id);
      setRowId(filterFunc);
    } else {
      tempArray.push(id);
      setRowId(tempArray);
    }
  };

  return (
    <React.Fragment>
      {cves?.length > 0 && (
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              {cves?.map((item, index) => {
                const tarihFormati = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };
                const published = new Date(item?.created_at);
                const publishedDate = published.toLocaleDateString(
                  "en-US",
                  tarihFormati
                );
                const indexed = new Date(item?.created_at);
                const indexedDate = indexed.toLocaleDateString(
                  "en-US",
                  tarihFormati
                );
                const lastModified = new Date(item?.updated_at);
                const lastModifiedDate = lastModified.toLocaleDateString(
                  "en-US",
                  tarihFormati
                );

                let severityScore = item?.cvss?.v3
                  ? item?.cvss?.v3
                  : item?.cvss?.v2
                  ? item?.cvss?.v2
                  : false;

                return (
                  <div key={index} className="col-xxl-6  mb-3 ">
                    <div
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      className="special-card h-100 "
                    >
                      <div
                        onClick={() => {
                          router.push(
                            `/cyber-watch/threat-intelligence/cves-detail/${item?.id}`
                          );
                        }}
                        className="d-flex flex-row align-items-center justify-content-between  items-card-header "
                      >
                        <div className="d-flex flex-row align-items-center ">
                          <div className="items-card-header-icon">
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 1200.000000 1196.000000"
                              className="spidericon"
                            >
                              <g
                                transform="translate(0.000000,1196.000000) scale(0.100000,-0.100000)"
                                fill={
                                  item?.exploits?.length > 0
                                    ? "#ff9800"
                                    : "#aeaeae"
                                }
                                stroke="none"
                              >
                                <path d="M6570 11946 c0 -3 407 -418 905 -922 816 -827 904 -918 900 -942 -3 -15 -71 -596 -151 -1292 -80 -696 -148 -1268 -152 -1271 -4 -3 -447 -192 -984 -419 l-978 -413 0 -289 0 -288 596 0 c526 0 595 -2 590 -15 -3 -8 -131 -232 -284 -498 -153 -265 -284 -492 -290 -503 -11 -21 2 -29 233 -163 135 -77 249 -141 255 -141 8 0 1558 1162 1696 1271 6 5 483 -196 1205 -508 657 -284 1195 -517 1196 -518 1 0 155 -558 343 -1240 187 -682 343 -1241 345 -1244 3 -2 4 702 3 1564 l-3 1569 -1290 817 c-928 589 -1291 824 -1293 838 -2 11 -31 700 -66 1530 -35 831 -65 1513 -67 1515 -2 2 -598 347 -1324 766 -726 419 -1335 771 -1352 782 -18 11 -33 17 -33 14z" />
                                <path d="M4080 11164 c-740 -427 -1349 -780 -1354 -784 -5 -5 -37 -696 -72 -1536 -37 -895 -68 -1530 -73 -1534 -6 -3 -588 -372 -1295 -820 l-1286 -815 1 -1570 c0 -932 4 -1560 9 -1545 5 14 160 573 344 1244 185 670 337 1220 339 1223 3 2 317 139 698 304 382 165 912 395 1179 511 267 116 493 212 502 215 11 3 304 -212 855 -627 461 -348 844 -637 850 -642 9 -7 87 33 260 133 137 78 249 143 251 145 2 1 -130 232 -292 514 -163 281 -296 513 -296 516 0 2 268 4 595 4 l595 0 -2 292 -3 293 -974 412 c-536 227 -978 416 -982 420 -4 5 -75 589 -157 1298 l-149 1290 626 634 c753 761 1184 1201 1179 1201 -2 0 -609 -349 -1348 -776z" />
                                <path d="M5050 8260 c-514 -297 -936 -544 -938 -548 -2 -7 822 -363 840 -362 5 0 673 403 989 597 l57 35 521 -317 522 -316 417 177 c229 97 420 179 424 183 8 7 -1855 1085 -1881 1088 -9 1 -437 -241 -951 -537z" />
                                <path d="M3050 4748 l0 -1094 946 -547 c521 -301 948 -546 950 -544 3 2 -106 910 -110 914 0 0 -242 133 -536 294 -294 161 -535 293 -536 293 0 1 5 240 12 531 7 292 13 566 13 610 l1 80 -326 245 c-179 135 -346 260 -370 278 l-44 34 0 -1094z" />
                                <path d="M8564 5569 c-198 -149 -362 -274 -366 -277 -3 -4 0 -281 8 -616 7 -335 13 -610 11 -611 -1 -1 -238 -132 -527 -290 -289 -158 -529 -290 -533 -294 -8 -8 -120 -898 -115 -912 2 -4 427 238 946 538 l942 545 0 1094 c0 602 -2 1094 -4 1094 -2 0 -165 -122 -362 -271z" />
                                <path d="M5143 4737 c-192 -111 -243 -146 -243 -162 0 -11 58 -488 129 -1060 71 -572 128 -1041 127 -1041 0 -1 -467 -347 -1036 -769 -569 -422 -1040 -771 -1045 -776 -6 -4 -567 137 -1255 316 -684 179 -1245 324 -1246 322 -2 -2 606 -355 1352 -785 l1355 -782 1297 679 c713 373 1325 692 1359 709 l61 32 1357 -710 1357 -710 1354 781 c745 430 1353 783 1351 785 -1 2 -562 -143 -1246 -321 l-1242 -325 -1027 762 c-565 418 -1036 769 -1047 779 l-21 19 128 1027 c70 566 127 1042 128 1059 0 30 -7 35 -246 173 -136 79 -250 139 -254 134 -4 -4 -140 -237 -301 -516 -188 -326 -295 -503 -300 -495 -4 7 -138 238 -297 512 -159 275 -293 501 -298 502 -5 1 -118 -61 -251 -139z" />
                              </g>
                            </svg>
                          </div>
                          <div className="items-cards-header-title">
                            <span>{item?.id}</span>
                          </div>
                        </div>
                        {item?.cvss?.v2 && (
                          <div
                            className={`items-cards-body-score-cvss me-1  col-lg-3 col-4 ${
                              item?.cvss?.v2 <= 10 && item?.cvss?.v2 >= 7
                                ? "bg-severity-high"
                                : item?.cvss?.v2 < 7 && item?.cvss?.v2 >= 4
                                ? "bg-severity-medium"
                                : item?.cvss?.v2 < 4 && item?.cvss?.v2 >= 0
                                ? "bg-severity-low"
                                : ""
                            }`}
                          >
                            <div className="items-cards-body-score-cvss-content py-1 ">
                              <i className="mdi mdi-security font-size-16" />
                              <span className="ms-1 font-size-13">
                                CVSS V2: {item?.cvss?.v2}
                              </span>
                            </div>
                          </div>
                        )}
                        {!item?.cvss?.v2 && (
                          <div
                            className={`items-cards-body-score-cvss me-1  col-lg-3 col-4 bg-severity-low`}
                          >
                            <div className="items-cards-body-score-cvss-content py-1 ">
                              <i className="mdi mdi-security font-size-16" />
                              <span className="ms-1 font-size-13">
                                CVSS V2 : 0
                              </span>
                            </div>
                          </div>
                        )}
                        {item?.cvss?.v3 && (
                          <div
                            className={`items-cards-body-score-cvss me-3  col-lg-3 col-4 ${
                              item?.cvss?.v3 <= 10 && item?.cvss?.v3 >= 9
                                ? "bg-severity-critical"
                                : item?.cvss?.v3 < 9 && item?.cvss?.v3 >= 7
                                ? "bg-severity-high"
                                : item?.cvss?.v3 < 7 && item?.cvss?.v3 >= 4
                                ? "bg-severity-medium"
                                : item?.cvss?.v3 < 4 && item?.cvss?.v3 >= 0
                                ? "bg-severity-low"
                                : ""
                            }`}
                          >
                            <div className="items-cards-body-score-cvss-content py-1 ">
                              <i className="mdi mdi-security font-size-16" />
                              <span className="ms-1 font-size-13">
                                CVSS V3 : {item?.cvss?.v3}
                              </span>
                            </div>
                          </div>
                        )}
                        {!item?.cvss?.v3 && (
                          <div
                            className={`items-cards-body-score-cvss me-3  col-lg-3 col-4 bg-severity-empty`}
                          >
                            <div className="items-cards-body-score-cvss-content py-1 ">
                              <i className="mdi mdi-security font-size-16" />
                              <span className="ms-1 font-size-13">
                                CVSS V3 : -
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="items-cards-body col-lg-12">
                        <div className="items-cards-body-info  ">
                          <div
                            onClick={() => {
                              router.push(
                                `/cyber-watch/threat-intelligence/cves-detail/${item?.id}`
                              );
                            }}
                            className="fw-medium  "
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                item?.summary.slice(0, 160) +
                                  "..." +
                                  ` <span
                    
                        style=" cursor:pointer;"
                      >
                        Continue Reading
                      </span>`
                              ),
                            }}
                          ></div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between px-3 me-3 mt-3  gap-2  ">
                          <div className="d-flex flex-column items-cards-body-date col-4  ">
                            <span className="fw-bold text-black">
                              Published Date
                            </span>
                            <span className="font-size-13 fw-medium  ">
                              {publishedDate || "-"}
                            </span>
                          </div>
                          <div className="d-flex flex-column  items-cards-body-date col-4 ">
                            <span className="fw-bold text-black">
                              Indexed Date
                            </span>
                            <span className="font-size-13 fw-medium  ">
                              {indexedDate || "-"}
                            </span>
                          </div>
                          <div className="d-flex flex-column  items-cards-body-date col-4 ">
                            <span className="fw-bold text-black">
                              Last Modified
                            </span>
                            <span className="font-size-13 fw-medium  ">
                              {lastModifiedDate || "-"}
                            </span>
                          </div>
                        </div>
                        <div className="items-cards-body-description-cvssv3-scores-severity mt-3  mx-3 me-3 ">
                          <div className="items-cards-body-description-cvssv3-scores-severity-title col-lg-12">
                            <div
                              className="col-lg-9"
                              style={{
                                paddingLeft: "0px!important",
                                paddingRight: "0px!important",
                              }}
                            >
                              <span className="text-black fw-bold ">
                                Severity
                              </span>
                            </div>
                          </div>
                          {severityScore && (
                            <div>
                              <Progress
                                className="progress-sm "
                                color={
                                  severityScore <= 10 && severityScore >= 9
                                    ? "severity-critical"
                                    : severityScore < 9 && severityScore >= 7
                                    ? "severity-high"
                                    : severityScore < 7 && severityScore >= 4
                                    ? "severity-medium"
                                    : severityScore < 4 && severityScore >= 0
                                    ? "severity-low"
                                    : ""
                                }
                                max={10}
                                value={severityScore}
                              ></Progress>
                            </div>
                          )}
                          {!severityScore && (
                            <div>
                              <Progress
                                className="progress-sm "
                                color={"severity-empty"}
                                max={10}
                                value={10}
                              ></Progress>
                            </div>
                          )}
                        </div>
                        {item?.exploits?.length > 0 && (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => rowIdFunc(item?.id)}
                            className=" d-flex justify-content-between align-items-center  px-3"
                          >
                            <div>
                              <span className="font-size-16 text-primary fw-bold  ">
                                Exploits
                              </span>
                              <i
                                className={`mdi mdi-alert ms-1 text-primary font-size-16`}
                              />
                            </div>
                            <div className="card-tools pull-right">
                              <button
                                onClick={() => rowIdFunc(item?.id)}
                                type="button"
                                className="btn "
                              >
                                <i
                                  className={`mdi mdi-${
                                    rowId.some(
                                      (rowItem) => rowItem === item?.id
                                    )
                                      ? "minus"
                                      : "plus"
                                  } font-size-16`}
                                />
                              </button>
                            </div>
                          </div>
                        )}
                        <Collapse
                          isOpen={rowId.some((rowItem) => rowItem === item?.id)}
                        >
                          <CvesComponentExploitDetails
                            exploits={item?.exploits}
                            cvesID={item?.id}
                          />
                        </Collapse>
                      </div>{" "}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-lg-4">
            <div
              style={{ maxHeight: "700px", overflowY: "overlay" }}
              className="special-card card-primary my-2 my-lg-0 "
            >
              <div className="card-header d-flex justify-content-between align-items-center ">
                <h5 className="card-title">Subscriptions</h5>
                <div className="form-check form-switch">
                  <input
                    onChange={(e) => displayChangeCves(e.target.checked)}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={subscription_list}
                    disabled={buttonDisabled}
                  />
                </div>
                <i
                  onClick={() =>
                    router.push(
                      "/cyber-watch/threat-intelligence/subscriptions-tags"
                    )
                  }
                  style={{ cursor: "pointer" }}
                  className="mdi mdi-arrow-top-right-bold-box-outline font-size-22 text-primary "
                />
              </div>
              <div className="card-body ">
                <h5>
                  <strong>
                    My Vendors <span>({subscriptions?.vendors?.length})</span>
                  </strong>
                </h5>
                {subscriptions?.vendors?.map((vendor, vendorIndex) => (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      filterMyVendor(vendor?.name);
                    }}
                    key={vendorIndex}
                    className="badge bg-info font-size-13 me-1"
                  >
                    {vendor?.name}
                  </span>
                ))}
                {subscriptions?.vendors?.length < 1 && (
                  <div className="text-center py-2 bg-info text-white">
                    No vendor
                  </div>
                )}
                <hr />
                <h5>
                  <strong>
                    My Products <span>({subscriptions?.products?.length})</span>
                  </strong>
                </h5>
                {subscriptions?.products?.map((product, productIndex) => (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      filterMyProduct(product?.vendor_name, product?.name)
                    }
                    key={productIndex}
                    className="badge bg-primary me-1 font-size-13"
                  >
                    {product?.name}
                  </span>
                ))}
                {subscriptions?.products?.length < 1 && (
                  <div className="text-center py-2 bg-info text-white">
                    No product
                  </div>
                )}
              </div>
            </div>
            <div className="special-card card-primary my-2 ">
              <div className="card-header with-border d-flex justify-content-between  align-items-center ">
                <h5 className="card-title">Tags</h5>
                <i
                  onClick={() =>
                    router.push(
                      "/cyber-watch/threat-intelligence/subscriptions-tags"
                    )
                  }
                  style={{ cursor: "pointer" }}
                  className="mdi mdi-arrow-top-right-bold-box-outline font-size-22 text-primary"
                />
              </div>
              <div className="card-body">
                {tags?.map((tagItem, tagIndex) => (
                  <span
                    onClick={() => filterMyTag(tagItem?.name)}
                    key={tagIndex}
                    className="badge p-2 me-1 mb-1 "
                    style={{
                      color: `${
                        getBrightness(tagItem?.color) < 128 ? "white" : "black"
                      }`,
                      backgroundColor: tagItem?.color,
                      cursor: "pointer",
                    }}
                  >
                    {tagItem?.name}
                  </span>
                ))}
                {tags?.length < 1 && (
                  <div className="text-center py-2 bg-info text-white">
                    No tag
                  </div>
                )}
              </div>
            </div>
            <div
              style={{ maxHeight: "700px", overflowY: "overlay" }}
              className="special-card card-primary my-2 my-lg-0 "
            >
              <div className="card-header">
                <h5 className="card-title">Current Page</h5>
              </div>
              <div className="card-body ">
                <h5>
                  <strong>
                    Vendors <span>({uniqueVendorKeys?.length})</span>
                  </strong>
                </h5>
                {uniqueVendorKeys?.map((vendor, vendorIndex) => (
                  <span
                    key={vendorIndex}
                    className="badge bg-info font-size-13 me-1"
                  >
                    {vendor}
                  </span>
                ))}
                {uniqueVendorKeys?.length < 1 && (
                  <div className="text-center py-2 bg-info text-white">
                    No vendor
                  </div>
                )}
                <hr />
                <h5>
                  <strong>
                    Products <span>({allProducts?.length})</span>
                  </strong>
                </h5>
                {allProducts?.map((product, productIndex) => (
                  <span
                    key={productIndex}
                    className="badge bg-primary me-1 font-size-13"
                  >
                    {product}
                  </span>
                ))}
                {allProducts?.length < 1 && (
                  <div className="text-center py-2 bg-info text-white">
                    No product
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {cves?.length < 1 && <div className="">No Cves</div>}
    </React.Fragment>
  );
};

export default CvesComponent;
