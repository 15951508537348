// @flow
import { IS_ENDPOINT_REDUX } from "./actionTypes";

const INIT_STATE = {
  isEndpointRedux: false,
};

const Custom = (state = INIT_STATE, action) => {
  switch (action.type) {
    case IS_ENDPOINT_REDUX:
      return {
        ...state,
        isEndpointRedux: action.payload.isEndpointRedux,
      };

    default:
      return state;
  }
};

export default Custom;
