import React from "react";
import Hardware from "./Hardware";
import Protocol from "./Protocol";
import Product from "./Product";

const OverviewSection = (props) => {
  const { data } = props;
  return (
    <div className="row mb-3 justify-content-xxl-around  ">
      {Object.keys(data?.hardwares)?.length > 0 && (
        <div className="col-xxl-4 mb-xxl-0 mb-3 ">
          <Hardware data={data?.hardwares} />
        </div>
      )}
      {Object.keys(data?.products)?.length > 0 && (
        <div className="col-xxl-4 mb-xxl-0 mb-3 ">
          <Product data={data?.products} />
        </div>
      )}
      {Object.keys(data?.protocols)?.length > 0 && (
        <div className=" col-xxl-4  ">
          <Protocol data={data?.protocols} />
        </div>
      )}
    </div>
  );
};

export default OverviewSection;
