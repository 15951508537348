import React, { useState } from "react";
import { Modal } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { put } from "../../../../helpers/api_helper";
import { useDispatch } from "react-redux";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";

const AddGrantAccess = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const dispatch = useDispatch();

  const [email, set_email] = useState();
  const [phone_number, set_phone_number] = useState();
  const [addLoading, setAddLoading] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toastError = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const addGrantAccess = async () => {
    paceFunction(true);
    setAddLoading(true);
    let data = {
      email,
      phone_number,
    };
    if (email && phone_number) {
      if (phone_number.length < 7) {
        toastError("Phone Number must be at least 7 characters");
      } else {
        try {
          let result = await put("/customer/settings/linked-account", data);
          if (result?.success) {
            tog_standard();
            tog_standard5();
            toastSuccess(result?.message || "Grant Access Added");
            dispatch({ type: "RENDER_COMPONENT_REQ" });
          }
        } catch (error) {
          console.log(`error ==>`, error);
          errorToastFunction(error);
        }
      }
    } else {
      toastError("You must fill in all fields");
    }

    setAddLoading(false);
    paceFunction(false);
  };

  return (
    <div>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addGrantAccess}
        text="Are you sure you want to add new grant access?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        size="lg"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        {" "}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add New Grant Access
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form
            id="customer_detail_form"
            noValidate
            method="post"
            action="/update_profile/"
          >
            <div className="pb-3">
              <div className="row">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Email :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-email" />
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="email"
                      onChange={(e) => set_email(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Phone Number :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-phone" />
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="phone_number"
                      onChange={(e) => set_phone_number(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-1 mb-3" />
            <div className="d-flex justify-content-end ">
              <button
                onClick={tog_standard5}
                type="button"
                id="confirmationTooltip1"
                className="btn btn-primary waves-effect btn-label waves-light  "
              >
                <i className="mdi mdi-check-all me-3 label-icon"></i>Add
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddGrantAccess;
