import React, { useState } from "react";
import ReactJson from "react-json-view";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { post } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const PortScan = () => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, set_isLoading] = useState(false);
  const [output, set_output] = useState();

  const [host, set_host] = useState();
  const [port, set_port] = useState();
  const [protocol, set_protocol] = useState("TCP");

  const checkItFunction = async () => {
    paceFunction(true);
    set_isLoading(true);
    let data = {
      port,
      host,
      protocol,
    };
    try {
      let result = await post("/admin/utils/port-scan", data);
      if (result?.success) {
        set_output(result?.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_isLoading(false);
    paceFunction(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-4">
          <div className="input-group">
            <span className="input-group-text">Host</span>
            <input
              type="text"
              className="form-control"
              style={{ width: "80%" }}
              name="host"
              placeholder="Host"
              required
              defaultValue={host}
              onChange={(e) => set_host(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-4 my-lg-0 my-2">
          <div className="input-group">
            <span className="input-group-text">Port</span>
            <input
              type="number"
              max={65535}
              min={1}
              className="form-control"
              style={{ width: "80%" }}
              name="port"
              placeholder="Port"
              required
              defaultValue={port}
              onChange={(e) => set_port(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-group">
            <span className="input-group-text">Protocol</span>
            <select
              className="form-control"
              name="protocol"
              style={{ border: "1px solid #3fa0da" }}
              id="type"
              onChange={(e) => set_protocol(e.target.value)}
            >
              <option value="TCP">TCP</option>
              <option value="UDP">UDP</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          id="claBtn"
          className="btn btn-success waves-effect btn-label waves-light w-100 mt-2 "
          onClick={checkItFunction}
        >
          <i className="mdi mdi-check-all label-icon"></i> Check It
        </button>

        <h5 className="my-3">Result</h5>
        <div
          className="special-card2  "
          style={{
            minHeight: "400px",
            // overflowY: "overlay",
          }}
        >
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{
                  width: "3rem",
                  height: "3rem",
                }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && output && <ReactJson src={output} />}
        </div>
      </div>
    </div>
  );
};

export default PortScan;
