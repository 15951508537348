import React from "react";

const InfoNoDataComponent = ({ text, component }) => {
  return (
    <div className="special-card d-flex flex-column justify-content-between ">
      <div className="d-flex justify-content-center ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 96 96"
          width="96px"
          height="96px"
          baseProfile="basic"
        >
          <path
            fill="#0fd2ff"
            d="M48,85.503c-0.001,0-0.002,0-0.003,0C30.002,85.501,10.437,73.78,10,48.049 c0-0.034,0-0.068,0-0.102c0.258-15.108,7.645-27.297,20.267-33.441c13.33-6.489,30.023-4.994,41.543,3.721 c1.01,0.765,1.4,2.043,1.082,3.194c1.11-0.442,2.426-0.189,3.294,0.732c6.247,6.643,9.641,15.563,9.814,25.796 c0,0.034,0,0.068,0,0.102C85.56,73.783,65.994,85.503,48,85.503z M16,47.998c0.393,21.644,16.855,31.504,31.998,31.505 C63.14,79.503,79.604,69.645,80,48c-0.158-8.695-2.987-16.21-8.184-21.735c-0.745-0.792-0.976-1.882-0.706-2.857 c-0.943,0.373-2.052,0.26-2.919-0.396c-9.762-7.385-23.947-8.636-35.297-3.11C22.234,25.09,16.236,35.066,16,47.998z"
            opacity=".3"
          />
          <path
            fill="#0fd2ff"
            d="M48,84.503c-0.001,0-0.002,0-0.003,0C30.476,84.502,11.425,73.088,11,48.032 c0.251-14.786,7.433-26.653,19.704-32.627c13.001-6.327,29.277-4.874,40.502,3.619c0.881,0.666,1.055,1.921,0.389,2.802 c-0.667,0.881-1.922,1.055-2.802,0.388c-10.055-7.607-24.659-8.897-36.338-3.212C21.427,24.37,15.228,34.681,15,48.032 c0.379,22.286,17.371,32.47,32.999,32.471C63.625,80.504,80.619,70.322,81,47.966c-0.152-8.901-3.076-16.666-8.457-22.387 c-0.756-0.805-0.718-2.07,0.087-2.827c0.805-0.758,2.07-0.719,2.827,0.087c6.074,6.458,9.374,15.146,9.542,25.127 C84.572,73.091,65.521,84.503,48,84.503z"
            opacity=".3"
          />
          <path
            fill="#0fd2ff"
            d="M48,34c-1.657,0-3-1.343-3-3v-3c0-1.657,1.343-3,3-3s3,1.343,3,3v3 C51,32.657,49.657,34,48,34z"
            opacity=".3"
          />
          <path
            fill="#0fd2ff"
            d="M48,33c-1.104,0-2-0.896-2-2v-3c0-1.104,0.896-2,2-2s2,0.896,2,2v3 C50,32.104,49.104,33,48,33z"
            opacity=".3"
          />
          <path
            fill="#0fd2ff"
            d="M48,69c-1.657,0-3-1.343-3-3V42c0-1.657,1.343-3,3-3s3,1.343,3,3v24 C51,67.657,49.657,69,48,69z"
            opacity=".3"
          />
          <path
            fill="#0fd2ff"
            d="M48,68c-1.104,0-2-0.896-2-2V42c0-1.104,0.896-2,2-2s2,0.896,2,2v24 C50,67.104,49.104,68,48,68z"
            opacity=".3"
          />
          <path
            fill="#0fd2ff"
            d="M48,83.503c-0.001,0-0.002,0-0.003,0C30.95,83.502,12.413,72.396,12,48.015 c0.245-14.361,7.222-25.907,19.143-31.71c12.669-6.169,28.527-4.755,39.461,3.517c0.44,0.334,0.527,0.961,0.194,1.401 c-0.333,0.439-0.96,0.525-1.401,0.194c-10.347-7.828-25.37-9.159-37.378-3.314C20.634,23.645,14.235,34.268,14,48.015 c0.39,22.996,17.897,33.487,33.998,33.488C64.099,81.504,81.607,71.015,82,47.983c-0.156-9.188-3.174-17.185-8.728-23.089 c-0.378-0.402-0.359-1.035,0.043-1.414c0.402-0.377,1.036-0.358,1.414,0.043c5.9,6.273,9.106,14.731,9.271,24.46 C83.583,72.398,65.047,83.503,48,83.503z"
          />
          <path
            fill="#0fd2ff"
            d="M48,32c-0.552,0-1-0.447-1-1v-3c0-0.553,0.448-1,1-1s1,0.447,1,1v3C49,31.553,48.552,32,48,32z"
          />
          <path
            fill="#0fd2ff"
            d="M48,67c-0.552,0-1-0.447-1-1V42c0-0.553,0.448-1,1-1s1,0.447,1,1v24C49,66.553,48.552,67,48,67z"
          />
        </svg>
      </div>
      <h2 className="text-center my-5 ">{text}</h2>
      <div className="d-flex justify-content-center ">
        <div className={`bg-images-${component}`} />
      </div>
    </div>
  );
};

export default InfoNoDataComponent;
