import React, { useEffect, useState } from "react";
import { get, post } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ManagerCustomersTable from "../../../components/Manager/Customers/ManagerCustomersTable";
import {
  Form,
  FormFeedback,
  Col,
  Container,
  Row,
  Input,
  Label,
  Modal,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const Customers = () => {
  document.title = "Customers | PentestBX";

  const { errorFunctionNotSwal } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [entryValue, setEntryValue] = React.useState(10);
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);
  const [renderLocal, setRenderLocal] = useState(false);

  const [isEndpoint, setIsEndpoint] = useState(false);
  const [remote_db, set_remote_db] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [addLoading, setAddLoading] = useState(false);

  const { render } = useSelector((state) => ({
    render: state.render.render,
  }));

  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCustomers = async () => {
    paceFunction(true);
    try {
      let result = await get("/manager/customer");
      if (result?.success) {
        setData(result?.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunctionNotSwal(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getCustomers();
  }, [renderLocal, render]);

  const toastError = (msg) =>
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      phone_number: "",
      company_name: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      phone_number: Yup.string().required("Please Enter Your Phone Number"),
      company_name: Yup.string().required(
        "Please Enter Your Organization Name"
      ),
    }),
    onSubmit: (values) => {
      let data = {
        ...values,
        is_active: isActive,
        is_endpoint: isEndpoint,
        is_demo: isDemo,
        remote_db: isEndpoint ? remote_db : false,
      };
      addNewCustomer(data);
    },
  });

  const addNewCustomer = async (data) => {
    paceFunction(true);
    setAddLoading(true);
    try {
      let result = await post("/manager/customer", data);
      if (result?.success) {
        toastSuccess(result?.message || "Customer Added...");
        setRenderLocal(!renderLocal);
        tog_standard();
        tog_standard3();
      }
    } catch (error) {
      toastError(error?.response?.data?.message);
      console.log("Error ==>", error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_standard3() {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="special-card">
            <Row>
              <Col
                className="d-flex justify-content-start align-items-center "
                xs={10}
              >
                <h4 className="text-primary">Customers</h4>
              </Col>
              <Col xs={2} className="d-flex justify-content-end">
                <div>
                  <button
                    data-toggle="modal"
                    data-target="#myModal"
                    onClick={() => {
                      tog_standard();
                    }}
                    type="button"
                    className="btn btn-soft-primary"
                  >
                    <i className="mdi mdi-account-plus-outline me-1"></i> Add
                    New
                  </button>
                  <Modal
                    isOpen={modal_standard}
                    toggle={() => {
                      tog_standard();
                    }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myModalLabel">
                        New Customer
                      </h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-xl-6 col-md-6">
                            <div className="form-floating mb-2">
                              <Input
                                type="text"
                                name="company_name"
                                className="form-control"
                                autoComplete="off"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.company_name || ""}
                                invalid={
                                  validation.touched.company_name &&
                                  validation.errors.company_name
                                    ? true
                                    : false
                                }
                              />
                              <label>Company Name</label>
                              {validation.touched.company_name &&
                              validation.errors.company_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.company_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6">
                            <div className="form-floating mb-2">
                              <Input
                                type="email"
                                name="email"
                                className="form-control"
                                // required
                                autoComplete="off"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              <label>E-mail Address</label>
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6">
                            <div className="form-floating mb-2">
                              <Input
                                type="text"
                                name="phone_number"
                                className="form-control"
                                // required
                                autoComplete="off"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone_number || ""}
                                invalid={
                                  validation.touched.phone_number &&
                                  validation.errors.phone_number
                                    ? true
                                    : false
                                }
                              />
                              <label>Phone Number</label>
                              {validation.touched.phone_number &&
                              validation.errors.phone_number ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.phone_number}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6">
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">Active</label>
                                <Input
                                  defaultValue={isActive}
                                  type="checkbox"
                                  id="switch1"
                                  switch="primary"
                                  defaultChecked={isActive}
                                  onChange={(e) =>
                                    setIsActive(e.target.checked)
                                  }
                                />
                                <Label
                                  htmlFor="switch1"
                                  data-on-label="Yes"
                                  data-off-label="No"
                                ></Label>
                              </div>
                              <div className="col-md-4">
                                <label className="form-label">Endpoint</label>
                                <Input
                                  defaultValue={isEndpoint}
                                  type="checkbox"
                                  id="switch2"
                                  switch="primary"
                                  defaultChecked={isEndpoint}
                                  onChange={(e) =>
                                    setIsEndpoint(e.target.checked)
                                  }
                                />
                                <Label
                                  htmlFor="switch2"
                                  data-on-label="Yes"
                                  data-off-label="No"
                                ></Label>
                              </div>
                              <div className="col-md-4">
                                <label className="form-label">Demo</label>
                                <Input
                                  defaultValue={isDemo}
                                  type="checkbox"
                                  id="switch6"
                                  switch="primary"
                                  defaultChecked={isDemo}
                                  onChange={(e) => setIsDemo(e.target.checked)}
                                />
                                <Label
                                  htmlFor="switch6"
                                  data-on-label="Yes"
                                  data-off-label="No"
                                ></Label>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6" />
                          <div className="col-xl-6 col-md-6">
                            <div className="row">
                              <div className="col-md-2" />
                              <AnimatePresence>
                                {isEndpoint && (
                                  <motion.div
                                    className="col-md-8"
                                    id="dbLocationContainer"
                                    initial={{ y: -50, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: 0, opacity: 0 }}
                                    transition={{ duration: 0.4 }}
                                  >
                                    <label className="form-label">
                                      Database Location
                                    </label>
                                    <div className="form-floating mb-2">
                                      <div className="btn-group" role="group">
                                        <input
                                          type="radio"
                                          className="btn-check"
                                          name="db_location"
                                          defaultValue="CLOUD"
                                          id="db_cloudchk"
                                          autoComplete="off"
                                          defaultChecked={!remote_db}
                                          onChange={(e) => set_remote_db(false)}
                                        />
                                        <label
                                          className="btn btn-outline-secondary"
                                          htmlFor="db_cloudchk"
                                        >
                                          Cloud
                                        </label>
                                        <input
                                          type="radio"
                                          className="btn-check"
                                          name="db_location"
                                          defaultValue="REMOTE"
                                          id="db_epchk"
                                          autoComplete="off"
                                          defaultChecked={remote_db}
                                          onChange={(e) => set_remote_db(true)}
                                        />
                                        <label
                                          className="btn btn-outline-secondary"
                                          htmlFor="db_epchk"
                                        >
                                          Endpoint
                                        </label>
                                      </div>
                                    </div>
                                  </motion.div>
                                )}
                              </AnimatePresence>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => {
                            tog_standard();
                          }}
                          className="btn btn-secondary "
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          onClick={tog_standard3}
                          type="button"
                          className="btn btn-primary "
                        >
                          Add Customer
                        </button>
                        <Modal
                          isOpen={modal_standard3}
                          toggle={() => {
                            tog_standard3();
                          }}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                              PentestBX asks
                            </h5>
                            <button
                              type="button"
                              onClick={() => {
                                setmodal_standard3(false);
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <h5>Are you sure you want to add new customer?</h5>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              onClick={() => {
                                tog_standard3();
                              }}
                              className="btn btn-secondary "
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                            {addLoading ? (
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                disabled
                              >
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                Yes
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  validation.handleSubmit();
                                }}
                                type="submit"
                                className="btn btn-primary "
                              >
                                Yes
                              </button>
                            )}
                          </div>
                        </Modal>
                      </div>
                    </Form>
                  </Modal>
                </div>
              </Col>
            </Row>
            {isLoading && (
              <div className="d-flex justify-content-center  align-items-center h-100  ">
                <Spinner
                  style={{ width: "3rem", height: "3rem" }}
                  className="ms-2 "
                  color="primary"
                />
              </div>
            )}
            {!isLoading && data && (
              <div
                style={{
                  overflowX: windowWidth < 800 ? "scroll" : "none",
                }}
              >
                <ManagerCustomersTable
                  data={data}
                  entryValue={entryValue}
                  changeValue={(e) => changeValue(e)}
                />
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Customers;
