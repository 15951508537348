export const formatDateTransform = (formatDate) => {
  if (formatDate === "%Y-%m-%d") return "yyyy-MM-dd";
  if (formatDate === "%d-%m-%Y") return "dd-MM-yyyy";
  if (formatDate === "%m-%d-%Y") return "MM-dd-yyyy";
  if (formatDate === "%d/%m/%Y") return "dd/MM/yyyy";
  if (formatDate === "%m/%d/%Y") return "MM/dd/yyyy";
  if (formatDate === "%d %b %Y") return "dd MMM yyyy";
  if (formatDate === "%d-%b-%Y") return "dd-MMM-yyyy";
  if (formatDate === "%b %d %Y") return "MMM dd yyyy";
  if (formatDate === "%b-%d-%Y") return "MMM-dd-yyyy";
};
