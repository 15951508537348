import React, { useEffect, useState } from "react";
//redux
import { useSelector, useDispatch } from "react-redux";
// Redux Store
import { changePreloader } from "../store/actions";
const paceFunctionComponent = () => {
  const dispatch = useDispatch();
  const paceFunction = (data) => {
    dispatch(changePreloader(data));
  };
  return { paceFunction };
};

export default paceFunctionComponent;
