import React from "react";
import SeveritySection from "./SeveritySection";
import ScoreSection from "./ScoreSection";

const DashboardComponent = ({ data }) => {
  return (
    <div>
      {data && <ScoreSection data={data} />}

      {data?.weekly_overview?.length > 0 && <SeveritySection data={data} />}
    </div>
  );
};

export default DashboardComponent;
