import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { formatDateTransform } from "../../../common/utils/commonFunctions";
const ScoreSection = ({ data }) => {
  const [viewDetails, set_viewDetails] = useState(false);

  const findMaxScore = (dataArray) => {
    if (dataArray?.length === 0) {
      return null;
    }
    let maxScoreItem = dataArray[0];
    for (let i = 1; i < dataArray?.length; i++) {
      if (dataArray[i].score > maxScoreItem?.score) {
        maxScoreItem = dataArray[i];
      }
    }
    return maxScoreItem.score;
  };

  let filteredScores = data?.scores.filter((item) => item.score !== null);
  const maxScore = findMaxScore(filteredScores);
  let scoreArray = [];
  let lastScoreArray = [];
  let scoreDates = [];
  let filteredWeeklyOverview = data?.weekly_overview?.filter(
    (item) => item.score !== null
  );
  if (filteredWeeklyOverview?.length > 0) {
    scoreArray = filteredWeeklyOverview?.map((item) => item?.score);
    scoreDates = filteredWeeklyOverview?.map((item) => item?.week);
  }
  if (scoreArray.length > 0) {
    lastScoreArray = scoreArray.map((item) => {
      if (item >= 0 && item <= 0.8) {
        return 0;
      } else if (item > 0.8 && item <= 1.5) {
        return 1;
      } else if (item > 1.5 && item <= 2.4) {
        return 2;
      } else if (item > 2.4 && item <= 3.5) {
        return 3;
      } else {
        return 4;
      }
    });
  }

  const options = {
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: "Score",
        data: lastScoreArray,
      },
    ],
    colors: ["red"],
    fill: {
      type: "solid",
      colors: ["transparent"],
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: formatDateTransform(data?.date_format),
        datetimeUTC: false,
      },
      categories: scoreDates,
    },
    yaxis: {
      // reversed: true,
      labels: {
        formatter: function (value) {
          const yLabels = ["A", "B", "C", "D", "E"];
          if (value >= 0 && value <= 0.8) {
            return yLabels[0];
          } else if (value > 0.8 && value <= 1.5) {
            return yLabels[1];
          } else if (value > 1.5 && value <= 2.4) {
            return yLabels[2];
          } else if (value > 2.4 && value <= 3.5) {
            return yLabels[3];
          } else {
            return yLabels[4];
          }
        },
      },
      min: 0,
      max: 4,
      tickAmount: 4,
    },

    stroke: {
      curve: "smooth",
    },
    tooltip: {
      x: {
        format: `${formatDateTransform(data?.date_format)} HH:mm`,
      },
    },
  };

  const series = options.series;

  return (
    <div className="row">
      {filteredWeeklyOverview.length > 0 && (
        <div className="col-xxl-8  col-lg-6 mb-4 ">
          <div className="special-card">
            <div className="d-flex  py-2  align-items-center justify-content-between  ">
              <div>
                <span className="fw-bold fs-4 me-1 ">Score Trends </span>
              </div>
            </div>
            <div>
              <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={230}
              />
            </div>
          </div>
        </div>
      )}
      {data?.scores?.length > 0 && (
        <div className="col-xxl-4  col-lg-6 mb-4  ">
          <div className="special-score-card">
            <div className="d-flex  py-2  align-items-center justify-content-between  ">
              <div>
                <span className="fw-bold fs-4 me-1 ">Score </span>
              </div>
            </div>
            <div
              onMouseEnter={() => set_viewDetails(true)}
              onMouseLeave={() => set_viewDetails(false)}
            >
              {viewDetails ? (
                <div className=" row-bs row row-bs">
                  {data?.scores?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        height: "265px",
                      }}
                      className={`col row-bs bg-hexagon-${
                        item?.score == null
                          ? "g"
                          : item?.score <= 0.8
                          ? "a"
                          : item?.score <= 1.5
                          ? "b"
                          : item?.score <= 2.4
                          ? "c"
                          : item?.score <= 3.5
                          ? "d"
                          : "e"
                      } d-flex flex-column   align-items-center justify-content-around relative `}
                    >
                      <span
                        style={{ height: "42px" }}
                        className="font-size-14 fw-semibold  text-white  text-center absolute top-0  "
                      >
                        {item?.title}
                      </span>
                      <div>
                        <div className="d-flex align-items-center  justify-content-center  ">
                          <div className="hexagon-small   ">
                            <span className="text-uppercase ">
                              {item?.score == null
                                ? ""
                                : item?.score <= 0.8
                                ? "a"
                                : item?.score <= 1.5
                                ? "b"
                                : item?.score <= 2.4
                                ? "c"
                                : item?.score <= 3.5
                                ? "d"
                                : "e"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    height: "265px",
                  }}
                  className={`row-bs row row-bs bg-hexagon-${
                    maxScore == null
                      ? "g"
                      : maxScore <= 0.8
                      ? "a"
                      : maxScore <= 1.5
                      ? "b"
                      : maxScore <= 2.4
                      ? "c"
                      : maxScore <= 3.5
                      ? "d"
                      : "e"
                  } pb-5  pt-2 align-items-center justify-content-center `}
                >
                  <div className="hexagon   mt-5 ">
                    <span className="">
                      {maxScore == null
                        ? ""
                        : maxScore <= 0.8
                        ? "A"
                        : maxScore <= 1.5
                        ? "B"
                        : maxScore <= 2.4
                        ? "C"
                        : maxScore <= 3.5
                        ? "D"
                        : "E"}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScoreSection;
