import React from "react";
import { Progress } from "reactstrap";

const Protocol = ({ data }) => {
  const randomHexCode = () => {
    const randomColor = Math.floor(Math.random() * 16777215)
      .toString(16)
      .toUpperCase();
    return `#${randomColor.padStart(6, "0")}`;
  };

  const colors = [];

  for (let i = 0; i < 200; i++) {
    const hexKodu = randomHexCode();
    colors.push(hexKodu);
  }

  const findMaxValue = (data) => {
    let maxValue = 0;

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const value = data[key];
        if (value > maxValue) {
          maxValue = value;
        }
      }
    }

    return maxValue;
  };

  const maxValue = findMaxValue(data);

  const calculateTotal = (data) => {
    let total = 0;

    // Verilen objenin her bir değerini toplamaya ekle
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        total += data[key];
      }
    }

    return total;
  };

  const total = calculateTotal(data);

  const calculatePercentage = (num1, num2) => {
    const percentage = (num1 / num2) * 100;
    return percentage.toFixed(1);
  };

  return (
    <div
      style={{ maxHeight: "350px", overflowY: "overlay", overflowX: "hidden" }}
      className="special-card d-flex flex-column px-0 h-100 "
    >
      <div className="d-flex justify-content-start align-items-center border-bottom ps-3 ">
        <h5>Protocols </h5>
      </div>
      <div className="row mt-3 ">
        <div className="row ps-4">
          <div className="col-6">
            <span className="font-size-14 fw-semibold my-2 ps-2 ms-1  ">
              Protocol
            </span>
          </div>
          <div className="col-1">
            <span className="font-size-14 fw-semibold my-2 me-3  ">%</span>
          </div>
          <div className="col-5">
            <span className="font-size-14 fw-semibold my-2  "></span>
          </div>
        </div>
        <div className="row ps-4">
          <div className="col-12">
            <div className="d-flex flex-column ">
              {Object.entries(data)?.map(([name, count], index) => {
                const widthPercentage = (count / maxValue) * 100;
                const percentage = calculatePercentage(count, total);
                return (
                  <div key={name} className="row align-items-center mb-1 ">
                    <div className="col-6">
                      <div className="d-flex flex-row align-items-center ">
                        <div
                          style={{
                            width: "5px",
                            height: "5px",
                            backgroundColor: colors[index],
                          }}
                          className="rounded "
                        />
                        <span className="font-size-13 fw-medium ms-2  ">
                          {name}
                        </span>
                      </div>
                    </div>
                    <div className="col-1">
                      {" "}
                      <span
                        key={name + "a"}
                        className="font-size-13 fw-medium  me-3  mb-2 "
                      >
                        {percentage}
                      </span>
                    </div>
                    <div className="col-5">
                      <div
                        // style={{ marginBottom: "15px" }}
                        className="d-flex align-items-center "
                        key={name + "s"}
                      >
                        <div className="my-progress-bar col-12">
                          <div
                            className="my-progress-bar-inner"
                            style={{
                              width: `${widthPercentage}%`,
                              backgroundColor: `${colors[index]}`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Protocol);
