import React, { useState } from "react";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import { toast } from "react-toastify";
import { del } from "../../../helpers/api_helper";
import IssuesDetail from "./Issues/IssuesDetail";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const Issues = ({ issues, getIssues }) => {
  const sortedData = issues.sort((a, b) => {
    return new Date(b.updated) - new Date(a.updated);
  });
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [issueDetail, set_issueDetail] = useState();
  const [issueID, set_issueID] = useState();

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const toastWarning = (msg) =>
    toast.warning(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const deleteIssue = async () => {
    paceFunction(true);
    setAddLoading(true);
    try {
      let result = await del(`/account/issue?id=${issueID}`);
      if (result?.success) {
        toastSuccess(result?.message || "Issue Deleted");
        tog_standard5();
        getIssues();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {issueDetail && (
        <IssuesDetail
          issueDetail={issueDetail}
          issueID={issueID}
          getIssues={getIssues}
          modal_standard={modal_standard2}
          tog_standard={tog_standard2}
          setmodal_standard={setmodal_standard2}
        />
      )}
      {/* Delete Issue */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={deleteIssue}
        text="Are you sure you want to delete issue?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <div
        style={{ maxHeight: "500px", overflowY: "overlay" }}
        className="special-card mt-5"
      >
        <table className="table table-borderless table-hover table-centered m-0">
          <thead className="">
            <tr>
              <th>Subject</th>
              <th>Type</th>
              <th>Status</th>
              <th>Created Date</th>
              <th>Updated Date</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedData?.map((item, index) => (
              <tr key={index}>
                <td>{item?.subject}</td>
                <td>{item?.type}</td>
                <td>
                  {item?.status === "OPENED" ? (
                    <span className="badge bg-success">{item?.status}</span>
                  ) : item?.status === "CLOSED" ? (
                    <span className="badge bg-danger">{item?.status}</span>
                  ) : (
                    <span className="badge bg-info">WORK IN PROGRESS</span>
                  )}
                </td>
                <td>{item?.created}</td>
                <td>{item?.updated}</td>
                <td>
                  <button
                    onClick={() => {
                      set_issueDetail(item);
                      tog_standard2();
                      set_issueID(item?.id);
                    }}
                    className="btn btn-success btn-sm "
                  >
                    Detail
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => {
                      if (item?.status === "WIP") {
                        toastWarning(
                          "You can't delete the issue because its status is work in progress."
                        );
                      } else {
                        set_issueID(item?.id);
                        tog_standard5();
                      }
                    }}
                    className="btn btn-danger btn-sm"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default Issues;
