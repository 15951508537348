import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDEditor from "@uiw/react-md-editor/nohighlight";
import { del, get, post, put, patch } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";

const CustomerNotes = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  company_name,
  customerNotes,
  userId,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [modal_standard2, setmodal_standard2] = useState(false);
  const [value, setValue] = useState(customerNotes?.note);

  const createdDate = new Date(customerNotes?.created_date);
  const lastCreatedDate = new Intl.DateTimeFormat("en-EN", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(createdDate);

  const updatedDate = new Date(customerNotes?.updated_date);
  const lastUpdatedDate = new Intl.DateTimeFormat("en-EN", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(updatedDate);

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const updateCustomerNotes = async () => {
    paceFunction(true);
    let data;
    if (value === "") {
      data = {
        id: userId,
      };
    } else {
      data = {
        id: userId,
        note: value,
      };
    }
    try {
      let result = await patch(`/admin/customer/notes`, data);
      if (result?.success) {
        toastSuccess(result?.message || "Notes Saved");
        tog_standard2();
      }
    } catch (error) {
      errorToastFunction(error);
      console.log(`error ==>`, error);
    }
    paceFunction(false);
  };

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  useEffect(() => {
    if (modal_standard) {
      setValue(customerNotes?.note);
    }
  }, [modal_standard]);

  return (
    <React.Fragment>
      {/* Update Notes */}
      <ConfirmModal
        modal_standard={modal_standard2}
        tog_standard={tog_standard2}
        setmodal_standard={setmodal_standard2}
        targetFunction={updateCustomerNotes}
        text="Are you sure you want to save notes?"
        loadingAction={false}
      />
      {/* Main Modal */}
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {company_name} Notes
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center mb-3 fs-5 fw-bold    ">
            <div title="Create Date">
              <i className="mdi mdi-creation text-primary fs-4 me-1 " />
              <span>{lastCreatedDate}</span>
            </div>
            <div title="Update Date">
              <i className="mdi mdi-update text-primary fs-4 me-1   " />
              <span>{lastUpdatedDate}</span>
            </div>
          </div>
          <MDEditor value={value} onChange={setValue} />
          {/* <MDEditor.Markdown
              source={value}
              style={{ whiteSpace: "pre-wrap" }}
            /> */}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary "
            data-dismiss="modal"
            onClick={tog_standard2}
          >
            Save
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CustomerNotes;
