import React from "react";
import Completed from "./Completed/Completed";
import Tasks from "./Tasks/Tasks";
import Current from "./Current/Current";

const TasksComponent = ({
  tasks,
  userID,
  renderGetTask,
  set_renderGetTask,
}) => {
  return (
    <div className="row gap-3 ">
      {tasks?.tasks?.length > 0 && (
        <div className="col-12">
          <Tasks datas={tasks?.tasks} />
        </div>
      )}
      {tasks?.queue?.length > 0 && (
        <div className="col-12">
          <Current
            datas={tasks?.queue}
            renderGetTask={renderGetTask}
            set_renderGetTask={(e) => set_renderGetTask(e)}
            userID={userID}
          />
        </div>
      )}
      {tasks?.completed?.length > 0 && (
        <div className="col-12">
          <Completed datas={tasks?.completed} />
        </div>
      )}
      {tasks?.completed?.length < 1 &&
        tasks?.queue?.length < 1 &&
        tasks?.tasks?.length < 1 && (
          <div className="d-flex justify-content-center ">
            There is no task.
          </div>
        )}
    </div>
  );
};

export default TasksComponent;
