import React, { useEffect, useRef, useState } from "react";
import { CardBody, CardHeader, Badge, Spinner } from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";
import Vulnerabilities from "./Vulnerabilities";
import { post } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";

const BottomSection = ({
  assets,
  updated_at,
  groupList,
  getAssetPortFunc,
  moduleID,
  setSelectDisabled,
}) => {
  const { errorFunctionNotSwal } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isServiceLoading, setIsServiceLoading] = useState(false);
  // Hosts
  const [hosts, setHosts] = useState(assets);
  const [allHosts, set_allHosts] = useState(assets);
  const [filteredHosts, set_filteredHosts] = useState([]);
  const [allfilteredHosts, set_allfilteredHosts] = useState([]);
  // Services
  const [filteredServices, set_filteredServices] = useState([]);
  const [allfilteredServices, set_allfilteredServices] = useState([]);
  const [services, setServices] = useState([]);
  const [tempServices, setTempServices] = useState([]);

  const [groupArray, setGroupArray] = useState([]);
  const [allGroupArray, setAllGroupArray] = useState([]);
  // Filter Inputs
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenFilterInput, setIsDropdownOpenFilterInput] =
    useState(false);
  const [isDropdownOpenServices, setIsDropdownOpenServices] = useState(false);
  const [searchInputValue, set_searchInputValue] = useState("");
  const [searchInputValueFilterInput, set_searchInputValueFilterInput] =
    useState("");
  const [searchServicesInputValue, set_searchServicesInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFilterInput, setIsOpenFilterInput] = useState(false);
  const [isOpenServices, setIsOpenServices] = useState(false);
  // Input Refs
  const inputRef = useRef(null);
  const inputRefFilterInput = useRef(null);
  const inputRefServices = useRef(null);
  const dropdownRef = useRef(null);
  const dropdownRefFilterInput = useRef(null);
  const dropdownRefServices = useRef(null);

  // Search Filter Input
  const [allFilterInput, set_allFilterInput] = useState();
  const [filterInput, set_filterInput] = useState();
  const [addressFilter, set_addressFilter] = useState("all");
  const [groupFilter, set_groupFilter] = useState([]);

  const findMissingAssets = (assets, hosts) => {
    const hostAddresses = hosts?.map((host) => host?.address);

    const missingAssets = assets?.filter(
      (asset) => !hostAddresses.includes(asset?.address)
    );

    return missingAssets;
  };

  const findMissingAssetsServices = (assets, hosts) => {
    const hostAddresses = hosts?.map((host) => host?.port);

    const missingAssets = assets?.filter(
      (asset) => !hostAddresses.includes(asset?.port)
    );

    return missingAssets;
  };

  useEffect(() => {
    const missingAssets = findMissingAssets(allHosts, hosts);
    set_filteredHosts(missingAssets);
    set_allfilteredHosts(missingAssets);
  }, [hosts]);

  useEffect(() => {
    const missingAssets = findMissingAssetsServices(services, tempServices);
    set_filteredServices(missingAssets);
    set_allfilteredServices(missingAssets);
  }, [tempServices]);

  useEffect(() => {
    setIsServiceLoading(true);
    getServices();
  }, [updated_at, hosts]);

  useEffect(() => {
    getAssetPortFunc(hosts, tempServices);
  }, [tempServices, hosts]);

  useEffect(() => {
    let tempFilterInput = [
      { type: "address", name: "all", id: "all" },
      { type: "address", name: "private", id: "private" },
      { type: "address", name: "public", id: "public" },
      { type: "group", name: "all", id: "all-group" },
    ];
    const tempGroupArray = groupList?.map((item) => item?.id);
    const tempFilterGroupArray = groupList?.map((item) => ({
      type: "group",
      name: item?.name,
      id: item?.id,
    }));
    setGroupArray(tempGroupArray);
    set_groupFilter(tempFilterGroupArray);
    setAllGroupArray(tempFilterGroupArray);
    let last = tempFilterInput.concat(tempFilterGroupArray);
    set_allFilterInput(tempFilterInput);
    set_filterInput(tempFilterInput);
  }, []);

  const handleInputFocus = () => {
    if (!isDropdownOpen && !isOpen) {
      setIsDropdownOpen(true);
      setIsOpen(true);
    }
  };

  const handleInputBlur = () => {
    if (isDropdownOpen && isOpen) {
      setTimeout(() => {
        setIsDropdownOpen(false);
        setIsOpen(false);
        set_searchInputValue("");
      }, 100);
    }
  };

  const handleInputFocusFilterInput = () => {
    if (!isDropdownOpenFilterInput && !isOpenFilterInput) {
      setIsDropdownOpenFilterInput(true);
      setIsOpenFilterInput(true);
    }
  };

  const handleInputBlurFilterInput = () => {
    if (isDropdownOpenFilterInput && isOpenFilterInput) {
      setTimeout(() => {
        setIsDropdownOpenFilterInput(false);
        setIsOpenFilterInput(false);
        set_searchInputValueFilterInput("");
      }, 100);
    }
  };

  const handleInputFocusServices = () => {
    if (!isDropdownOpenServices && !isOpenServices) {
      setIsDropdownOpenServices(true);
      setIsOpenServices(true);
    }
  };

  const handleInputBlurServices = () => {
    if (isDropdownOpenServices && isOpenServices) {
      setTimeout(() => {
        setIsDropdownOpenServices(false);
        setIsOpenServices(false);
        set_searchServicesInputValue("");
      }, 100);
    }
  };

  const handleOutsideClick = (event) => {
    if (!event.target.classList.contains("exclude-span")) {
      inputRef.current.focus();
    }
  };

  const handleOutsideClickFilterInput = (event) => {
    if (!event.target.classList.contains("exclude-span-filter")) {
      inputRefFilterInput.current.focus();
    }
  };

  const handleOutsideClickServices = (event) => {
    if (!event.target.classList.contains("exclude-span-services")) {
      inputRefServices.current.focus();
    }
  };

  const handleRemoveAddress = async (tag, index) => {
    const updatedTags = [...hosts];
    updatedTags.splice(index, 1);
    setHosts(updatedTags);
  };

  const handleRemoveService = async (tag, index) => {
    const updatedTags = [...tempServices];
    updatedTags.splice(index, 1);
    setTempServices(updatedTags);
  };

  const groupArrayFunc = (value) => {
    let dizi = groupArray;
    const index = groupArray.indexOf(Number(value));
    if (index !== -1) {
      dizi.splice(index, 1);
      setGroupArray(dizi);
    } else {
      dizi = [...dizi, Number(value)];
      setGroupArray(dizi);
    }
    let lastArray = [];
    assets.forEach((item) => {
      if (dizi.includes(item.group_id)) {
        lastArray.push(item);
      }
    });
    if (addressFilter === "all") {
      setHosts(lastArray);
      set_allHosts(lastArray);
    } else {
      let is_address_private = addressFilter === "private" ? true : false;
      let filteredLastArray = lastArray.filter(
        (item) => item?.is_private === is_address_private
      );
      setHosts(filteredLastArray);
      set_allHosts(filteredLastArray);
    }
  };

  const addHostAddress = (item) => {
    let tempArray = [...hosts, item];
    setHosts(tempArray);
    set_searchInputValue("");
  };

  const addServicesPort = (item) => {
    let tempArray = [...tempServices, item];
    setTempServices(tempArray);
    set_searchServicesInputValue("");
  };

  const filterAddressesByNumber = (number) => {
    const filteredAddresses = allfilteredHosts?.filter((address) => {
      return address?.address?.includes(number.toString());
    });

    return filteredAddresses;
  };

  const filterPortsByNumber = (number) => {
    const filteredAddresses = allfilteredServices?.filter((address) => {
      return address?.port?.toString().includes(number.toString());
    });

    return filteredAddresses;
  };

  const filterFilterInput = (str) => {
    const filteredData = allFilterInput?.filter((address) => {
      return address?.name
        ?.toLowerCase()
        ?.toString()
        .includes(str.toLowerCase().toString());
    });

    return filteredData;
  };

  const handleChangeSearch = (e) => {
    set_searchInputValue(e.target.value);
    let result = filterAddressesByNumber(e.target.value);
    set_filteredHosts(result);
  };

  const handleChangeSearchFilterInput = (e) => {
    set_searchInputValueFilterInput(e.target.value);
    let result = filterFilterInput(e.target.value);
    set_filterInput(result);
  };

  const handleChangeSearchServices = (e) => {
    set_searchServicesInputValue(e.target.value);
    let result = filterPortsByNumber(e.target.value);
    set_filteredServices(result);
  };

  const filterInputFunction = (item) => {
    if (item?.type === "address") {
      let dizi = groupArray;
      let lastArray = [];
      assets.forEach((item) => {
        if (dizi.includes(item.group_id)) {
          lastArray.push(item);
        }
      });
      if (item?.name === "all") {
        setHosts(lastArray);
        set_allHosts(lastArray);
      } else {
        let is_address_private = item?.name === "private" ? true : false;
        let filteredLastArray = lastArray.filter(
          (item) => item?.is_private === is_address_private
        );
        setHosts(filteredLastArray);
        set_allHosts(filteredLastArray);
      }
      set_addressFilter(item?.name);
      set_filterInput(allFilterInput);
    } else {
      if (item?.name === "all") {
        let groupsIds = allGroupArray.map((group) => group?.id);
        setGroupArray(groupsIds);
        set_groupFilter(allGroupArray);
        set_allFilterInput([
          { type: "address", name: "all", id: "all" },
          { type: "address", name: "private", id: "private" },
          { type: "address", name: "public", id: "public" },
          { type: "group", name: "all", id: "all-group" },
        ]);
        set_filterInput([
          { type: "address", name: "all", id: "all" },
          { type: "address", name: "private", id: "private" },
          { type: "address", name: "public", id: "public" },
          { type: "group", name: "all", id: "all-group" },
        ]);
        let dizi = groupsIds;
        let lastArray = [];
        assets.forEach((item) => {
          if (dizi.includes(item.group_id)) {
            lastArray.push(item);
          }
        });
        if (addressFilter === "all") {
          setHosts(lastArray);
          set_allHosts(lastArray);
        } else {
          let is_address_private = addressFilter === "private" ? true : false;
          let filteredLastArray = lastArray.filter(
            (item) => item?.is_private === is_address_private
          );
          setHosts(filteredLastArray);
          set_allHosts(filteredLastArray);
        }
      } else {
        let hasAllInputFilter = allFilterInput.some(
          (allInput) => allInput.id === item.id
        );
        if (hasAllInputFilter) {
          let filteredAllInput = allFilterInput.filter(
            (allInput) => allInput?.id !== item?.id
          );
          set_allFilterInput(filteredAllInput);
          set_filterInput(filteredAllInput);
        } else {
          let tempAllFilterInput = [...allFilterInput, item];
          set_allFilterInput(tempAllFilterInput);
          set_filterInput(tempAllFilterInput);
        }
        let hasGroupFilter = groupFilter.some(
          (group) => group?.id === item?.id
        );
        if (hasGroupFilter) {
          let filteredGroup = groupFilter.filter(
            (group) => group?.id !== item?.id
          );
          set_groupFilter(filteredGroup);
        } else {
          let tempGroupFilter = [...groupFilter, item];
          set_groupFilter(tempGroupFilter);
        }
        groupArrayFunc(item.id);
      }
    }
    set_searchInputValueFilterInput("");
  };

  const getServices = async () => {
    paceFunction(true);
    setSelectDisabled(true);
    let serviceData = {
      date: updated_at,
      id: hosts.map((item) => item.id),
    };
    try {
      let result = await post(`/customer/module/host/services`, serviceData);
      if (result?.success) {
        const uniquePorts = [];
        result?.data?.forEach((portData) => {
          const existingPort = uniquePorts?.find(
            (port) => port?.port === portData?.port
          );
          if (!existingPort) {
            uniquePorts.push(portData);
          }
        });
        setServices(uniquePorts);
        setTempServices(uniquePorts);
        setIsServiceLoading(false);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunctionNotSwal(error);
    }
    setSelectDisabled(false);
    paceFunction(false);
  };

  let previous;

  return (
    <>
      <div className="special-card">
        <CardHeader>
          <h4 className="card-title mb-0 fs-2 text-primary  ">Report </h4>
        </CardHeader>
        <CardBody>
          <div className="row">
            <h4 className="">Filters</h4>
            <div
              className="col-12 border py-1 mb-4 rounded-2 d-flex flex-row align-items-center  "
              onClick={handleOutsideClickFilterInput}
            >
              {addressFilter && (
                <div className="exclude-span-services">
                  <span
                    className={`badge  bg-${
                      addressFilter === "private"
                        ? "primary"
                        : addressFilter === "all"
                        ? "info"
                        : "danger"
                    } font-size-16 me-1 `}
                  >
                    Address
                  </span>
                  <span
                    className={`badge  bg-${
                      addressFilter === "private"
                        ? "primary"
                        : addressFilter === "all"
                        ? "info"
                        : "danger"
                    } font-size-16 me-1 `}
                  >
                    =
                  </span>
                  <span
                    className={`badge  bg-${
                      addressFilter === "private"
                        ? "primary"
                        : addressFilter === "all"
                        ? "info"
                        : "danger"
                    } font-size-16 me-1 `}
                  >
                    {" "}
                    {addressFilter}
                  </span>
                </div>
              )}
              {groupFilter.length > 0 && (
                <div className="exclude-span-services">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setGroupArray([]);
                      set_groupFilter([]);
                      let tempFilterInput = [
                        { type: "address", name: "all", id: "all" },
                        { type: "address", name: "private", id: "private" },
                        { type: "address", name: "public", id: "public" },
                        { type: "group", name: "all", id: "all-group" },
                      ];
                      let last = tempFilterInput.concat(allGroupArray);
                      set_allFilterInput(last);
                      set_filterInput(last);
                      setHosts([]);
                      set_allHosts([]);
                    }}
                    className="badge bg-success font-size-16 me-1"
                  >
                    Groups
                  </span>
                  <span className="badge bg-success font-size-16 me-1"> =</span>
                  {groupFilter.map((item, index) => (
                    <span
                      key={item?.id + item?.name}
                      className="badge bg-success font-size-16 me-1"
                    >
                      {item.name}
                      <span
                        className={`float-end  remove-tag  ms-1  font-size-15  private-tag exclude-span-filter`}
                        onClick={() => filterInputFunction(item)}
                      >
                        X
                      </span>
                    </span>
                  ))}
                </div>
              )}
              <div className="outside-area d-flex flex-column ">
                <input
                  style={{ border: "none", outline: "none" }}
                  className="exclude-span-filter input-dark "
                  type="text"
                  value={searchInputValueFilterInput}
                  ref={inputRefFilterInput}
                  onFocus={handleInputFocusFilterInput}
                  onBlur={handleInputBlurFilterInput}
                  onChange={handleChangeSearchFilterInput}
                />
                <AnimatePresence>
                  {isDropdownOpenFilterInput && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className={`dropdown-host ${
                        isOpenFilterInput ? "open" : ""
                      }`}
                      ref={dropdownRefFilterInput}
                    >
                      <ul
                        style={{
                          top: "5px",
                          maxHeight: "257px",
                          overflowY: "overlay",
                        }}
                        className="options-host"
                      >
                        {filterInput?.map((item, index) => {
                          const isDifferentType = previous !== item?.type;
                          previous = item?.type;
                          return (
                            <React.Fragment key={index}>
                              {isDifferentType && (
                                <div
                                  style={{ cursor: "default" }}
                                  className="text-uppercase w-100 fw-bold font-size-14 text-center"
                                >
                                  {item?.type}
                                </div>
                              )}
                              <li
                                className={`font-size-16 py-1 exclude-span-filter  ${
                                  item?.name === "all"
                                    ? "text-info"
                                    : item?.name === "private"
                                    ? "text-primary"
                                    : item?.name === "public"
                                    ? "text-danger"
                                    : "text-success "
                                }`}
                                onClick={() => filterInputFunction(item)}
                              >
                                {item?.name}
                              </li>
                            </React.Fragment>
                          );
                        })}
                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
          <div className="row justify-content-around mb-3 ">
            <div className="col-xl-5 mt-3">
              <div className="d-flex flex-wrap align-items-center">
                <h5 className="card-title me-2">
                  IP Adresses ({hosts.length})
                </h5>
                <div className="ms-auto">
                  <div>
                    <button
                      type="button"
                      data-tag-select="ip_addressesstags"
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setHosts(allHosts);
                      }}
                    >
                      Select All
                    </button>
                    <button
                      type="button"
                      data-tag-unselect="ip_addressesstags"
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => {
                        setHosts([]);
                      }}
                    >
                      Unselect All
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="border border-1 border-primary d-flex flex-wrap gap-1   p-1 rounded-3   "
                style={{
                  maxHeight: "280px",
                  overflowY: "overlay",
                  minHeight: "100px",
                }}
                onClick={handleOutsideClick}
              >
                <AnimatePresence>
                  {hosts?.map((tag, index) => (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      key={tag.address}
                      className="exclude-span  "
                    >
                      <Badge
                        style={{ maxHeight: "25px" }}
                        className={`fs-6 w-100   tag  bg-${
                          tag.is_private === false ? "danger" : "primary"
                        } mr-1`}
                        title={`ID=${tag.id} ${
                          tag.is_private === true ? "Private" : "Public"
                        } ${tag.address} Group=${tag.group_name}`}
                      >
                        {tag.is_private === true ? (
                          <i className="float-start mdi mdi-ip-network-outline address-white me-1" />
                        ) : (
                          <i className="float-start mdi mdi-weather-cloudy-alert address-white me-1" />
                        )}

                        <span className="mx-1">{tag?.address}</span>
                        <span
                          className={`float-end exclude-span  remove-tag  badge-danger me-1   ${
                            tag.is_private === false
                              ? "public-tag"
                              : "private-tag"
                          }`}
                          onClick={() => handleRemoveAddress(tag, index)}
                        >
                          X
                        </span>
                      </Badge>
                    </motion.div>
                  ))}
                </AnimatePresence>
                <div className="outside-area">
                  <input
                    style={{ border: "none", outline: "none" }}
                    className="exclude-span input-dark"
                    type="text"
                    value={searchInputValue}
                    ref={inputRef}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onChange={handleChangeSearch}
                  />
                </div>
              </div>
              <AnimatePresence>
                {isDropdownOpen && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className={`dropdown-host ${isOpen ? "open" : ""}`}
                    ref={dropdownRef}
                  >
                    <ul
                      style={{
                        top: "-15px",
                        maxHeight: "257px",
                        overflowY: "overlay",
                      }}
                      className="options-host"
                    >
                      {filteredHosts?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => addHostAddress(item)}
                          className="fs-6"
                        >
                          {item?.address}
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="col-xl-5 mt-3">
              <div className="d-flex flex-wrap align-items-center">
                <h5 className="card-title me-2">
                  Services ({tempServices.length})
                </h5>
                <div className="ms-auto">
                  <div>
                    <button
                      type="button"
                      data-tag-select="ip_addressesstags"
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setTempServices(services);
                      }}
                    >
                      Select All
                    </button>
                    <button
                      type="button"
                      data-tag-unselect="ip_addressesstags"
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => {
                        setTempServices([]);
                      }}
                    >
                      Unselect All
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="border border-1 border-primary d-flex flex-wrap gap-1  p-1 rounded-3   "
                style={{
                  maxHeight: "280px",
                  overflowY: "overlay",
                  minHeight: "100px",
                }}
                onClick={handleOutsideClickServices}
              >
                {isServiceLoading && (
                  <div className="d-flex justify-content-center  align-items-center w-100   ">
                    <Spinner
                      style={{ width: "3rem", height: "3rem" }}
                      className="mt-2 text-center  "
                      color="primary"
                    />
                  </div>
                )}
                {!isServiceLoading && (
                  <AnimatePresence>
                    {tempServices?.map((tag, index) => (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        key={index}
                        className="exclude-span-services "
                      >
                        <Badge
                          style={{ maxHeight: "25px" }}
                          className={`fs-6 w-100   tag  bg-info mr-1`}
                          title={`Name=${tag?.name} ${tag?.port} `}
                        >
                          <span className="">{tag?.port}</span>
                          <span
                            className={`float-end exclude-span-services  remove-tag  badge-danger me-1    private-tag
                            `}
                            onClick={() => handleRemoveService(tag, index)}
                          >
                            X
                          </span>
                        </Badge>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                )}
                <div className="outside-area">
                  <input
                    style={{ border: "none", outline: "none" }}
                    className="exclude-span-services input-dark"
                    type="text"
                    value={searchServicesInputValue}
                    ref={inputRefServices}
                    onFocus={handleInputFocusServices}
                    onBlur={handleInputBlurServices}
                    onChange={handleChangeSearchServices}
                  />
                </div>
              </div>
              <AnimatePresence>
                {isDropdownOpenServices && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className={`dropdown-host ${isOpenServices ? "open" : ""}`}
                    ref={dropdownRefServices}
                  >
                    <ul
                      style={{
                        top: "-15px",
                        maxHeight: "257px",
                        overflowY: "overlay",
                      }}
                      className="options-host"
                    >
                      {filteredServices?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => addServicesPort(item)}
                          className="fs-6"
                        >
                          {item?.port}
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <Vulnerabilities
            services={tempServices}
            updated_at={updated_at}
            hosts={hosts}
            moduleID={moduleID}
            setSelectDisabled={(e) => setSelectDisabled(e)}
          />
        </CardBody>
      </div>
    </>
  );
};

export default BottomSection;
