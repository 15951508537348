import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Container, Spinner } from "reactstrap";
import CalendarComponent from "../../../../components/Customer/Account/Calendar/CalendarComponent";
import { get } from "../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../../common/InfoNoDataComponent";

const Calendar = () => {
  document.title = "Dashboard | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [renderGetTask, set_renderGetTask] = useState(false);
  const [modules, set_modules] = useState([]);
  const [moduleID, set_moduleID] = useState();
  const [firstLoading, set_firstLoading] = useState(false);
  const [seemInfoComponentActiveModule, set_seemInfoComponentActiveModule] =
    useState(false);
  const [seemInfoComponentModule, set_seemInfoComponentModule] =
    useState(false);

  const getTaskCalendar = async () => {
    paceFunction(true);
    if (moduleID) {
      try {
        let result = await get(
          `/customer/settings/module/task_calendar?id=${moduleID}`
        );
        if (result.success) {
          setData(result.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(`Error ==>`, error);
        setIsLoading(false);
        errorFunction(error);
      }
    }
    paceFunction(false);
  };

  const getModules = async () => {
    paceFunction(true);
    if (!moduleID) {
      try {
        let result = await get("/customer/module");
        if (result?.success) {
          if (result?.data?.modules?.length > 0) {
            const filteredData = result?.data?.modules.reduce((acc, item) => {
              if (!("permission" in item) || item.permission === true) {
                acc.push(item);
              }
              return acc;
            }, []);
            let filteredActiveData = filteredData.filter(
              (item) => item.is_enable === true
            );
            if (filteredActiveData.length > 0) {
              set_moduleID(filteredActiveData[0].id);
              set_modules(filteredActiveData);
              set_firstLoading(true);
            } else {
              set_seemInfoComponentActiveModule(true);
              setIsLoading(false);
            }
          } else {
            set_seemInfoComponentModule(true);
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(`Error ==>`, error);
        errorFunction(error);
      }
    }
    paceFunction(false);
  };

  useEffect(() => {
    if (!firstLoading) {
      setIsLoading(true);
    }
    getTaskCalendar();
  }, [renderGetTask, moduleID]);

  useEffect(() => {
    getModules();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Calendar" breadcrumbItem="Calendar" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && data && (
            <CalendarComponent
              data={data}
              modules={modules}
              getTaskCalendar={getTaskCalendar}
              set_moduleID={(e) => set_moduleID(e)}
              moduleID={moduleID}
            />
          )}
          {!isLoading && seemInfoComponentActiveModule && (
            <InfoNoDataComponent
              text="No active module were discovered"
              component="calendar"
            />
          )}
          {!isLoading && seemInfoComponentModule && (
            <InfoNoDataComponent
              text="No  module were discovered"
              component="calendar"
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Calendar;
