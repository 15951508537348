// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { IS_ENDPOINT_REDUX } from "./actionTypes";

import { isEndpointReduxFunction } from "./actions";

function* isEndpointFunction({ payload }) {
  yield put({ type: "IS_ENDPOINT_REDUX" });
}

export function* watchRender() {
  yield takeEvery("IS_ENDPOINT_REDUX_REQ", isEndpointFunction);
}

function* CustomSaga() {
  yield all([fork(watchRender)]);
}

export default CustomSaga;
