import React from "react";
import { Link, withRouter } from "react-router-dom";
const AdminSidebar = (propsValues) => {
  const props = propsValues.propsValues;
  return (
    <>
      <li>
        <Link to="/dashboard" className="">
          <i className="mdi mdi-view-dashboard "></i>
          <span>{props.t("Dashboard")}</span>
        </Link>
      </li>
      <li>
        <Link to="/admin/customers" className="">
          <i className="mdi mdi-account-group-outline "></i>
          <span>{props.t("Customers")}</span>
        </Link>
      </li>
      <li>
        <Link to="/admin/managers" className="">
          <i className="mdi mdi-account-tie-voice-outline"></i>
          <span>{props.t("Managers")}</span>
        </Link>
      </li>
      <li>
        <Link to="/admin/endpoints" className="">
          <i className="mdi mdi-router-wireless"></i>
          <span>{props.t("Endpoints")}</span>
        </Link>
      </li>
      <li>
        <Link to="/admin/utils" className="">
          <i className="mdi mdi-umbraco"></i>
          <span>{props.t("Utils")}</span>
        </Link>
      </li>
      <li>
        <Link to="/admin-profile" className="">
          {/* <FeatherIcon icon="monitor" /> */}
          <i className="mdi mdi-account-lock-outline"></i>
          <span>{props.t("Profile")}</span>
        </Link>
      </li>
      <li>
        <Link to="/admin/issues" className="">
          <i className="mdi mdi-cube-scan"></i>
          <span>{props.t("Issues")}</span>
        </Link>
      </li>
      {/* <li>
        <Link to="/admin/discount-coupons" className="">
          <i className="mdi mdi-ticket-percent-outline"></i>
          <span>{props.t("Discount Coupons")}</span>
        </Link>
      </li>  */}
      {/* <li>
        <Link to="/admin/account-diagram" className="">
          <i className="mdi mdi-file-tree-outline"></i>
          <span>{props.t("Account Diagram")}</span>
        </Link>
      </li> */}
      <li>
        <Link to="/admin/logs" className="">
          <i className="mdi mdi-book-open-page-variant-outline"></i>
          <span>{props.t("Logs")}</span>
        </Link>
      </li>
    </>
  );
};

export default AdminSidebar;
