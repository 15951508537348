import React, { useEffect, useState } from "react";
import { get } from "../../../helpers/api_helper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import { CardBody, Container, Spinner, CardHeader } from "reactstrap";
import ManagersTable from "../../../components/Admin/Managers/ManagersTable";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const Managers = () => {
  document.title = "Managers | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const router = useHistory();

  const [entryValue, setEntryValue] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoading, set_firstLoading] = useState(false);
  const [renderGetManagers, set_renderGetManagers] = useState(false);
  const [data, setData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getManagers = async () => {
    paceFunction(true);
    try {
      let result = await get("/admin/manager");
      setData(result.data);
      set_firstLoading(true);
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    if (!firstLoading) {
      setIsLoading(true);
    }
    getManagers();
  }, [renderGetManagers]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Managers" breadcrumbItem="Managers" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          <div className="special-card">
            <CardHeader>
              <button
                type="button"
                className="btn btn-primary waves-effect btn-label waves-light"
                onClick={() => {
                  router.push("/admin/add-manager");
                }}
              >
                <i className="mdi mdi-account-plus-outline label-icon"></i> Add
                Manager
              </button>
            </CardHeader>
            {!isLoading && data && (
              <CardBody>
                <div
                  style={{
                    overflowX: windowWidth < 800 ? "scroll" : "none",
                  }}
                >
                  <ManagersTable
                    data={data}
                    entryValue={entryValue}
                    changeValue={(e) => changeValue(e)}
                    renderGetManagers={renderGetManagers}
                    set_renderGetManagers={(e) => set_renderGetManagers(e)}
                  />
                </div>
              </CardBody>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Managers;
