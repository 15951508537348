import React, { useState } from "react";
import ReactJson from "react-json-view";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { post } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const WafControl = () => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, set_isLoading] = useState(false);
  const [output, set_output] = useState();
  const [domain, set_domain] = useState();

  const checkItFunction = async () => {
    paceFunction(true);
    set_isLoading(true);
    let data = {
      domain,
    };
    try {
      let result = await post("/admin/utils/check-waf", data);
      if (result?.success) {
        set_output(result?.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_isLoading(false);
    paceFunction(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-xxl-4 col-xl-5 col-lg-6">
          <div className="input-group">
            <span className="input-group-text">Domain</span>
            <input
              type="text"
              className="form-control"
              style={{ width: "80%" }}
              name="host"
              placeholder="Domain"
              required
              defaultValue={domain}
              onChange={(e) => set_domain(e.target.value)}
            />
          </div>
        </div>
        <div className="col-xxl-8  col-xl-7 col-lg-6">
          <button
            type="button"
            id="claBtn"
            className="btn btn-success waves-effect btn-label waves-light w-100 mt-lg-0 mt-2 "
            onClick={checkItFunction}
          >
            <i className="mdi mdi-check-all label-icon"></i> Check It
          </button>
        </div>
      </div>
      <div>
        <h5 className="my-3">Result</h5>
        <div
          className="special-card2  "
          style={{
            minHeight: "400px",
            // overflowY: "overlay",
          }}
        >
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{
                  width: "3rem",
                  height: "3rem",
                }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && output && <ReactJson src={output} />}
        </div>
      </div>
    </div>
  );
};

export default WafControl;
