import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { del, get, post, put, patch } from "../helpers/api_helper";
import errorFunctionComponent from "../../common/errorFunctionComponent";
import { useSelector, useDispatch } from "react-redux";
const CustomerSidebar = (propsValues) => {
  const { errorFunctionNotSwal } = errorFunctionComponent();
  const { render, render2, isEndpointRedux } = useSelector((state) => ({
    render: state.render.render,
    render2: state.render.render2,
    isEndpointRedux: state.custom.isEndpointRedux,
  }));
  const [isEndpoint, set_isEndpoint] = useState(false);
  const props = propsValues.propsValues;

  const getEndpoint = async () => {
    try {
      let result = await get("/customer/settings/endpoint");
      if (result?.success) {
        set_isEndpoint(true);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      // errorFunctionNotSwal(error);
      if (!error?.response?.data?.success) {
        set_isEndpoint(false);
      }
    }
  };

  // useEffect(() => {
  //   getEndpoint();
  // }, [render2]);
  return (
    <>
      <li>
        <Link to="/dashboard" className="">
          <i className="mdi mdi-view-dashboard "></i>
          <span>{props.t("Dashboard")}</span>
        </Link>
      </li>
      <li>
        <Link to="/#" className="has-arrow ">
          <i className="mdi mdi-monitor"></i>
          <span>{props.t("Hosts")}</span>
        </Link>
        <ul className="sub-menu">
          <li>
            <Link to="/hosts/audits" className="">
              <i className="mdi mdi-monitor-screenshot"></i>
              <span>{props.t("Audits")}</span>
            </Link>
          </li>
          {isEndpointRedux && (
            <>
              {" "}
              <li>
                <Link to="/hosts/network-shares" className="">
                  <i className="mdi mdi-monitor-share"></i>
                  <span>{props.t("Network Shares")}</span>
                </Link>
              </li>
              {/* <li>
                <Link to="/hosts/weak-credentials" className="">
                  <i className="mdi mdi-bow-tie"></i>
                  <span>{props.t("Weak Credentials")}</span>
                </Link>
              </li> */}
              <li>
                <Link to="/hosts/network-monitor" className="">
                  <i className="mdi mdi-monitor-eye"></i>
                  <span>{props.t("Network Monitor")}</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </li>
      <li>
        <Link to="/web-applications" className="">
          <i className="mdi mdi-web "></i>
          <span>{props.t("Web Applications")}</span>
        </Link>
      </li>
      <li>
        <Link to="/#" className="has-arrow ">
          <i className="mdi mdi-fingerprint"></i>
          <span>{props.t("Cyber Watch")}</span>
        </Link>
        <ul className="sub-menu">
          <li>
            <Link to="/cyber-watch/attack-surface" className="">
              <i className="mdi mdi-webpack"></i>
              <span>{props.t("Attack Surface")}</span>
            </Link>
          </li>
          <li>
            <Link to="/cyber-watch/account-leaks" className="">
              <i className="mdi mdi-monitor"></i>
              <span>{props.t("Account Leaks")}</span>
            </Link>
          </li>
          <li>
            <Link to="/#" className="has-arrow">
              <i className="mdi mdi-security "></i>
              <span className="">{props.t("Threat Intelligence")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/cyber-watch/threat-intelligence/cves?page=1"
                  // className="has-arrow"
                >
                  <i className="mdi mdi-set-none "></i>
                  <span className="">{props.t("Vulnerabilities Watcher")}</span>
                </Link>
                {/* <ul className="sub-menu">
                  <li>
                    <Link
                      to="/cyber-watch/threat-intelligence/cves?page=1"
                      className=""
                    >
                      <i className="mdi mdi-format-list-bulleted"></i>
                      <span>{props.t("Vulnerabilities")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cyber-watch/threat-intelligence/vendor-product"
                      className=""
                    >
                      <i className="mdi mdi-vector-intersection"></i>
                      <span>{props.t("Vendor & Product")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cyber-watch/threat-intelligence/categories-cwes?page=1"
                      className=""
                    >
                      <i className="mdi mdi-group"></i>
                      <span>{props.t("Categories (CWE)")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cyber-watch/threat-intelligence/subscriptions-tags"
                      className=""
                    >
                      <i className="mdi mdi-tag"></i>
                      <span>{props.t("Subscriptions & Tags")}</span>
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li>
                <Link
                  to="/cyber-watch/threat-intelligence/cyber-news"
                  className=""
                >
                  <i className="mdi mdi-newspaper"></i>
                  <span>{props.t("Cyber News")}</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/mailbox-threats" className="">
          <i className="mdi mdi-virus-outline "></i>
          <span>{props.t("Mailbox Threats")}</span>
        </Link>
      </li>
      <li>
        <Link to="/assets" className="">
          <i className="mdi mdi-apps"></i>
          <span>{props.t("Assets")}</span>
        </Link>
      </li>
      <li>
        <Link to="/ignored-vulns" className="">
          <i className="mdi mdi-eye-off"></i>
          <span>{props.t("Ignored Vulns")}</span>
        </Link>
      </li>
      <li>
        <Link to="/notifications" className="">
          <i className="mdi mdi-email-multiple-outline"></i>
          <span>{props.t("Notifications")}</span>
        </Link>
      </li>
      <li>
        <Link to="/#" className="has-arrow ">
          <i className="mdi mdi-shield-account-outline"></i>
          <span>{props.t("Account")}</span>
        </Link>
        <ul className="sub-menu">
          <li>
            <Link to="/account-profile" className="">
              <i className="mdi mdi-account-lock-outline"></i>
              <span>{props.t("Profile")}</span>
            </Link>
          </li>
          <li>
            <Link to="/account-calendar" className="">
              <i className="mdi mdi-calendar-multiselect"></i>
              <span>{props.t("Calendar")}</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/account-invoices" className="">
              <i className="mdi mdi-file-document-outline"></i>
              <span>{props.t("Invoices")}</span>
            </Link>
          </li>
          <li>
            <Link to="/account-pricing" className="">
              <i className="mdi mdi-briefcase-download-outline"></i>
              <span>{props.t("Pricing")}</span>
            </Link>
          </li> */}
        </ul>
      </li>
    </>
  );
};

export default CustomerSidebar;
