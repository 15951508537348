import React, { useEffect, useRef, useState } from "react";
import { Modal, Badge } from "reactstrap";
import { put } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion, AnimatePresence } from "framer-motion";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const FixedModal = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  assets,
  row,
  moduleID,
  updated_at,
  getVulnerabilities,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [modal_standard5, setmodal_standard5] = useState(false);

  const [addLoading, set_addLoading] = useState(false);

  const [hosts, setHosts] = useState(assets);
  const [filteredHosts, set_filteredHosts] = useState([]);
  const [allfilteredHosts, set_allfilteredHosts] = useState([]);
  const [searchInputValue, set_searchInputValue] = useState("");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const findMissingAssets = (assets, hosts) => {
    const hostAddresses = hosts?.map((host) => host?.address);

    const missingAssets = assets?.filter(
      (asset) => !hostAddresses?.includes(asset?.address)
    );

    return missingAssets;
  };

  useEffect(() => {
    const missingAssets = findMissingAssets(assets, hosts);
    set_filteredHosts(missingAssets);
    set_allfilteredHosts(missingAssets);
  }, [hosts]);

  const handleInputFocus = () => {
    if (!isDropdownOpen && !isOpen) {
      setIsDropdownOpen(true);
      setIsOpen(true);
    }
  };

  const handleInputBlur = () => {
    if (isDropdownOpen && isOpen) {
      setTimeout(() => {
        setIsDropdownOpen(false);
        setIsOpen(false);
        set_searchInputValue("");
      }, 100);
    }
  };

  const handleOutsideClick = (event) => {
    if (!event.target.classList.contains("exclude-span")) {
      inputRef.current.focus();
    }
  };

  const addHostAddress = (item) => {
    let tempArray = [...hosts, item];
    setHosts(tempArray);
    set_searchInputValue("");
  };

  const filterAddressesByNumber = (number) => {
    const filteredAddresses = allfilteredHosts.filter((address) => {
      return address.address.includes(number.toString());
    });

    return filteredAddresses;
  };

  const handleChangeSearch = (e) => {
    set_searchInputValue(e.target.value);
    let result = filterAddressesByNumber(e.target.value);
    set_filteredHosts(result);
  };

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toastSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });

  const handleRemoveAddress = async (tag, index) => {
    const updatedTags = [...hosts];
    updatedTags.splice(index, 1);
    setHosts(updatedTags);
  };

  const addCredentials = async () => {
    paceFunction(true);
    set_addLoading(true);
    let address = hosts?.map((item) => item?.address);
    let data = {
      name: row.original.name,
      address,
      fixed: true,
      false_positive: false,
      accepted_risk: false,
      module_id: moduleID,
      date: updated_at,
    };
    try {
      let result = await put("/customer/module/ignored-vulns", data);
      if (result?.success) {
        toastSuccess(result?.message || "Ignore Added");
        tog_standard5();
        tog_standard();
        getVulnerabilities();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addCredentials}
        text="Are you sure ?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        size="lg"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Fixed Assets
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="col-xl-12 col-md-12"></div>
          <div className="col-xl-12 col-md-12">
            <div className="d-flex align-items-center mb-3  ">
              <span className="fs-6 me-1 fw-semibold  ">Name:</span>
              <div className="fs-6 fw-medium text-center border-1 border border-primary p-1  ">
                {row?.original?.name}
              </div>
            </div>
            <div className="form-group mb-3">
              <div className="d-flex justify-content-between align-items-center ">
                <label>Assets</label>
                <div>
                  <button
                    type="button"
                    data-tag-select="ip_addressesstags"
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => {
                      setHosts(assets);
                    }}
                  >
                    Select All
                  </button>
                  <button
                    type="button"
                    data-tag-unselect="ip_addressesstags"
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => {
                      setHosts([]);
                    }}
                  >
                    Unselect All
                  </button>
                </div>
              </div>
              <div
                className="border border-1 border-primary d-flex flex-wrap gap-1   p-1 rounded-3   "
                style={{
                  maxHeight: "280px",
                  overflowY: "overlay",
                  minHeight: "100px",
                }}
                onClick={handleOutsideClick}
              >
                <AnimatePresence>
                  {hosts.map((tag, index) => (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      key={tag?.address}
                      className=" exclude-span "
                    >
                      <Badge
                        style={{ maxHeight: "25px" }}
                        className={`fs-6 w-100   tag  bg-${
                          tag?.is_private === false ? "danger" : "primary"
                        } mr-1`}
                        title={` ${
                          tag?.is_private === true ? "Private" : "Public"
                        } ${tag.address}`}
                      >
                        {tag?.is_private === true ? (
                          <i className="float-start mdi mdi-ip-network-outline address-white me-1" />
                        ) : (
                          <i className="float-start mdi mdi-weather-cloudy-alert address-white me-1" />
                        )}

                        <span className="mx-1">{tag?.address}</span>
                        <span
                          className={`float-end exclude-span  remove-tag  badge-danger me-1   ${
                            tag?.is_private === false
                              ? "public-tag"
                              : "private-tag"
                          }`}
                          onClick={() => handleRemoveAddress(tag, index)}
                        >
                          X
                        </span>
                      </Badge>
                    </motion.div>
                  ))}
                </AnimatePresence>
                <div className="outside-area">
                  <input
                    style={{ border: "none", outline: "none" }}
                    className="exclude-span input-dark "
                    type="text"
                    value={searchInputValue}
                    ref={inputRef}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onChange={handleChangeSearch}
                  />
                </div>
              </div>
              <AnimatePresence>
                {isDropdownOpen && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className={`dropdown-host ${isOpen ? "open" : ""}`}
                    ref={dropdownRef}
                  >
                    <ul
                      style={{
                        top: "-15px",
                        maxHeight: "257px",
                        overflowY: "overlay",
                      }}
                      className="options-host"
                    >
                      {filteredHosts?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => addHostAddress(item)}
                          className="fs-6"
                        >
                          {item?.address}
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            onClick={tog_standard5}
            type="button"
            data-delete-groupbtn="deneme"
            className="btn btn-primary text-truncate"
          >
            Approve
          </button>
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default FixedModal;
